Beacon.Control.DisplayXY = OpenLayers.Class(OpenLayers.Control, {

    autoActivate: true,

    element: null,
    toggleElement: null,

    granularity: 10,
    lastXy: null,

    digits: 0,
    hidden: true,
    sink: null,

    displayProjection: null,
    displayProjectionIndex: null,


    initialize: function (options) {
        OpenLayers.Control.prototype.initialize.apply(this, arguments);


    },


    destroy: function () {
        this.deactivate();
        OpenLayers.Control.prototype.destroy.apply(this, arguments);
    },


    activate: function () {
        if (OpenLayers.Control.prototype.activate.apply(this, arguments)) {
            this.map.events.register('mousemove', this, this.redraw);
            this.map.events.register('mouseout', this, this.reset);

            // Registering the touch events as priority - the reason being is b/c the drag event will kill the chain (since this display was designed around realitime mouse position),
            // so we careate a specific touch event for mobile, and move the event to the top of the stack...AL
            this.map.events.registerPriority('touchstart', this, this.redrawTouch);
            this.map.events.registerPriority('touchend', this, this.reset);

            this.redraw();
            return true;
        } else {
            return false;
        }
    },


    deactivate: function () {
        if (OpenLayers.Control.prototype.deactivate.apply(this, arguments)) {
            this.map.events.unregister('mousemove', this, this.redraw);
            this.map.events.unregister('mouseout', this, this.reset);

            this.map.events.unregister('touchstart', this, this.redrawTouch);
            this.map.events.unregister('touchend', this, this.reset);

            this.element.innerHTML = "";
            return true;
        } else {
            return false;
        }
    },


    draw: function () {
        OpenLayers.Control.prototype.draw.apply(this, arguments);

        if (!this.element) {

            this.toggleElement = document.createElement("div");
            this.toggleElement.className = this.displayClass + 'Toggle';
            this.toggleElement.title = "Change coordinate system";
            this.div.appendChild(this.toggleElement);

            this.element = document.createElement("div");
            this.element.className = this.displayClass + 'Text';
            this.div.appendChild(this.element);

        }

        //prevent clicks from hitting the map 
        this.sink = new Beacon.Control.EventSink();
        this.sink.register(this.div);

        OpenLayers.Event.observe(this.toggleElement, "click", OpenLayers.Function.bindAsEventListener(this.onClick, this));

        if (Beacon.MapJS.distanceFactor === 1.0) {
            //init projection info-  toggle will advance to 0 - map units
            this.displayProjectionIndex = -1;
        } else {
            this.displayProjectionIndex = mapConfig.Projections.length - 3;
        }

        this.toggleProjection();

        return this.div;
    },


    redraw: function (evt) {

        //if map is dragging, bail - helps IE perf
        if (this.map.dragging) return;

        var lonLat;

        if (evt == null) {
            this.reset();
            return;
        }

        if (this.lastXy == null ||
                Math.abs(evt.xy.x - this.lastXy.x) > this.granularity ||
                Math.abs(evt.xy.y - this.lastXy.y) > this.granularity) {
            this.lastXy = evt.xy;
            return;
        }

        this.lastXy = evt.xy;

        this.lastLatLon = this.map.getLonLatFromPixel(evt.xy);

        this.updateDisplay();

    },

    redrawTouch: function (evt) {

        var lonLat;

        if (evt == null) {
            this.reset();
            return;
        }

        this.lastXy = evt.xy;
        this.lastLatLon = this.map.getLonLatFromPixel(evt.xy);
        this.updateDisplay();
    },

    updateDisplay: function () {

        if (!this.lastLatLon) {
            // map has not yet been properly initialized
            return;
        }

        var lonLat = this.lastLatLon.clone();

        if (this.displayProjection) {
            lonLat.transform(this.map.getProjectionObject(), this.displayProjection);
        }

        var newHtml = this.formatOutput(lonLat);

        if (newHtml != this.element.innerHTML) {
            this.element.innerHTML = newHtml;

            //IE:hack
            $(this.div).width(250);
            $(this.div).width($(this.element).width() + 16);

            //            if (this.hidden) {
            //                this.hidden = false;
            //                $(this.element).show();
            //            }
        }

    },


    reset: function (evt) {
        //        this.element.innerHTML = '';
        //        $(this.element).hide();
        //        this.hidden = true;
    },


    formatOutput: function (lonLat) {

        if (!this.digits) {
            // D M S - round to seconds
            return this.formatDMS(lonLat.lon, true) + ', ' +
                   this.formatDMS(lonLat.lat, false);
        } else {
            return lonLat.lon.toFixed(this.digits) + ', ' + lonLat.lat.toFixed(this.digits);
        }
    },

    onClick: function () {
        this.toggleProjection();
        Beacon.GA.TrackEvent('DisplayXY', 'Click');
    },

    toggleProjection: function () {

        try {
            this.displayProjectionIndex++;
            if (this.displayProjectionIndex >= mapConfig.Projections.length) this.displayProjectionIndex = 0;

            var prjInfo = mapConfig.Projections[this.displayProjectionIndex];


            this.displayProjection = new OpenLayers.Projection("EPSG:" + prjInfo.SRID);
            this.element.title = prjInfo.Name;

            if (prjInfo.Name == "Lon/Lat (dd mm ss)") {
                this.digits = 0; //DMS
            } else {
                this.digits = (prjInfo.SRID == 4326) ? 6 : 2;
            }

            this.updateDisplay();
            //this.redraw(true);
        } catch (e) {
            Beacon.MapJS.showRetryActivity(e, "DisplayXY.toggleProjection");

        }
    },

    formatDMS: function (coordinate, axis) {

        
        var abscoordinate = Math.abs(coordinate)
        var absCoordinatedegrees = Math.floor(abscoordinate);
        var actualCoordinatedegrees = coordinate < 0 ? Math.ceil(coordinate) : Math.floor(coordinate); // We only use absoulte to calcuate the minutes / seconds - not the degrees (as degrees needs to retain it's sign)...AL

        var coordinateminutes = (abscoordinate - absCoordinatedegrees) / (1 / 60);
        var tempcoordinateminutes = coordinateminutes;
        coordinateminutes = Math.floor(coordinateminutes);
        var coordinateseconds = (tempcoordinateminutes - coordinateminutes) / (1 / 60);
        coordinateseconds = Math.round(coordinateseconds * 10) / 10;

        var s = [];

        s.push(actualCoordinatedegrees);
        s.push("\u00B0 ");

        if (coordinateminutes < 10) {
            s.push("0");
        }
        s.push(coordinateminutes);
        s.push("' ");

        if (coordinateseconds < 10) {
            s.push("0");
        }

        s.push(coordinateseconds.toFixed(1));
        s.push('" ');

        if (axis) {
            s.push(coordinate < 0 ? "W" : "E");
        } else {
            s.push(coordinate < 0 ? "S" : "N");
        }
        return s.join('');

    },


    CLASS_NAME: "Beacon.Control.DisplayXY"
});
