
Beacon.Control.ClearMap = OpenLayers.Class(OpenLayers.Control, {

    type: OpenLayers.Control.TYPE_BUTTON,

    selectionLayer: null,

    trigger: function () {

        // called to clear Selection, KeyValue, Popups

        try {
            this.ClearSelection();
            this.ClearPopups();
            this.ClearBuffer();
            this.ClearMeasurements();

            //this.selectionLayer.setKeyFeature(null);
            Beacon.MapJS.bufferLayer.removeAllFeatures();
        } catch (e) {
            Beacon.MapJS.showRetryActivity(e, "ClearMap.Trigger");

        }

    },

    ClearMeasurements: function () {
        Beacon.MapJS.controls.measure.removeAllMeasurements();
    },

    ClearSelection: function () {
        this.selectionLayer.clearSelection();
        // Beacon.ResultsPane.removeAll();
        //Beacon.ResultsPane.updateDisplay(0);
        //Beacon.Tabs.onSelectionChanged(0, null);
    },

    ClearPopups: function () {
        for (var i = this.map.popups.length - 1; i >= 0; i--) {
            this.map.removePopup(this.map.popups[i]);
        }
    },

    ClearBuffer: function () {
        if (Beacon.MapJS.bufferLayer.features.length > 0) {
            Beacon.MapJS.bufferLayer.removeAllFeatures();
            Beacon.API.ClearBufferGeometry();
        }
    },

    initialize: function (selectionLayer, options) {
        OpenLayers.Control.prototype.initialize.apply(this, [options]);
        this.selectionLayer = selectionLayer;
    },

    destroy: function () {
        this.selectionLayer = null;
        OpenLayers.Control.prototype.destroy.apply(this, []);
    },

    CLASS_NAME: "Beacon.Control.ClearMap"
});