/**
* Class: Beacon.Popup.MapTipFrame
* 
* Inherits from: 
*  - <OpenLayers.Popup.Framed>
*/
Beacon.Popup.MapTipFrame =
  OpenLayers.Class(OpenLayers.Popup.Framed, {


      loadMessage: "<div class='title'>Loading Information...</div><div class='loader'></div>",


      // is the pin pushed in - prevent from auto-deleting when a new feature is id'd
      pinned: false,
      pinDiv: null,
      dragContainer: null,

      //tack on the fid so we can find things later
      fid: null,

      /** 
      * Property: contentDisplayClass
      * {String} The CSS class of the popup content div.
      */
      contentDisplayClass: "olMapTipFrameContent",

      /**
      * APIProperty: autoSize
      * {Boolean} Framed Cloud is autosizing by default.
      */
      autoSize: true,

      /**
      * APIProperty: panMapIfOutOfView
      * {Boolean} Framed Cloud does pan into view by default.
      */
      panMapIfOutOfView: true,

      /**
      * APIProperty: imageSize
      * {<OpenLayers.Size>}
      */
      imageSize: new OpenLayers.Size(600, 600),

      /**
      * APIProperty: isAlphaImage
      * {Boolean} The FramedCloud does not use an alpha image (in honor of the 
      *     good ie6 folk out there)
      */
      isAlphaImage: true,

      /** 
      * APIProperty: fixedRelativePosition
      * {Boolean} The Framed Cloud popup works in just one fixed position.
      */
      fixedRelativePosition: false,

      /**
      * Property: positionBlocks
      * {Object} Hash of differen position blocks, keyed by relativePosition
      *     two-character code string (ie "tl", "tr", "bl", "br")
      */
      positionBlocks: {
          "bl": {
              'offset': new OpenLayers.Pixel(0, -10),
              'padding': new OpenLayers.Bounds(20, 20, 30, 0),
              'blocks': [
                { // top-left
                    size: new OpenLayers.Size('auto', 'auto'),
                    anchor: new OpenLayers.Bounds(20, 20, 30, 0),
                    position: new OpenLayers.Pixel(-41, -40)
                },
                { //top-right
                    size: new OpenLayers.Size(30, 'auto'),
                    anchor: new OpenLayers.Bounds(null, 20, 0, 0),
                    position: new OpenLayers.Pixel(-536, -40)
                },
                { //bottom-left
                    size: new OpenLayers.Size('auto', 20),
                    anchor: new OpenLayers.Bounds(20, 0, 30, null),
                    position: new OpenLayers.Pixel(-41, -384)
                },
                { //bottom-right
                    size: new OpenLayers.Size(30, 20),
                    anchor: new OpenLayers.Bounds(null, 0, 0, null),
                    position: new OpenLayers.Pixel(-536, -384)
                },
                { // stem
                    size: new OpenLayers.Size(30, 34),
                    anchor: new OpenLayers.Bounds(null, null, 0, 0),
                    position: new OpenLayers.Pixel(-66, -430)
                }
            ]
          },
          "br": {
              'offset': new OpenLayers.Pixel(0, -10),
              'padding': new OpenLayers.Bounds(20, 20, 30, 0),
              'blocks': [
                { // top-left
                    size: new OpenLayers.Size('auto', 'auto'),
                    anchor: new OpenLayers.Bounds(20, 20, 30, 0),
                    position: new OpenLayers.Pixel(-41, -40)
                },
                { //top-right
                    size: new OpenLayers.Size(30, 'auto'),
                    anchor: new OpenLayers.Bounds(null, 20, 0, 0),
                    position: new OpenLayers.Pixel(-536, -40)
                },
                { //bottom-left
                    size: new OpenLayers.Size('auto', 20),
                    anchor: new OpenLayers.Bounds(20, 0, 30, null),
                    position: new OpenLayers.Pixel(-41, -384)
                },
                { //bottom-right
                    size: new OpenLayers.Size(30, 20),
                    anchor: new OpenLayers.Bounds(null, 0, 0, null),
                    position: new OpenLayers.Pixel(-536, -384)
                },
                { // stem
                    size: new OpenLayers.Size(21, 34),
                    anchor: new OpenLayers.Bounds(0, null, null, 0),
                    position: new OpenLayers.Pixel(-10, -430)
                }
            ]
          }
      },

      startPosition: null,
      lastPos: null,
      newPos: null,
      /** 
      * Method: calculateRelativePosition - OVERRIDE!
      * 
      * Parameters:
      * px - {<OpenLayers.Pixel>}
      * 
      * Returns:
      * {String} The relative position ("br" "bl") at which the popup
      *     should be placed.
      *  --- Limited now to only returning bottom l/r quadrants
      */
      calculateRelativePosition: function (px) {

          if (this.startPosition) {
              var lonlat = this.map.getLonLatFromLayerPx(px);
              var startPoitionPx = this.map.getViewPortPxFromLonLat(this.startPosition);

              if (startPoitionPx.x >= px.x - 150 || startPoitionPx.x <= px.x + 150)
                  this.newPos = this.lastPos;
              else if (lonlat.lon > this.startPosition.lon)
                  this.newPos = 'br';
              else
                  this.newPos = 'bl';
              return this.newPos;
          }              
          else {
              var lonlat = this.map.getLonLatFromLayerPx(px);

              var extent = this.map.getExtent();
              var quadrant = extent.determineQuadrant(lonlat);

              this.lastPos = OpenLayers.Bounds.oppositeQuadrant(quadrant);
              this.startPosition = lonlat;
              this.lastPos = 'b' + this.lastPos.charAt(1); //force to bottom l/r quadrants
              this.newPos = this.lastPos;
              return this.lastPos;

      }
      },


      /**
      * APIProperty: minSize
      * {<OpenLayers.Size>}
      */
      minSize: new OpenLayers.Size(350 - 50, 50),

      /**
      * APIProperty: maxSize
      * {<OpenLayers.Size>}
      */
      maxSize: new OpenLayers.Size(300 + 50 - 50, 346 + 20),

      down: false,
      popPnt: null,
      mapPnt: null,
      popup: null,
      docMouseUpProxy: null,

      /** 
      * Constructor: OpenLayers.Popup.FramedCloud
      * 
      * Parameters:
      * id - {String}
      * lonlat - {<OpenLayers.LonLat>}
      * contentSize - {<OpenLayers.Size>}
      * contentHTML - {String}
      * anchor - {Object} Object to which we'll anchor the popup. Must expose 
      *     a 'size' (<OpenLayers.Size>) and 'offset' (<OpenLayers.Pixel>) 
      *     (Note that this is generally an <OpenLayers.Icon>).
      * closeBox - {Boolean}
      * closeBoxCallback - {Function} Function to be called on closeBox click.
      */
      initialize: function (id, lonlat, contentSize, contentHTML, anchor, closeBox,
                        closeBoxCallback, showUnderMouse) {

          //if (showUnderMouse) {
              this.positionBlocks.bl.offset.x = 20;
              this.positionBlocks.br.offset.x = -10;
          //} else {
              //this.positionBlocks.bl.offset.x = 0;
              //this.positionBlocks.br.offset.x = 0;
          //}

          this.imageSrc = OpenLayers.ImgPath + 'BeaconMapTip.png';

          OpenLayers.Popup.Framed.prototype.initialize.apply(this,
            [id, lonlat, contentSize, contentHTML, anchor, false, null]);

          this.contentDiv.className = this.contentDisplayClass;
          this.addCloseBox();
      },

      /** 
      * APIMethod: destroy
      */
      destroy: function () {
          this.dragContainer.destroy();
          OpenLayers.Popup.Framed.prototype.destroy.apply(this, arguments);
      },

      registerEvents: function() {
          OpenLayers.Popup.Framed.prototype.registerEvents.apply(this, arguments);

          //need to capture these, they seem to leak thru to the map
          this.events.on({
              "touchstart": this.onmousedown,  // those fcns invoke "stop"
              "touchend": this.onmouseup,
              "touchmove": this.onmousemove,
              scope: this
          });

      },

      setFeature: function (feature) {

          this.fid = feature.fid;

          if (feature.tipHtml) {

              var tiphtml = feature.tipHtml;

              if (feature.html) {
                  //var str = feature.html
                  var n = feature.html.startsWith("No Data Found")
                  if (n) {
                      tiphtml = "No Data Found";
                  }
                  else {
                      tiphtml = feature.tipHtml;
                      tiphtml += Beacon.Tabs.getReportLinksHtml(feature.fid);
                  }
              } else {
                  tiphtml = feature.tipHtml;
                  tiphtml += Beacon.Tabs.getReportLinksHtml(feature.fid);
              }
              
              //              var reports = Beacon.Tabs.getReportLinks(feature.fid);
              //              if (reports.length > 0) {
              //                  var reportLinks = $.map(reports, function (d) {
              //                      return '<a href="' + d.url + '">' + d.name + '</a>';
              //                  });
              //                  tiphtml += "View: " + reportLinks.join("&nbsp;|&nbsp;");
              //              }

              
              this.setContent((feature.fid.length <= 6 ? 'Feature data: ' : '') + feature.fid, tiphtml);
          }
      },

      setContent: function (titleString, contentHtml) {
          if (contentHtml === 'No Data Found') {
              titleString = 'No Data Found'
          }

          if (titleString.indexOf("·") > -1) {
              // If there is a middle dot, then this is a multi-site key. Mask the key to only show parcel number.
              titleString = titleString.split("·")[1];
          }
          var html = "<div class='title'><b>" + titleString + "</b></div>";
          html += "<div class='content'>" + contentHtml + "</div>";
          this.setContentHTML(html);

      },

      togglePin: function (e) {
          if (this.pinDiv.className == "sprite MaptipPinDown") {
              this.pinned = false;
              this.pinDiv.className = "sprite MaptipPinUp"
          } else {
              this.pinned = true;
              this.pinDiv.className = "sprite MaptipPinDown"
          }
      },

      //Overrides method in OpenLayers.Popup
      addCloseBox: function (callback) {
          //we don't call the base method - we replaced it

          this.closeDiv2 = OpenLayers.Util.createDiv(this.id + "_close",
                                        null,
                                        new OpenLayers.Size(15, 15));

          this.closeDiv2.className = "sprite MaptipClose";

         
          this.pinDiv = OpenLayers.Util.createDiv(this.id + "_pin",
                              null,
                              new OpenLayers.Size(15, 15));
          this.pinDiv.className = "sprite MaptipPinUp";


          this.groupDiv.appendChild(this.closeDiv2);
          this.groupDiv.appendChild(this.pinDiv);

          var closePopup = callback || function (e) {
              this.dragContainer.destroy();
              this.map.removePopup(this);
              OpenLayers.Event.stop(e);
          };
          OpenLayers.Event.observe(this.closeDiv2, "click",
                OpenLayers.Function.bindAsEventListener(closePopup, this));

          OpenLayers.Event.observe(this.pinDiv, "click",
                OpenLayers.Function.bindAsEventListener(this.togglePin, this));
      },

      CLASS_NAME: "Beacon.Popup.MapTipFrame"
    });


Beacon.Popup.MapTipFrameDragable = OpenLayers.Class(OpenLayers.Control, {
    down: false,
    popPnt: null,
    mapPnt: null,
    popup: null,
    docMouseUpProxy: null,
    markupLayer: null,
    /**
     * Constructor: OpenLayers.Control.DragPopup
     * Create a new control to drag a popup.
     *
     * Parameters:
     * @param {OpenLayers.Popup} popup
     * @param {Object} options
     */
    initialize: function (popup, options) {
        OpenLayers.Control.prototype.initialize.apply(this, [options]);
        this.popup = popup;
        this.popup.dragContainer = this;
        this.popup.events.register('mousedown', this, this.mouseDown);
        this.popup.events.register('mouseup', this, this.mouseUp);
        this.popup.events.register('mousemove', this, this.mouseMove);
        // Define a function bound to this used to listen for
        // document mouseout events
        this.docMouseUpProxy = OpenLayers.Function.bind(this.mouseUp, this);


        
    },

    /**
     * Method: setMap
     * Set the map property for the control.
     *
     * Parameters: 
     * map - {<OpenLayers.Map>} The controls map.
     */
    setMap: function (map) {
        OpenLayers.Control.prototype.setMap.apply(this, [map]);
        this.map.events.register('mousemove', this, this.mouseMove);

        this.markupLayer = new OpenLayers.Layer.Vector("Overlay");
        map.addLayer(this.markupLayer);
    },

    mouseDown: function (evt) {
        //console.log('mouseDown');
        this.down = true;
        this.popPnt = this.popup.events.getMousePosition(evt);
        OpenLayers.Event.observe(document, 'mouseup', this.docMouseUpProxy);
        OpenLayers.Event.stop(evt);
    },

    mouseUp: function (evt) {
        //console.log('mouseUp');
        this.down = false;
        OpenLayers.Event.stopObserving(document, 'mouseup', this.docMouseUpProxy);
        OpenLayers.Event.stop(evt);


        

        

        //var start_point = new OpenLayers.Geometry.Point(0, 10);
        //var end_point = new OpenLayers.Geometry.Point(30, 0);

        

        //var vector = new OpenLayers.Layer.Vector();
        //vector.features.push([new OpenLayers.Feature.Vector(new OpenLayers.Geometry.LineString([mapPntPx, this.popPnt]).transform(new OpenLayers.Projection("EPSG:4326"), new OpenLayers.Projection("EPSG:900913")))]);
        //this.popup.map.addLayers([ol, vector]);
            //this.popup.map.setCenter(new OpenLayers.LonLat(0, 5), 3);
    },

    mouseOut: function (evt) {
        //console.log('map.mouseOut');
        this.down = false;
        OpenLayers.Event.stop(evt);
    },

    mouseMove: function (evt) {
        //console.log('mouseMove');
        if (this.down) {

            var popupWidth = 300;

            var mapPntPx = this.map.events.getMousePosition(evt);        

            if (this.popup.newPos == 'br')
                mapPntPx = mapPntPx.add((this.popPnt.x * -1) + 15, ((this.popPnt.y - 10) * -1));
            else
                mapPntPx = mapPntPx.add((275 - this.popPnt.x), ((this.popPnt.y - 10) * -1));

            this.popup.lonlat = this.map.getLonLatFromViewPortPx(mapPntPx);
            this.popup.updatePosition();

            this.drawMarkup(this.popup.startPosition, this.popup.lonlat);

        }
        OpenLayers.Event.stop(evt);
    },

    drawMarkup: function (startLonLat, endLonLat) {

        var points = [
            new OpenLayers.Geometry.Point(startLonLat.lon, startLonLat.lat),
            new OpenLayers.Geometry.Point(endLonLat.lon, endLonLat.lat)
        ];

        this.markupLayer.destroyFeatures();

        var pointFeature = new OpenLayers.Feature.Vector(
            new OpenLayers.Geometry.Point(startLonLat.lon, startLonLat.lat),
            null,
            {
                fillColor: "#ee9900",
                strokeColor: "#ee9900",
                pointRadius: 3
            }            
        );

        var feature = new OpenLayers.Feature.Vector(
            new OpenLayers.Geometry.LineString(points)
        );

        this.markupLayer.addFeatures(feature);
        this.markupLayer.addFeatures(pointFeature);
    },

    removeMarkup: function () {
        if (this.popup && this.popup.map) {
            if (this.markupLayer) {
                this.popup.map.removeLayer(this.markupLayer);
            }
            this.markupLayer = null;
        }
    },

    destroy: function () {
        this.removeMarkup();
        // Remove listeners
        this.popup.events.unregister('mousedown', this, this.mouseDown);
        this.popup.events.unregister('mouseup', this, this.mouseUp);
        this.popup.events.unregister('mousemove', this, this.mouseMove);
        this.map.events.unregister('mousemove', this, this.mouseMove);
        // Clear object references

        if (this.popup.map && this.markupLayer)
            this.popup.map.removeLayer(this.markupLayer);
        
        this.popup = null;
        this.popPnt = null;
        // allow our superclass to tidy up
        OpenLayers.Control.prototype.destroy.apply(this, []);


    },

    /** @final @type String */
    CLASS_NAME: "Beacon.Popup.MapTipFrameDragable"
});
