
Beacon.Control.MapMarkup = OpenLayers.Class(OpenLayers.Control, {

    html: null,

    currentTool: null,
    tools: null,
    divs: null,
    markupLayer: null,

    ddlColor: null,
    ddlSize: null,
    ddlPointType: null,
    txtTextbox: null,

    delayedSave: null,


    initialize: function (options) {
        OpenLayers.Control.prototype.initialize.apply(this, [options]);

        try {
            var that = this;

            // setup delayed save function
            this.delayedSave = new Beacon.DelayedFunction(function () {
                //console.info("SAVE MARKUP");
                that.saveMarkup();
            }, that, 537);


            this.markupLayer = new OpenLayers.Layer.Vector("Markup Layer", {
                styleMap: new OpenLayers.StyleMap({
                    temporary: OpenLayers.Util.applyDefaults({
                        pointRadius: 16
                    }, OpenLayers.Feature.Vector.style.temporary)
                })
            });

            this.markupLayer.events.register("beforefeatureadded", this, this.onFeatureAdded);
            this.markupLayer.events.register("beforefeaturemodified", this, this.onFeatureSelected);



            var vertexStyle = OpenLayers.Util.applyDefaults({
                fill: true,
                fillColor: "#fff",
                fillOpacity: 0.1,
                stroke: true,
                strokeColor: "#000",
                strokeWidth: 1.5,
                strokeOpacity: 0.75,
                graphic: true,
                graphicName: "square",
                pointRadius: 5
            }, OpenLayers.Feature.Vector.style["default"]);

            var vertexStyle2 = OpenLayers.Util.applyDefaults({
                fill: true,
                fillColor: "#fff",
                fillOpacity: 0.1,
                stroke: true,
                strokeColor: "#444",
                strokeWidth: 1.0,
                strokeOpacity: 0.75,
                graphic: true,
                graphicName: "square",
                pointRadius: 4
            }, OpenLayers.Feature.Vector.style["default"]);

            var dragStyle = OpenLayers.Util.applyDefaults({
                fill: false,
                fillColor: "#000",
                fillOpacity: 0.75,
                stroke: false,
                //            strokeColor: "#000",
                //            strokeWidth: 1.5,
                //            strokeOpacity: 0.75,
                graphic: true,
                externalGraphic: "/Images/Hover-Move-Arrows.png",
                graphicWidth: 19,
                graphicHeight: 19,
                //graphicName: "square",
                pointRadius: 5
            }, OpenLayers.Feature.Vector.style["default"]);


            //        this.strokeColor = "#f00";
            //        this.strokeWidth = 2;
            //        this.pointRadius = 10;
            //        this.graphicName = "circle";

            var handlerStyle = {
                pointRadius: 4,
                fillColor: "#fff",
                fillOpacity: 0.1
            };


            this.tools = {

                modify: new Beacon.Control.ModifyFeature(this.markupLayer, {
                    toggle: true,
                    mode: Beacon.Control.ModifyFeature.RESHAPE | Beacon.Control.ModifyFeature.DRAG,
                    virtualStyle: vertexStyle2,
                    vertexStyle: vertexStyle,
                    dragStyle: dragStyle,
                    onModificationStart: function (f) { that.featureSelected(f); },
                    onModificationEnd: function (f) { that.featureUnselected(f); }
                }),

                point: new OpenLayers.Control.DrawFeature(this.markupLayer, OpenLayers.Handler.Point,
                        { handlerOptions: { style: handlerStyle} }),

                line: new OpenLayers.Control.DrawFeature(this.markupLayer, OpenLayers.Handler.Path,
                        { handlerOptions: { style: handlerStyle, doubleTouchTolerance: 30} }),

                polygon: new OpenLayers.Control.DrawFeature(this.markupLayer, OpenLayers.Handler.Polygon,
                        { handlerOptions: { style: handlerStyle, doubleTouchTolerance: 30} }),

                freehand: new OpenLayers.Control.DrawFeature(this.markupLayer, OpenLayers.Handler.Path,
                        { handlerOptions: {
                            freehand: true,
                            style: handlerStyle
                        }
                        }),

                text: new OpenLayers.Control.DrawFeature(this.markupLayer, OpenLayers.Handler.Point,
                        { handlerOptions: { style: handlerStyle} })

            };




            // OL2.11RC1 hack for freehand/touch support:
            var h = this.tools.freehand.handler;

            h.addPoint = function (pixel) {
                this.layer.removeFeatures([this.point]);
                var lonlat = this.control.map.getLonLatFromPixel(pixel);

                //Hack to correct 1st point of touch
                if (!this.point.geometry.x) {
                    this.point.geometry.x = lonlat.lon;
                    this.point.geometry.y = lonlat.lat;
                    this.point.geometry.clearBounds();
                    this.line.geometry.clearBounds();
                }
                //End of hack

                this.point = new OpenLayers.Feature.Vector(
                        new OpenLayers.Geometry.Point(lonlat.lon, lonlat.lat)
                    );
                this.line.geometry.addComponent(
                        this.point.geometry, this.line.geometry.components.length
                    );
                this.layer.addFeatures([this.point]);
                this.callback("point", [this.point.geometry, this.getGeometry()]);
                this.callback("modify", [this.point.geometry, this.getSketch()]);
                this.drawFeature();
            };








            this.divs = {
                modify: $("#mmModify")[0],

                point: $("#mmPoint")[0],
                line: $("#mmLine")[0],
                polygon: $("#mmPolygon")[0],
                freehand: $("#mmFreehand")[0],
                text: $("#mmText")[0],

                deleteFeature: $("#mmDeleteFeature")[0],
                copySelection: $("#mmCopySelection")[0],
                clearAll: $("#mmClearAll")[0]

            };

            this.ddlColor = $("#mmColor2")[0];
            this.ddlPointType = $("#mmPointType2")[0];
            this.ddlSize = $("#mmSize")[0];
            this.txtTextbox = $("#mmTextbox")[0];

            this.html = $('#MapMarkupPanel').detach();


        } catch (e) {
            Beacon.MapJS.logException(e, "MapMarkup.init");

        }

    },

    draw: function () {
        OpenLayers.Control.prototype.draw.apply(this, arguments);

        if (!this.element) {
            this.element = document.createElement('div');
            this.element.id = this.id + '_frame';
            OpenLayers.Element.addClass(this.element, this.displayClass + 'Frame');

            //re-attach the div we detached in init
            $(this.element).append(this.html);
            this.html.show();

        }


        this.div.appendChild(this.element);


        //prevent clicks from hitting the map 
        this.sink = new Beacon.Control.EventSink();
        this.sink.register(this.div);


        return this.div;

    },

    setMap: function (map) {
        OpenLayers.Control.prototype.setMap.apply(this, [map]);

        this.map.addLayers([this.markupLayer]);

        for (key in this.tools) {
            this.tools[key].setMap(map);
        }

        this.loadMarkup();

    },

    activate: function () {
        try {
            var r = OpenLayers.Control.prototype.activate.apply(this);
            if (r) {

                //hack: disable the keyboard controls
                //var kbdCtrl = this.map.getControlsByClass("OpenLayers.Control.KeyboardDefaults");
                //if (kbdCtrl.length) kbdCtrl[0].deactivate();
                Beacon.MapJS.controls.kbdNavigation.deactivate();

                OpenLayers.Event.observe(this.divs.modify, "click",
                OpenLayers.Function.bindAsEventListener(function () { this.activateTool(this.tools.modify); }, this));

                OpenLayers.Event.observe(this.divs.point, "click",
                OpenLayers.Function.bindAsEventListener(function () { this.activateTool(this.tools.point) }, this));

                OpenLayers.Event.observe(this.divs.line, "click",
                OpenLayers.Function.bindAsEventListener(function () { this.activateTool(this.tools.line) }, this));

                OpenLayers.Event.observe(this.divs.polygon, "click",
                OpenLayers.Function.bindAsEventListener(function () { this.activateTool(this.tools.polygon) }, this));

                OpenLayers.Event.observe(this.divs.freehand, "click",
                OpenLayers.Function.bindAsEventListener(function () { this.activateTool(this.tools.freehand) }, this));

                OpenLayers.Event.observe(this.divs.text, "click",
                OpenLayers.Function.bindAsEventListener(function () { this.activateTool(this.tools.text) }, this));

                OpenLayers.Event.observe(this.divs.deleteFeature, "click",
                OpenLayers.Function.bindAsEventListener(this.deleteFeature, this));

                OpenLayers.Event.observe(this.divs.copySelection, "click",
                OpenLayers.Function.bindAsEventListener(this.copySelection, this));

                OpenLayers.Event.observe(this.divs.clearAll, "click",
                OpenLayers.Function.bindAsEventListener(this.clearAll, this));


                if (this.currentTool) {
                    this.activateTool(this.currentTool);
                } else {
                    this.activateTool(this.tools.modify);
                }



                var that = this;

                var parentCtrl = $("#mmColor2");
                var childCtrl = $("#mmColorPicker");
                var pos = parentCtrl.position();
                var height = parentCtrl.height();

                //console.info(pos);

                childCtrl.css('left', pos.left);
                childCtrl.css('top', pos.top + height + 4);
                //childCtrl.show();

                parentCtrl.click(function () {
                    childCtrl.slideToggle(200);
                });

                $(".mmColorSwatch").click(function () {
                    //color click handler
                    var newColor = $(this).css('background-color');
                    parentCtrl.css('background-color', newColor);
                    childCtrl.hide();
                    that.onChangeColor.apply(that);
                });





                var markerButton = $("#mmPointType2");
                var markerPos = markerButton.position();
                var markerHeight = markerButton.height();
                var markerPalette = $("#mmMarkerPicker");
                markerPalette.css('left', markerPos.left);
                markerPalette.css('top', markerPos.top + markerHeight + 4);

                markerButton.click(function () {
                    markerPalette.slideToggle(200);
                });

                $(".mmMarkerSwatch").click(function () {
                    //marker click handler
                    var newMarker = $(this).attr('data-marker');
                    var newImage = $(this).css('background-image');

                    markerButton.attr('data-marker', newMarker);
                    markerButton.css('background-image', newImage);
                    markerPalette.hide();
                    that.onChangePointType.apply(that);

                });

                //OpenLayers.Event.observe(this.ddlColor, "change", OpenLayers.Function.bindAsEventListener(this.onChangeColor, this));
                //OpenLayers.Event.observe(this.ddlPointType, "change", OpenLayers.Function.bindAsEventListener(this.onChangePointType, this));
                OpenLayers.Event.observe(this.ddlSize, "change", OpenLayers.Function.bindAsEventListener(this.onChangeSize, this));

                OpenLayers.Event.observe(this.txtTextbox, "change", OpenLayers.Function.bindAsEventListener(this.onChangeText, this));
                OpenLayers.Event.observe(this.txtTextbox, "keydown", OpenLayers.Function.bindAsEventListener(this.onKeyDown, this));

                $("BODY").addClass('disableTextSelection');

                //update ui
                this.featureUnselected();

                Beacon.GA.TrackEvent('MapMarkup', 'Opened');
            }
            return r;

        } catch (e) {
            Beacon.MapJS.logException(e, "MapMarkup.activate");
        }

    },

    deactivate: function () {
        try {
            var r = OpenLayers.Control.prototype.deactivate.apply(this);
            //console.info("deactivate");

            if (r) {

                for (key in this.divs) {
                    var toolDiv = this.divs[key];
                    var toolControl = this.tools[key];
                    if (toolControl) toolControl.deactivate();

                    OpenLayers.Event.stopObservingElement(toolDiv);
                }

                $("BODY").removeClass('disableTextSelection');

                $("#mmColor2").unbind();
                $(".mmColorSwatch").unbind();
                $("#mmPointType2").unbind();
                $(".mmMarkerSwatch").unbind();

                //hack: re-enable the keyboard controls
//                var kbdCtrl = this.map.getControlsByClass("OpenLayers.Control.KeyboardDefaults");
//                if (kbdCtrl.length) kbdCtrl[0].activate();
                Beacon.MapJS.controls.kbdNavigation.activate();

            }
            return r;
        } catch (e) {
            Beacon.MapJS.logException(e, "MapMarkup.deactivate");

        }

    },

    activateTool: function (newTool) {
        try {
            for (key in this.tools) {
                var control = this.tools[key];
                var toolDiv = this.divs[key];

                if (newTool == control) {
                    control.activate();
                    $(toolDiv).addClass("mmActive");
                } else {
                    control.deactivate();
                    $(toolDiv).removeClass("mmActive");
                }
            }
            this.currentTool = newTool;
            this.updateStyleOnCurrentTool();

            if (this.currentTool == this.tools.point || this.currentTool == this.tools.modify) {
                //$(this.ddlPointType).attr('disabled', '');
            } else {
                //$(this.ddlPointType).attr('disabled', '1');
            }
        } catch (e) {
            Beacon.MapJS.logException(e, "MapMarkup.activateTool");
        }

    },


    deleteFeature: function () {
        try {
            var f = this.tools.modify.feature;
            if (f) {
                this.tools.modify.unselectFeature(f);
                this.markupLayer.removeFeatures([f]);
                this.featureUnselected();
            }
        } catch (e) {
            Beacon.MapJS.logException(e, "MapMarkup.delete");

        }
    },

    clearAll: function () {
        try {
            this.markupLayer.removeAllFeatures();
        } catch (e) {
            Beacon.MapJS.logException(e, "MapMarkup.clear");

        }
    },

    copySelection: function () {

        try {
            var slyr = Beacon.MapJS.selectionLayer;
            var features = slyr.features;
            var settings = this.getStyleSettingsFromUi();

            if (features.length == 0) return;


            //take out of selection layer:
            slyr.removeFeatures(features);
            this.markupLayer.addFeatures(features);

            var geomClass = features[0].geometry.CLASS_NAME;

            var newStyle;
            var splitParts;

            if (geomClass == "OpenLayers.Geometry.Point" || geomClass == "OpenLayers.Geometry.MultiPoint") {
                newStyle = this.getStyleFromUiFor("MARKER", settings);
                splitParts = true;
            } else {
                newStyle = this.getStyleFromUiFor("GEOMETRY", settings);
                splitParts = false;
            }

            for (var i = 0; i < features.length; i++) {
                var f = features[i];

                if (splitParts) {
                    //convert multipoint down to point to keep markup layer happy

                    var ptGeom = f.geometry.components[0];
                    f.geometry = ptGeom;

                }

                // assign and fill in missing settings from default style
                f.style = OpenLayers.Util.applyDefaults(newStyle, OpenLayers.Feature.Vector.style["default"]);

                this.markupLayer.drawFeature(f);
            }

        } catch (e) {

            Beacon.MapJS.logException(e, "MapMarkup.copy");
        }

    },

    updateStyleOnCurrentTool: function () {

        var s = this.getStyleSettingsFromUi();

        if (this.currentTool && this.currentTool.handler) {
            this.currentTool.handler.style.strokeColor = s.color; // this.strokeColor;
            this.currentTool.handler.style.strokeWidth = s.linewidth; // this.strokeWidth;
        }

    },


    updateStyleOnFeature: function (f) {
        try {
            //set symbology - fill in missing style settings from default
            if (f) {
                var settings = this.getStyleSettingsFromUi();
                var newStyle = this.getStyleFromUiFor(f.style.annoType, settings);
                f.style = newStyle;

                this.markupLayer.drawFeature(f);
            }

            this.delayedSave.delayedInvoke();
        } catch (e) {
            Beacon.MapJS.logException(e, "MapMarkup.updateStyle");

        }

    },

    getStyleSettingsFromUi: function () {
        return this.calculateStyleSettings(
            $(this.ddlColor).css('background-color'),
        //$(this.ddlColor).val(),
            $(this.ddlSize).val(),
        //$(this.ddlPointType).val(),
            $(this.ddlPointType).attr('data-marker'),
            $(this.txtTextbox).val()
        );

    },

    calculateStyleSettings: function (color, size, pointType, label) {
        return {
            color: color,
            linewidth: size,
            fontsize: (8 + size * 2) + 'px',
            graphictype: pointType,
            graphicradius: size * 3,
            label: label,
            labelOffset: (size * 3) + 5
        };
    },

    getStyleFromUiFor: function (annoType, settings) {

        var newStyle;
        var s = settings; // this.getStyleSettingsFromUi();

        // Label/Text tool (a specialized point w/label and type=none)
        if (annoType == "TEXT") {

            // we are adding text - a special form of point
            newStyle = {
                fillColor: "#fff",
                fillOpacity: 0.1,
                strokeColor: s.color,
                strokeWidth: 2,
                graphic: false,
                graphicName: "",
                label: s.label,
                labelAlign: 'cm',
                labelYOffset: 0,
                fontColor: s.color,
                fontSize: s.fontsize,
                fontWeight: 'bold',
                labelSelect: true,
                annoType: 'TEXT',
                annoSize: s.linewidth
            };

        }

        if (annoType == "MARKER") {

            newStyle = {
                fillColor: "#fff",
                fillOpacity: 0.1,
                strokeColor: s.color,
                strokeWidth: 2,
                graphic: true,
                graphicName: s.graphictype,
                pointRadius: s.graphicradius,
                label: s.label,
                labelAlign: 'cb',
                labelYOffset: s.labelOffset,
                fontColor: s.color,
                fontSize: s.fontsize,
                fontWeight: 'bold',
                labelSelect: false,
                annoType: 'MARKER',
                annoSize: s.linewidth
            };

        }

        if (annoType == "GEOMETRY") {

            newStyle = {
                fillColor: "#fff",
                fillOpacity: 0.1,
                strokeColor: s.color,
                strokeWidth: s.linewidth,
                graphic: false,
                label: s.label,
                //labelAlign: 'cm',
                fontColor: s.color,
                fontSize: s.fontsize,
                fontWeight: 'bold',
                labelSelect: false,
                annoType: 'GEOMETRY',
                annoSize: s.linewidth
            };

        }

        return newStyle;

    },

    onFeatureAdded: function (e) {
        var f = e.feature;

        if (f.style) return; //skip items that are pre-styled (loaded from svr)

        var newStyle;
        var settings = this.getStyleSettingsFromUi();

        // Label/Text tool (a specialized point w/label and type=none)
        if (this.currentTool == this.tools.text) {

            newStyle = this.getStyleFromUiFor("TEXT", settings);

        }

        if (this.currentTool == this.tools.point) {
            newStyle = this.getStyleFromUiFor("MARKER", settings);


        }

        if (this.currentTool == this.tools.line
            || this.currentTool == this.tools.freehand
            || this.currentTool == this.tools.polygon) {

            newStyle = this.getStyleFromUiFor("GEOMETRY", settings);

        }

        // assign and fill in missing settings from default style
        f.style = OpenLayers.Util.applyDefaults(newStyle, OpenLayers.Feature.Vector.style["default"]);

        return true;
    },

    onFeatureSelected: function (e) {

        var f = e.feature;
        var s = f.style;

        //$(this.ddlColor).val(s.strokeColor);
        $(this.ddlColor).css('background-color', s.strokeColor);
        $(this.ddlSize).val(s.annoSize);
        $(this.txtTextbox).val(s.label);
        if (s.annoType != "TEXT") {
            //$(this.ddlPointType).val(s.graphicName);
            var swatch = $("DIV.mmMarkerSwatch[data-marker=" + s.graphicName + "]");
            if (swatch.length > 0) {
                $(this.ddlPointType).attr('data-marker', s.graphicName);
                $(this.ddlPointType).css('background-image', swatch.css('background-image'));
            }
        }

    },

    onChangeColor: function () {
        if (this.currentTool == this.tools.modify) {
            var f = this.tools.modify.feature;
            this.updateStyleOnFeature(f);
        }
        this.updateStyleOnCurrentTool();
    },

    onChangePointType: function () {
        if (this.currentTool == this.tools.modify) {
            var f = this.tools.modify.feature;
            this.updateStyleOnFeature(f);
        }
        this.updateStyleOnCurrentTool();
    },

    onChangeSize: function () {
        if (this.currentTool == this.tools.modify) {
            var f = this.tools.modify.feature;
            this.updateStyleOnFeature(f);
        }
        this.updateStyleOnCurrentTool();
    },

    onChangeText: function () {
        if (this.currentTool == this.tools.modify) {
            var f = this.tools.modify.feature;
            this.updateStyleOnFeature(f);
        }
        this.updateStyleOnCurrentTool();
    },

    onKeyDown: function (e) {
        if (e.keyCode == 13 || e.keyCode == 9) {
            this.onChangeText();
            this.txtTextbox.select();
            OpenLayers.Event.stop(e);
        }
    },

    loadMarkup: function () {
        try {

            var slyr = Beacon.MapJS.selectionLayer;
            var features = slyr.features;

            var wktWriter = new OpenLayers.Format.WKT();

            Beacon.API.LoadMapMarkup(
            function (d) {

                if (d && d.Features) {

                    for (var i = 0; i < d.Features.length; i++) {

                        var f = d.Features[i];

                        var v = wktWriter.read(f.WKT);

                        var settings = this.calculateStyleSettings(f.Color, f.Size, f.PointType, f.Label);
                        var newStyle = this.getStyleFromUiFor(f.AnnoType, settings);

                        // assign and fill in missing settings from default style
                        v.style = OpenLayers.Util.applyDefaults(newStyle, OpenLayers.Feature.Vector.style["default"]);

                        this.markupLayer.addFeatures([v]);
                        //this.markupLayer.drawFeature(v);
                    }
                }

                // load is complete
                this.doneLoading();
            },
            function () {
                Beacon.MapJS.showRetryActivity();
                this.doneLoading();
            },
            this);

        } catch (e) {
            Beacon.MapJS.logException(e, "MapMarkup.load");

        }
    },

    doneLoading: function () {

        this.markupLayer.events.register("featuresadded", this, function () { this.delayedSave.delayedInvoke(); });
        this.markupLayer.events.register("featuresremoved", this, function () { this.delayedSave.delayedInvoke(); });
        this.markupLayer.events.register("featuremodified", this, function () { this.delayedSave.delayedInvoke(); });

    },

    saveMarkup: function () {

        try {
            var markupFeatures = [];
            var markupData = { Features: markupFeatures }; // for the server

            var wktWriter = new OpenLayers.Format.WKT();

            var features = this.markupLayer.features;

            for (var i = 0; i < features.length; i++) {
                var f = features[i];

                if (f.style && f.style.annoType) {
                    var s = f.style;

                    var markupFeature = {
                        WKT: wktWriter.write(f),
                        Label: s.label,
                        Color: s.strokeColor,
                        Size: s.annoSize,
                        AnnoType: s.annoType,
                        PointType: s.graphicName || null
                    };

                    markupFeatures.push(markupFeature);
                }

            }

            // send
            Beacon.API.SaveMapMarkup(markupData,
                function () { }, //success
                function () { //fail
                    Beacon.MapJS.showRetryActivity();
                },
                this);

        } catch (e) {
            Beacon.MapJS.logException(e, "MapMarkup.save");

        }

    },

    featureSelected: function (feature) {
        $(".sprite", this.divs.deleteFeature).removeClass("MarkupDelete-disabled").addClass("MarkupDelete");
    },

    featureUnselected: function () {
        $(".sprite", this.divs.deleteFeature).removeClass("MarkupDelete").addClass("MarkupDelete-disabled");
    },

    CLASS_NAME: "Beacon.Control.MapMarkup"
});

