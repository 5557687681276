/* this was adapted from Openlayers/controls/geolocate.js */

Beacon.Control.Geolocate = OpenLayers.Class(OpenLayers.Control, {

    EVENT_TYPES: ["locationupdated", "locationfailed", "locationuncapable"],

    geolocation: navigator.geolocation,
    bind: false,
    watch: false,
    geolocationOptions: null,
    waitingOnFirstLocation: false,
    lastObservedPosition: null,

    initialize: function (options) {
        // concatenate events specific to this control with those from the base
        this.EVENT_TYPES =
            Beacon.Control.Geolocate.prototype.EVENT_TYPES.concat(
                OpenLayers.Control.prototype.EVENT_TYPES
            );
        this.geolocationOptions = {};
        OpenLayers.Control.prototype.initialize.apply(this, [options]);
    },

    destroy: function () {
        this.deactivate();
        OpenLayers.Control.prototype.destroy.apply(this, arguments);
    },

    activate: function () {
        if (!this.geolocation) {
            this.events.triggerEvent("locationuncapable");
            return false;
        }
        if (OpenLayers.Control.prototype.activate.apply(this, arguments)) {
            if (this.watch) {
                this.waitingOnFirstLocation = true;
                this.watchId = this.geolocation.watchPosition(
                    OpenLayers.Function.bind(this.geolocate, this),
                    OpenLayers.Function.bind(this.failure, this),
                    this.geolocationOptions
                );
            } else {
                this.getCurrentLocation();
            }
            return true;
        }
        return false;
    },

    deactivate: function () {
        if (this.active && this.watchId !== null) {
            this.geolocation.clearWatch(this.watchId);
        }
        return OpenLayers.Control.prototype.deactivate.apply(
            this, arguments
        );
    },

    geolocate: function (position) {

        if (position.coords.latitude == 0 && position.coords.longitude == 0) return; //bad coord, unless you are in the atlantic ocean

        var center = new OpenLayers.LonLat(
            position.coords.longitude,
            position.coords.latitude
        ).transform(
            new OpenLayers.Projection("EPSG:4326"),
            this.map.getProjectionObject()
            );
        if (this.bind) {
            //this.map.setCenter(center);
            this.map.panTo(center); // this on does a smooth pan instead of instantly changing the view
        }
        this.lastObservedPosition = {
            position: position,
            point: new OpenLayers.Geometry.Point(
                center.lon, center.lat
            ),
            center: center, //type: OpenLayers.LonLat
            isFirstPosition: this.waitingOnFirstLocation
        };
        this.events.triggerEvent("locationupdated", this.lastObservedPosition);
        this.waitingOnFirstLocation = false;
    },

    getCurrentLocation: function () {
        if (!this.active || this.watch) {
            return false;
        }
        this.geolocation.getCurrentPosition(
            OpenLayers.Function.bind(this.geolocate, this),
            OpenLayers.Function.bind(this.failure, this),
            this.geolocationOptions
        );
        return true;
    },

    failure: function (error) {
        this.events.triggerEvent("locationfailed", { error: error });
    },

    CLASS_NAME: "Beacon.Control.Geolocate"
});
