Beacon.Layer.TrackGPS = OpenLayers.Class(OpenLayers.Layer.Vector, {

    btnGpsMode: null,
    btnGpsModeTip: null,

    geolocate: null,

    visibility: false,

    waitingForFirstPosition: false,
    mapIsMoving: false, // set when gps is causing the map to pan
    userMovingMap: false, // set when user is dragging the map around

    precisionStyle: {
        fillColor: '#00D0FF',
        fillOpacity: 0.2,
        strokeColor: '#00D0FF',
        strokeWidth: 1,
        strokeOpacity: 0.5
    },

    postitionStyle: {
        graphicName: 'circle',
        strokeColor: '#ffffff',
        strokeWidth: 1,
        fillOpacity: 1.0,
        fillColor: '#00D0FF',
        pointRadius: 6
    },


    initialize: function (options) {
        OpenLayers.Layer.Vector.prototype.initialize.apply(this, arguments);

        this.btnGpsMode = $("#btnGpsMode");
        this.btnGpsMode.click(OpenLayers.Function.bind(this.onBtnGpsModeClick, this));

        this.btnGpsModeTip = $("#btnGpsModeTip");

        if (navigator.geolocation) {
            this.btnGpsMode.show();
        }

    },

    destroy: function () {

    },

    setMap: function (map) {
        OpenLayers.Layer.Vector.prototype.setMap.apply(this, arguments);

    },

    initTracking: function () {

        if (this.geolocate) return;

        this.geolocate = new Beacon.Control.Geolocate({
            bind: false,
            geolocationOptions: {
                enableHighAccuracy: true,
                maximumAge: 0,
                timeout: 7000
            }
        });

        this.map.addControl(this.geolocate);


        this.geolocate.events.register("locationupdated", this, this.newPointReceived);

        this.geolocate.events.register("locationfailed", this, function (e) {
            OpenLayers.Console.log('Location detection failed');
            this.showMessage(e.error.messge || "Geolocation Failure");
        });

        this.map.events.register("moveend", this, function () {
            this.userMovingMap = false;
            this.userInteraction();
        });
        this.map.events.register("movestart", this, function () {
            this.userMovingMap = true;
        });

    },

    userInteraction: function () {
        // if a user pans the current gps off the map, then turn off tracking

        var lastPos = this.geolocate.lastObservedPosition;
        if (!lastPos) return;

        if (this.mapIsMoving) return;

        var mapExt = this.map.getExtent();
        if (!mapExt.containsLonLat(lastPos.center)) {
            this.stopTracking();
        }
    },

    newPointReceived: function (e) {

        var accuracyInFeet = e.position.coords.accuracy / 0.3048;

        this.removeAllFeatures();

        this.addFeatures([
            new OpenLayers.Feature.Vector(
                e.point,
                {},
                this.postitionStyle
            ),
            new OpenLayers.Feature.Vector(
                OpenLayers.Geometry.Polygon.createRegularPolygon(new OpenLayers.Geometry.Point(e.point.x, e.point.y), accuracyInFeet, 40, 0),
                {},
                this.precisionStyle
            )
        ]);


        // limit position updates to this area:
        var ext = new OpenLayers.Bounds(mapConfig.OverviewExtent.minx,
            mapConfig.OverviewExtent.miny,
            mapConfig.OverviewExtent.maxx,
            mapConfig.OverviewExtent.maxy);

        if (!ext.containsLonLat(e.center)) {
            this.showMessage("Location too far away");
            return;
        }

        this.mapIsMoving = true;

        if (e.isFirstPosition) {
            if (this.map.getScale() > 4800) {
                this.map.zoomToScale(4800);
            }
        }


        // only force a pan if the point is not on the map
        var mapExt = this.map.getExtent();
        var safeZone = mapExt.scale(0.66);
        if (!safeZone.containsLonLat(e.center) && !this.userMovingMap) {
            this.map.panTo(e.center);
        }


        this.mapIsMoving = false;

    },

    startTracking: function () {

        this.removeAllFeatures();

        this.initTracking();

        this.waitingForFirstPosition = true;

        this.geolocate.watch = true;
        this.geolocate.activate();

        this.btnGpsMode.addClass("active");
        this.setVisibility(true);

        Beacon.GA.TrackEvent('TrackGPS', 'Click');

    },

    stopTracking: function () {
        this.btnGpsMode.removeClass("active");

        this.geolocate.watch = false;
        this.geolocate.deactivate();

        this.setVisibility(false);
    },

    onBtnGpsModeClick: function () {
        if (this.getVisibility()) {
            this.stopTracking();
        } else {
            this.startTracking();
        }
    },

    showMessage: function (msg) {
        $(".tooltip-inner", this.btnGpsModeTip).text(msg);

        this.btnGpsModeTip
            .css('display', 'none')
            .css('opacity', '1');

        this.btnGpsModeTip
            .stop()
            .fadeIn(200)
            .delay(3000)
            .fadeOut(200);
    },

    CLASS_NAME: 'Beacon.Layer.TrackGPS'
});