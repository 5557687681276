Beacon.Control.ZoomToSelection = OpenLayers.Class(OpenLayers.Control, {

    selectionLayer: null,

    type: OpenLayers.Control.TYPE_BUTTON,

    initialize: function (selectionLayer, options) {
        OpenLayers.Control.prototype.initialize.apply(this, [options]);

        this.selectionLayer = selectionLayer;
        this.deactivate();

        this.selectionLayer.events.register("featuresadded", this, this.onFeaturesAdded);
        this.selectionLayer.events.register("featuresremoved", this, this.onFeaturesRemoved);

    },

    trigger: function () {

        try {
            if (this.active) {
                var ext = this.selectionLayer.getDataExtent();
                Beacon.MapJS.zoomToFeatureExtent(ext);
            }
        } catch (e) {
            Beacon.MapJS.showRetryActivity(e, "ZoomToSelection");

        }

    },

    onFeaturesAdded: function () {
        if (!this.active) this.activate();
    },

    onFeaturesRemoved: function () {

        if (this.active) {

            var hasFeatures = (this.selectionLayer.features && this.selectionLayer.features.length > 0);

            if (!hasFeatures) this.deactivate();

        }
    },

    CLASS_NAME: "Beacon.Control.ZoomToSelection"
});
