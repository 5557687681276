Beacon.Control.GoogleStreetview = OpenLayers.Class(OpenLayers.Control, {

    handler: null,
//    hover: null,
//    cbTimer: null,
//    lastPt: null,

    // official Google Maps URL documentation: https://developers.google.com/maps/documentation/urls/guide
    gMapsPanoUrl: "https://www.google.com/maps/@?api=1&map_action=pano",

    initialize: function (options) {

        OpenLayers.Control.prototype.initialize.apply(this, [options]);


        this.handler = new OpenLayers.Handler.Click(this, {
            click: this.click
        }, {
            pixelTolerance: 10
        });

//        this.hover = new OpenLayers.Handler.Hover(this,
//        {
//            move: this.onMove
//        },
//        {
//            pixelTolerance: null,
//            delay: 250
//        });
    },

    setMap: function (map) {
        OpenLayers.Control.prototype.setMap.apply(this, arguments);

//        this.hover.setMap(map);
    },

    activate: function () {
        if (!this.active) {

//            this.loadScript();

            this.handler.activate();
//            this.hover.activate();

//            var t = this;
//            this.cbTimer = setInterval(function () { t.onInterval.call(t); }, 250);

        }
        return OpenLayers.Control.prototype.activate.apply(this, arguments);
    },

    deactivate: function () {
        if (this.active) {
            this.handler.deactivate();
//            this.hover.deactivate();
//            $(this.map.viewPortDiv).removeClass("olCursorNotAllowed");

//            if (this.cbTimer) clearInterval(this.cbTimer);
//            this.cbTimer = null;
        }
        return OpenLayers.Control.prototype.deactivate.apply(this, arguments);
    },


    click: function (evt) {

        Beacon.GA.TrackEvent('Streetview', 'Click');

        try {
            var pt = this.unproject(evt.xy);

//            this.isPanoAvailable(pt, this, function (available) {

//                if (available) {
//                    $.colorbox({
//                        title: 'Google Streetview',
//                        iframe: true,
//                        innerWidth: "800px",
//                        innerHeight: "600px",
//                        href: "/Streetview.aspx?x=" + pt.lon + "&y=" + pt.lat
//                    });
//                }

//            });

            var url = this.gMapsPanoUrl + "&viewpoint=" + pt.lat + "," + pt.lon

            window.open(url, '_blank');

        } catch (e) {
            Beacon.MapJS.showRetryActivity(e, "GoogleStreetview.Click");

        }

    },

//    onMove: function (e) {
//        this.lastPt = e.xy;
//    },

//    onInterval: function () {
//        if (!this.lastPt) return;

//        var d = this.map.viewPortDiv;
//        var pt = this.unproject(this.lastPt);

//        this.lastPt = null;

//        this.isPanoAvailable(pt, this, function (available) {
//            if (available || !this.active) {
//                $(d).removeClass("olCursorNotAllowed");
//            } else {
//                $(d).addClass("olCursorNotAllowed");
//            }
//        });


//    },


    unproject: function (xy) {

        var pt = this.map.getLonLatFromPixel(xy);

        // so here we need to make sure proj4.js is available?

        var prjLL = new OpenLayers.Projection("EPSG:4326"); //WGS84
        pt.transform(this.map.projection, prjLL);

        return pt;
    },

//    isPanoAvailable: function (pt, scope, callback) {

//        panoClient = new google.maps.StreetViewService();

//        var p1 = new google.maps.LatLng(pt.lat, pt.lon);

//        panoClient.getPanoramaByLocation(p1, 50, function (data, status) {
//            callback.call(scope, (status == google.maps.StreetViewStatus.OK));
//        });

//    },

//    loadScript: function () {
//        // this wil dynamically load the gmaps api when the tool is first activated

//        if (window.google && window.google.maps) {
//            // console.info('already loaded');
//            return;
//        }

//        var script = document.createElement("script");
//        script.type = "text/javascript";
//        script.src = "http://maps.googleapis.com/maps/api/js?key=AIzaSyCqnu2Xqty28uuX6eMYuvdluSBh3Jxnz-c&sensor=false&callback=gminit1122"; //
//        document.body.appendChild(script);
//    },

    CLASS_NAME: "Beacon.Control.GoogleStreetview"
});

//function gminit1122() {
//    //console.info("hey, i'm running!");
//}