Beacon.Control.EsriLogo = OpenLayers.Class(OpenLayers.Control, {

    sink: null,

    initialize: function (options) {
        OpenLayers.Control.prototype.initialize.apply(this, [options]);
    },

    draw: function () {
        OpenLayers.Control.prototype.draw.apply(this, arguments);
         
        var img = document.createElement("img");
        img.src = "/images/powered-by-esri-logo-med.png";
        img.alt = "";

        this.div.appendChild(img);

        //prevent clicks from hitting the map 
        this.sink = new Beacon.Control.EventSink();
        this.sink.register(this.div);

        return this.div;
    },

    CLASS_NAME: "Beacon.Control.EsriLogo"
});

