Beacon.Control.ToolWithAuxControl = OpenLayers.Class(OpenLayers.Control, {

    useActiveIcon: null,
    dangle_div: null,
    contentControl: null, //the control that is the "sub" that drops down...
    contentControlVisible: false,

    initialize: function (options) {
        OpenLayers.Control.prototype.initialize.apply(this, [options]);
        this.contentControlVisible = false;
    },

    setMap: function (map) {
        OpenLayers.Control.prototype.setMap.apply(this, arguments);
    },

    activate: function () { // Called every time a user clicks the tool

        if (!this.active) {
            this.showContentControl();

        } else {
            //already active, toggle the dropdown
            this.toggleContentControl();
        }
        return OpenLayers.Control.prototype.activate.apply(this, arguments);
    },

    deactivate: function () {
        if (this.active) {
            this.hideContentControl();
            this.contentControl.deactivate();
        }
        return OpenLayers.Control.prototype.deactivate.apply(this, arguments);
    },

    createPanel: function () {

        var vport = $(this.map.viewPortDiv).offset();
        var ctrl = $(this.panel_div).offset(); // position of the parent button position

        var spacer = 4;

        var posDangle = new OpenLayers.Pixel(
            ctrl.left - vport.left - 2,
            ctrl.top - vport.top + 28);


        this.dangle_div = OpenLayers.Util.createDiv(
            this.id + 'dangle',
            posDangle,
            new OpenLayers.Size(30, spacer));

        this.dangle_div.className = 'mapToolbarDangle';
        this.dangle_div.className += " olControlNoSelect";
        this.dangle_div.setAttribute("unselectable", "on", 0);
        this.dangle_div.onselectstart = OpenLayers.Function.False;
        this.dangle_div.style.zIndex = 1020;

        this.map.viewPortDiv.appendChild(this.dangle_div);

        $(this.dangle_div).hide();

        var posContainer = new OpenLayers.Pixel(
            ctrl.left - vport.left - 2,
            ctrl.top - vport.top + 28 + spacer);


        this.map.addControl(this.contentControl, posContainer);




        // add events to sub controls

        this.contentControl.deactivate();

        $(this.contentControl.div).hide();

        // listen to any tools, if this is a toolbar or smells like a toolbar (has a property called "controls")
        if (this.contentControl.controls) {
            var ctrls = this.contentControl.controls;
            for (var i = 0; i < ctrls.length; i++) {
                var c = ctrls[i];
                if (c.type && c.type == OpenLayers.Control.TYPE_TOOL) {
                    // this is a tool, wire up events
                    c.events.register("activate", this, this.onSubToolActivate);
                }
            }
            // set icon based on 1st tool in toolbar:
            if (this.useActiveIcon) {
                //this.panel_div.className = ctrls[0].panel_div.className;
                this.panel_div.innerHTML = ctrls[0].panel_div.innerHTML;
            }
        }

        // wire up callback if its a dialog tool
        if (this.useExecuteCompleted) {
            this.contentControl.executeCompleted = this.onSubToolExecuteCompleted;
            this.contentControl.executeCompletedScope = this;
        }

    },

    onSubToolExecuteCompleted: function () {
        this.hideContentControl();
    },

    onSubToolActivate: function (e) {

        //steal the active tool's icon
        if (this.useActiveIcon) {
            var subTool = e.object;
            //this.panel_div.className = subTool.panel_div.className;
            this.panel_div.innerHTML = subTool.panel_div.innerHTML;
        }

        // Close sub content if a user clicks a tool
        //        if (!this.iAmCausingThis) {
        //            this.hideContentControl();
        //        }
    },

    showContentControl: function () {
        if (!this.contentControlVisible) {
            this.contentControlVisible = true;

            var t = this;

            $(this.contentControl.div).hide().slideDown(200);
            $(this.dangle_div).show();



            //Activate needs to happen after the panel begins to appear - otherwise some DIV elements
            // dont have sizes/positions yet
            // this.iAmCausingThis = true; // prevent my activation from re-closing the sub panel
            this.contentControl.activate();
            // this.iAmCausingThis = null;
        }
    },

    hideContentControl: function () {
        if (this.contentControlVisible) {
            this.contentControlVisible = false;

            var t = this;
            $(this.contentControl.div).slideUp(200, function () {
                $(t.dangle_div).hide();
                //t.contentControl.deactivate();
            });
        }
    },

    toggleContentControl: function () {

        if (this.contentControlVisible) {
            this.hideContentControl();
        } else {
            this.showContentControl();
        }

    },

    CLASS_NAME: "Beacon.Control.ToolWithAuxControl"

});
