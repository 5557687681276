
Beacon.Control.FullScreenToggle = OpenLayers.Class(OpenLayers.Control, {

    type: OpenLayers.Control.TYPE_BUTTON,
    selectionLayer: null,

    trigger: function () {
        this.selectionLayer.hideResultHtml();
        Beacon.ResultsPane.onCloseClick();
        Beacon.Tabs.hideLayersMenu();
    },

    initialize: function (selectionLayer, options) {
        OpenLayers.Control.prototype.initialize.apply(this, [options]);
        this.selectionLayer = selectionLayer;
    },

    destroy: function () {
        this.selectionLayer = null;
        OpenLayers.Control.prototype.destroy.apply(this, []);
    },

    CLASS_NAME: "Beacon.Control.FullScreenToggle"
});