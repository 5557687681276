
Beacon.Protocol.BeaconFeature = OpenLayers.Class(OpenLayers.Protocol, {


    /**
    * Property: callback
    * {Object} Function to be called when the <read>, <create>,
    *     <update>, <delete> or <commit> operation completes, read-only,
    *     set through the options passed to the constructor.
    */
    callback: null,

    /**
    * Property: scope
    * {Object} Callback execution scope, read-only, set through the
    *     options passed to the constructor.
    */
    scope: null,



    bounds: null,
    useSelection: true, // true: selection layer, false: whole active layer
    wktParser: null,

    /**
    * Constructor: Beacon.Protocol.BeaconFeature
    * A class for giving layers generic BeaconFeature protocol.
    *
    * Parameters:
    * options - {Object} Optional object whose properties will be set on the
    *     instance.
    *
    * Valid options include:
    * format - {<OpenLayers.Format>}
    * callback - {Function}
    * scope - {Object}
    */
    initialize: function (options) {
        options = options || {};
        OpenLayers.Protocol.prototype.initialize.apply(this, arguments);
        this.wktParser = new OpenLayers.Format.WKT();
    },

    /**
    * APIMethod: destroy
    * Clean up the protocol.
    */
    destroy: function () {
        OpenLayers.Protocol.prototype.destroy.apply(this);
        this.wktParser.destroy();
    },

    /**
    * APIMethod: read
    * Construct a request for reading new features.
    *
    * Parameters:
    * options - {Object} Optional object for configuring the request.
    *     This object is modified and should not be reused.
    *
    * Valid options:
    * url - {String} Url for the request.
    * params - {Object} Parameters to get serialized as a query string.
    * headers - {Object} Headers to be set on the request.
    * filter - {<OpenLayers.Filter>} Filter to get serialized as a
    *     query string.
    * readWithPOST - {Boolean} If the request should be done with POST.
    *
    * Returns:
    * {<OpenLayers.Protocol.Response>} A response object, whose "priv" property
    *     references the HTTP request, this object is also passed to the
    *     callback function when the request completes, its "features" property
    *     is then populated with the the features received from the server.
    */
    read: function (options) {
        OpenLayers.Protocol.prototype.read.apply(this, arguments);
        options = OpenLayers.Util.applyDefaults(options, this.options);

        var resp = new OpenLayers.Protocol.Response({ requestType: "read" });

        var ext = null;

        if (options.filter && options.filter.type == "BBOX") {
            var b = options.filter.value;
            ext = b; //{ minx: b.left, miny: b.bottom, maxx: b.right, maxy: b.top }
        }

        this.readFeatures(this.useSelection,
            ext,
            Beacon.API.SpatialRelation.Intersects,
            null,
            function (features) {
                resp.features = features;
                resp.code = OpenLayers.Protocol.Response.SUCCESS;
                options.callback.call(options.scope, resp);
            },
            function (e) {
                resp.code = OpenLayers.Protocol.Response.FAILURE;
                options.callback.call(options.scope, resp);
            },
            this);

        return resp;
    },

    //Can be called from anywhere -- returns OL features with hydrated geometries, etc
    readFeatures: function (useSelection, geom, spatialRelation, maxFeatures, success, failure, scope, scopeArgs) {
        var t = this;

        var extent = null;
        var wkt = null;

        //figure out what geom we have and convert as necessary
        if (geom) {
            if (geom instanceof OpenLayers.Bounds) {
                extent = geom;
            } else {
                wkt = this.wktParser.write({ geometry: geom }); //has to look like a feature
            }
        }


        var featureLimit = Beacon.BrowserCompatibility.limitResults || maxFeatures || 0; //This is a flag set for IE<9 users
      

        Beacon.API.GetVectorLayer(
            mapConfig.LayerId,
            useSelection,
            extent,
            wkt,
            spatialRelation,
            featureLimit,
            function (result) {

                var features = t.processQueryResult(result);

                //splice inserts features as first arg
                var args = [features];
                if (scopeArgs) {
                    args = args.concat(scopeArgs);
                }
                success.apply(scope, args);

            },
            function (e) {
                var args = [e];
                if (scopeArgs) {
                    args = args.concat(scopeArgs);
                }
                failure.apply(scope, args);
            },
            scope,
            scopeArgs
            );

    },

    processQueryResult: function (results) {

        var featureCount = results.length;

//        if (Beacon.limitResults && featureCount > 25) {
//            featureCount = 25;
//            alert('limit exceeded!');
//        }

        var features = [];
        for (var i = 0; i < featureCount; i++) {
            var f1 = results[i];
            var feature = this.wktParser.read(f1.WktGeometry);
            if (feature) {
            } else {
                feature = new OpenLayers.Feature.Vector(null);
            }
            feature.attributes = { key: f1.Key };
            feature.fid = f1.Key;
            feature.html = f1.ResultHtml;
            feature.tipHtml = f1.TipHtml;
            feature.parentId = f1.ParentId;
            feature.childIds = f1.ChildIds;
            features.push(feature);
        }

        return features;

    },

    //    readParentFeature: function (id, success, failure, scope, scopeArgs) {
    //        var t = this;

    //        Beacon.API.GetParentFeature(
    //            id,
    //            function (result) {

    //                var features = t.processQueryResult([result]);
    //                var feature = null;
    //                if (features.length > 0) {
    //                    feature = features[0];
    //                }
    //                //splice inserts features as first arg
    //                var args = [feature];
    //                if (scopeArgs) {
    //                    args = args.concat(scopeArgs);
    //                }
    //                success.apply(scope, args);

    //            },
    //            function (e) {
    //                var args = [e];
    //                if (scopeArgs) {
    //                    args = args.concat(scopeArgs);
    //                }
    //                failure.apply(scope, args);
    //            },
    //            scope,
    //            scopeArgs
    //            );
    //    },

    CLASS_NAME: "Beacon.Protocol.BeaconFeature"
});


