Beacon.Control.GoogleMaps = {

    // official Google Maps URL documentation: https://developers.google.com/maps/documentation/urls/guide
    gMapsDisplayUrl: "https://www.google.com/maps/@?api=1&map_action=map",
    gMapsSearchUrl: "https://www.google.com/maps/search/?api=1",

    convertScaleToZoomLevel: function (scale) {

        // Note: 591657550.5 is the scale at level 1 (According to the Google tiling scheme)
        // var level = Math.floor(Math.log2(591657550.5 / scale) + 1);
        var level = Math.floor((Math.log(591657550.5 / scale) / Math.log(2)) + 1);

        // cap level at 21
        if (level > 21) level = 21;

        return level;

    },

    showFeature: function (fid) {
        var feature = Beacon.MapJS.selectionLayer.findFeatureById(fid);

        var prjLL = new OpenLayers.Projection("EPSG:4326"); //WGS84

        var cp = feature.geometry.getCentroid();
        var tcp = cp.transform(Beacon.MapJS.map.projection, prjLL);

        var level = this.convertScaleToZoomLevel(Beacon.MapJS.map.getScale());

        // to just display the map:
        //var url = this.gMapsDisplayUrl + "&zoom=" + level + "&center=" + tcp.y + "," + tcp.x;

        // to do a search for the point - provides a richer user experience
        var url = this.gMapsSearchUrl + "&zoom=" + level + "&query=" + tcp.y + "," + tcp.x;

        window.open(url, '_blank');

        return false;
    },

    CLASS_NAME: "Beacon.Control.GoogleMaps"
};