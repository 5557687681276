// this can be used to protect a DIV from leaking events onto the map

Beacon.Control.EventSink = OpenLayers.Class({

    initialize: function (options) {

    },

    register: function (div) {
        this.events = new OpenLayers.Events(this, div, null, true);

        this.events.on({
            "mousedown": this.onmousedown,
            "mousemove": this.onmousemove,
            "mouseup": this.onmouseup,
            "mouseout": this.onmouseout,
            "click": this.ignore,
            "dblclick": this.ignore,
            scope: this
        });

        //tried adding keydown/press/up to events, but they don't seem to be seen.

    },



    /** 
    * Method: onmousedown 
    * When mouse goes down within the popup, make a note of
    *   it locally, and then do not propagate the mousedown 
    *   (but do so safely so that user can select text inside)
    * 
    * Parameters:
    * evt - {Event} 
    */
    onmousedown: function (evt) {
        this.mousedown = true;
        OpenLayers.Event.stop(evt, true);
    },

    /** 
    * Method: onmousemove
    * If the drag was started within the popup, then 
    *   do not propagate the mousemove (but do so safely
    *   so that user can select text inside)
    * 
    * Parameters:
    * evt - {Event} 
    */
    onmousemove: function (evt) {
        if (this.mousedown) {
            OpenLayers.Event.stop(evt, true);
        }
    },

    /** 
    * Method: onmouseup
    * When mouse comes up within the popup, after going down 
    *   in it, reset the flag, and then (once again) do not 
    *   propagate the event, but do so safely so that user can 
    *   select text inside
    * 
    * Parameters:
    * evt - {Event} 
    */
    onmouseup: function (evt) {
        if (this.mousedown) {
            this.mousedown = false;
            OpenLayers.Event.stop(evt, true);
        }
    },

    /**
    * Method: ignore
    * Ignore event, but allowing default browser handling
    * 
    * Parameters:
    * evt - {Event} 
    */
    ignore: function (evt) {
        OpenLayers.Event.stop(evt, true);
    },

    /** 
    * Method: onmouseout
    * When mouse goes out of the popup set the flag to false so that
    *   if they let go and then drag back in, we won't be confused.
    * 
    * Parameters:
    * evt - {Event} 
    */
    onmouseout: function (evt) {
        this.mousedown = false;
    },





    CLASS_NAME: "Beacon.Control.EventSink"

});