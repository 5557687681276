
Beacon.Control.SpatialSelection = OpenLayers.Class(OpenLayers.Control, {

    //type: OpenLayers.Control.TYPE_BUTTON,
    sink: null,
    html: null,
    element: null,
    executeCompleted: null,
    executeCompletedScope: null,

    SELECTION_MODES: {
        Buffer: "buffer",
        Select: "select",
        Export: "export"
    },

    initialize: function (selectionLayer, options) {
        OpenLayers.Control.prototype.initialize.apply(this, arguments);

        this.html = $('#SpatialSelectPanel').detach();

        this.selectionLayer = selectionLayer;
        this.deactivate();

    },

    draw: function () {
        OpenLayers.Control.prototype.draw.apply(this, arguments);


        if (!this.element) {
            this.element = document.createElement('div');
            this.element.id = this.id + '_frame';
            OpenLayers.Element.addClass(this.element, this.displayClass + 'Frame');

            //re-attach the div we detached in init
            $(this.element).append(this.html);
            this.html.show();

        }


        this.div.appendChild(this.element);


        //prevent clicks from hitting the map 
        this.sink = new Beacon.Control.EventSink();
        this.sink.register(this.div);


        return this.div;

    },

    activate: function () {

        var r = OpenLayers.Control.prototype.activate.apply(this);
        if (r) {

            //hack: disable the keyboard controls
            //var kbdCtrl = this.map.getControlsByClass("OpenLayers.Control.KeyboardDefaults");
            //if (kbdCtrl.length) kbdCtrl[0].deactivate();
            Beacon.MapJS.controls.kbdNavigation.deactivate();

            Beacon.MapJS.bufferLayer.removeAllFeatures();
            Beacon.MapJS.bufferLayer.setVisibility(true);
            Beacon.API.ClearBufferGeometry();


            this.buildLayerDropdown($("#SpatialSelection_layers"), mapConfig.LayerId);
            $("#SpatialSelection_layerName").text(this.getNameForLayer(mapConfig.LayerId));

            //bind button
            OpenLayers.Event.observe($("#SpatialSelection_button")[0], "click",
                OpenLayers.Function.bindAsEventListener(this.executeOperation, this));

            //bind radio btns
            OpenLayers.Event.observe($("#SpatialSelection_buff")[0], "click",
                OpenLayers.Function.bindAsEventListener(this.changeSelectionMode, this));

            OpenLayers.Event.observe($("#SpatialSelection_sel")[0], "click",
                OpenLayers.Function.bindAsEventListener(this.changeSelectionMode, this));

            OpenLayers.Event.observe($("#SpatialSelection_export")[0], "click",
                OpenLayers.Function.bindAsEventListener(this.changeSelectionMode, this));


            OpenLayers.Event.observe($("#SpatialSelection_exportType")[0], "change",
                OpenLayers.Function.bindAsEventListener(this.onExportTypeChange, this));



            //bind text box
            OpenLayers.Event.observe($("#SpatialSelection_dist")[0], "change",
                OpenLayers.Function.bindAsEventListener(this.updateButtonVis, this));
            // propertychange is for IE, input for the others?
            OpenLayers.Event.observe($("#SpatialSelection_dist")[0], "propertychange",
                OpenLayers.Function.bindAsEventListener(this.updateButtonVis, this));
            OpenLayers.Event.observe($("#SpatialSelection_dist")[0], "input",
                OpenLayers.Function.bindAsEventListener(this.updateButtonVis, this));
            OpenLayers.Event.observe($("#SpatialSelection_dist")[0], "keydown",
                OpenLayers.Function.bindAsEventListener(this.onKeyDown, this));

            Beacon.MapJS.events.on({
                "changelayer": this.changeActiveLayer,
                scope: this
            });

            this.changeSelectionMode(); //updates panel visibilities
            this.onExportTypeChange();
            //this.hideSpinnerAndDownloadLink();

            // remove tool if no permissions:
            if (!mapConfig.Permissions.BufferToolExport) {
                $("#SpatialSelection_extractToolSection").hide();
            }

            Beacon.MapJS.selectionLayer.events.register("featuresremoved", this, this.updateButtonVis);
            Beacon.MapJS.selectionLayer.events.register("featuresadded", this, this.updateButtonVis);

            this.updateButtonVis();
        }


        return r;
    },

    deactivate: function () {

        var r = OpenLayers.Control.prototype.deactivate.apply(this);
        //console.info("deactivate");

        if (r) {

            Beacon.MapJS.bufferLayer.setVisibility(true);
            //Beacon.MapJS.bufferLayer.removeAllFeatures();
            //if(this.toolHasBeenUsed) Beacon.API.ClearBufferGeometry();


            //unbind
            OpenLayers.Event.stopObservingElement($("#SpatialSelection_button")[0]);
            OpenLayers.Event.stopObservingElement($("#SpatialSelection_buff")[0]);
            OpenLayers.Event.stopObservingElement($("#SpatialSelection_sel")[0]);
            OpenLayers.Event.stopObservingElement($("#SpatialSelection_export")[0]);
            OpenLayers.Event.stopObservingElement($("#SpatialSelection_dist")[0]);


            //hack: re-enable the keyboard controls
            //var kbdCtrl = this.map.getControlsByClass("OpenLayers.Control.KeyboardDefaults");
            //if (kbdCtrl.length) kbdCtrl[0].activate();
            Beacon.MapJS.controls.kbdNavigation.activate();
        }

        return r;

    },

    setMap: function (map) {
        OpenLayers.Control.prototype.setMap.apply(this, arguments);
    },


    updateButtonVis: function (e) {
        var s = $("#SpatialSelection_dist").val();
        var d = parseFloat(s);

        var ct = 0;
        if (Beacon.MapJS.selectionLayer) ct = Beacon.MapJS.selectionLayer.features.length;

        $("#SpatialSelection_featureCount").text(ct.format("0,000"));

        switch (this.currentMode()) {
            case this.SELECTION_MODES.Buffer:
            case this.SELECTION_MODES.Export:
                $("#SpatialSelection_button")[0].disabled = (isNaN(d) || d <= 0 || ct == 0 || ct > 100);
                break;

            case this.SELECTION_MODES.Select:
                $("#SpatialSelection_button")[0].disabled = (ct == 0 || ct > 100);
                break;
        }

        if (ct == 0) {
            this.setMessage("Please select at least one feature");

        } else if (ct > 100) {
            this.setMessage("Please select fewer features");

        } else {
            this.setMessage(null);
        }

    },

    buildLayerDropdown: function (sel, defaultLayerId) {

        var lyrs = Beacon.MapJS.getLayersGroupedAndFiltered(function (o) { return o.Query && !o.Locked; });

        sel.append(Beacon.Templates.spatialSelectLayerDropdown({ Layers: lyrs }));
        $("option[value=" + defaultLayerId + "]", sel).prop('selected', true);

    },

    changeActiveLayer: function () {
        //console.info('layer changing');
        //this.layerNameElement.innerText = this.getNameForLayer(mapConfig.LayerId);
        $("#SpatialSelection_layerName").text(this.getNameForLayer(mapConfig.LayerId));
    },

    getNameForLayer: function (layerId) {

        var lyr = Beacon.MapJS.getBeaconLayer(layerId);
        if (lyr) {
            return lyr.LayerName;
        }

        return 'Unknown';

    },



    currentMode: function () {
        if ($("#SpatialSelection_buff")[0].checked) return this.SELECTION_MODES.Buffer;
        if ($("#SpatialSelection_sel")[0].checked) return this.SELECTION_MODES.Select;
        if ($("#SpatialSelection_export")[0].checked) return this.SELECTION_MODES.Export;
    },



    changeSelectionMode: function () {

        switch (this.currentMode()) {
            case this.SELECTION_MODES.Buffer:
                $("#SpatialSelection_spanBuffer").show();
                $("#SpatialSelection_targetLayer").show();
                $("#SpatialSelection_exportLayer").hide();
                $("#SpatialSelection_spanSelect").hide();
                $("#SpatialSelection_ExportOptions").hide();
                $("#SpatialSelection_button").attr('value', 'Buffer');
                break;

            case this.SELECTION_MODES.Select:
                $("#SpatialSelection_spanBuffer").hide();
                $("#SpatialSelection_targetLayer").show();
                $("#SpatialSelection_exportLayer").hide();
                $("#SpatialSelection_spanSelect").show();
                $("#SpatialSelection_ExportOptions").hide();
                $("#SpatialSelection_button").attr('value', 'Select');
                break;

            case this.SELECTION_MODES.Export:
                $("#SpatialSelection_spanBuffer").show();
                $("#SpatialSelection_targetLayer").hide();
                $("#SpatialSelection_exportLayer").show();
                $("#SpatialSelection_spanSelect").hide();
                $("#SpatialSelection_ExportOptions").show();
                $("#SpatialSelection_button").attr('value', 'Export');
                break;
        }
        //$("#SpatialSelection_downloadLink").hide();

        //this.hideSpinnerAndDownloadLink();
        this.updateButtonVis();

    },

    executeOperation: function () {


        try {
            if (Beacon.MapJS.selectionLayer.features.length == 0) {
                alert("You have no features selected");
                return;
            }

            var targetLayerId = $("#SpatialSelection_layers").val();

            this.disableUi();

            switch (this.currentMode()) {
                case this.SELECTION_MODES.Buffer:
                    var bufferDistance = parseFloat($("#SpatialSelection_dist").val()) * parseFloat($("#SpatialSelection_units").val());
                    this.buffer(bufferDistance, targetLayerId, Beacon.API.SpatialRelation.Intersects);
                    Beacon.GA.TrackEvent('SpatialSelection', 'Buffer');
                    break;

                case this.SELECTION_MODES.Select:
                    this.buffer(0, targetLayerId, $("#SpatialSelection_relation").val());
                    Beacon.GA.TrackEvent('SpatialSelection', 'Select');
                    break;

                case this.SELECTION_MODES.Export:
                    var bufferDistance = parseFloat($("#SpatialSelection_dist").val()) * parseFloat($("#SpatialSelection_units").val());
                    this.exportData(bufferDistance, targetLayerId);
                    Beacon.GA.TrackEvent('SpatialSelection', 'Export');
                    break;
            }


        } catch (e) {
            Beacon.MapJS.showRetryActivity(e, "SpatialSelection.execute");

        }


    },

    buffer: function (distance, layerid, spatialRelation) {

        Beacon.MapJS.bufferLayer.removeAllFeatures();

        var f = Beacon.MapJS.selectionLayer.features;



        var wktParser = new OpenLayers.Format.WKT();

        var wkts = [];

        for (var i = 0; i < f.length; i++) {
            var feature = f[i];
            if (feature.geometry) {
                wkts.push(wktParser.write(feature));
            }
        }

        Beacon.API.BufferGeometry(
            wkts,
            distance,
            this.bufferComplete,
            this.bufferFailure,
            this,
            {
                layerid: layerid,
                spatialRelation: spatialRelation
            });

        if (distance == 0) {
            Beacon.MapJS.showActivity("Selecting feature(s)");
        } else {
            Beacon.MapJS.showActivity("Buffering feature(s)");
        }
    },

    bufferComplete: function (polyWkt, params) {

        try {
            var self = this;
            var wktParser = new OpenLayers.Format.WKT();
            var polyFeature = wktParser.read(polyWkt);
            polyFeature.fid = 'buffertest';

            Beacon.MapJS.bufferLayer.addFeatures([polyFeature]);

            if (mapConfig.LayerId != params.layerid) {
                // There appears to be a race condition here if we do not chain the changeActiveLayer and wait for a callback before requesting features.
                // The changeActiveLayer has an async call within that function to get the tabs, for the changed layer. In some instances, the requestFeatures
                // call would come back quicker, and hydrate the results pane. If the tabs are not created yet, then the reports links will not be created.
                // I'm not 100% that this is the issue - but it appears that passing in a callback here was the fix. (Just commenting this for now to hopefully
                // address any confusion. If this comment has remained for a long time, feel free to remove)...AL
                Beacon.MapJS.changeActiveLayer(params.layerid, function () {
                    self.requestFeatures(polyFeature.geometry, params.spatialRelation);
                });

            }
            else {
                self.requestFeatures(polyFeature.geometry, params.spatialRelation);
            }

        } catch (e) {
            Beacon.MapJS.showRetryActivity(e, "SpatialSelection.bufferComplete");
        }

    },



    bufferFailure: function (msg) {

        Beacon.MapJS.showRetryActivity();
        this.enableUi();

    },

    requestFeatures: function (geom, spatialRelation) {

        Beacon.MapJS.showActivity("Fetching data");

        var reader = new Beacon.Protocol.BeaconFeature({});

        reader.readFeatures(
            false,
            geom,
            spatialRelation,
            null,
            this.gotFeatures,
            function () {
                Beacon.MapJS.showRetryActivity();
                this.enableUi();
            },
            this);
    },

    exportData: function (distance, layerId) {

        Beacon.MapJS.bufferLayer.removeAllFeatures();
        Beacon.MapJS.showActivity("Exporting data");

        var keys = Beacon.MapJS.selectionLayer.getSelectedKeyValues();

        var exportType = $("#SpatialSelection_exportType").val();
        var usePropertyAddress = $("#SpatialSelection_nameType").val() == 'property';
        var showParcelId = $("#SpatialSelection_showParcelId")[0].checked;
        var skipLabelCount = $("#SpatialSelection_skipLabels").val();
        if (isNaN(skipLabelCount)) skipLabelCount = 0;

        //this.showSpinner();

        Beacon.API.BufferAndExport(layerId, keys, distance, exportType, showParcelId, usePropertyAddress, skipLabelCount, this.exportDataSuccess, this.exportDataFail, this);
    },

    exportDataSuccess: function (d) {
        // show download link
        //console.info(d);
        //this.showDownloadLink(d.DownloadUrl, d.OutputFeatureCount);
        this.enableUi();
        Beacon.MapJS.hideActivity();
        //window.open(d.DownloadUrl, "_blank");
        //$("#SpatialSelection_downloadLink").attr("href", d.DownloadUrl).show();

        Beacon.Dialogs.showWithHtml(
            $("#SpatialSelection_export")[0],
            null,
            //"<a href='" + d.DownloadUrl + "' target='_blank'>Download export file</a>",
            "<h3 style='text-align:center'>Your data export is ready to download.</h3>"
               + "<div style='text-align:center'>" + d.OutputFeatureCount.format(",") + " parcels were exported</div>",
            false,
            "Download", function () { window.open(d.DownloadUrl, "_blank"); },
            "Cancel", null
            );


        // show buffer geometry
        var wktParser = new OpenLayers.Format.WKT();
        var polyFeature = wktParser.read(d.BufferGeometry);
        polyFeature.fid = 'buffertest';
        Beacon.MapJS.bufferLayer.addFeatures([polyFeature]);

        Beacon.MapJS.map.zoomToExtent(Beacon.MapJS.bufferLayer.getDataExtent());

        if (this.executeCompleted) {
            this.executeCompleted.apply(this.executeCompletedScope);
        }
    },

    exportDataFail: function (e) {

        Beacon.MapJS.showRetryActivity(e, "SpatialSelection.exportData");
        this.enableUi();

    },

    gotFeatures: function (features) {

        try {

            Beacon.MapJS.selectionLayer.selectFeatures(features, false, false);

            //Zoom to selection

            var ext = Beacon.MapJS.selectionLayer.getDataExtent();
            Beacon.MapJS.zoomToFeatureExtent(ext);

            //OpenLayers.Element.removeClass(this.map.viewPortDiv, "olCursorWait");
            //Beacon.MapJS.hideActivity();

            Beacon.MapJS.hideActivity("");
            this.enableUi();

            if (this.executeCompleted) {
                this.executeCompleted.apply(this.executeCompletedScope);
            }

        } catch (e) {
            Beacon.MapJS.showRetryActivity(e, "SpatialSelection.gotFeatures");

        }

    },


   

    disableUi: function () {
        //$(this.element).fadeTo(200, 0.25);
        $("#SpatialSelection_button")[0].disabled = true;
        $("#SpatialSelection_spinner").show();
        //$("#SpatialSelection_downloadLink").hide();
    },

    enableUi: function () {
        //$(this.element).fadeTo(200, 1.0);
        $("#SpatialSelection_button")[0].disabled = false;
        $("#SpatialSelection_spinner").hide();
    },

    onExportTypeChange: function () {
        if ($("#SpatialSelection_exportType").val() == "5160") {
            $("#SpatialSelection_labelExportOptions").show();
        } else {
            $("#SpatialSelection_labelExportOptions").hide();
        }
       // $("#SpatialSelection_downloadLink").hide();

    },

    setMessage: function (msg) {
        var m = $("#SpatialSelection_messages");
        if (msg) {
            m.text(msg);
            m.show();
        } else {
            m.hide();
        }
    },

    //showSpinner: function () {
    //    $("#SpatialSelection_spinner").show();
    //    $("#SpatialSelection_downloadlink").hide();
    //},

    //showDownloadLink: function (url, count) {
    //    $("#SpatialSelection_spinner").hide();
    //    $("#SpatialSelection_downloadlinkurl").attr("href", url);
    //    $("#SpatialSelection_downloadlink").show();
    //    $("#SpatialSelection_recordCount").text(count.format("0,000"));
    //},

    //hideSpinnerAndDownloadLink: function () {
    //    $("#SpatialSelection_spinner").hide();
    //    $("#SpatialSelection_downloadlink").hide();
    //},


    onKeyDown: function (e) {
        if (e.keyCode == 13) {
            OpenLayers.Event.stop(e);
        }
    },

    CLASS_NAME: 'Beacon.Control.SpatialSelection'

});