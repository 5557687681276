Beacon.Control.Measure = OpenLayers.Class(OpenLayers.Control, {

    /**
    * Property: delay
    * {Number} Number of milliseconds between clicks before the event is
    *     considered a double-click.  The "measurepartial" event will not
    *     be triggered if the sketch is completed within this time.  This
    *     is required for IE where creating a browser reflow (if a listener
    *     is modifying the DOM by displaying the measurement values) messes
    *     with the dblclick listener in the sketch handler.
    */
    partialDelay: 10,

    /**
    * Property: delayedTrigger
    * {Number} Timeout id of trigger for measurepartial.
    */
    delayedTrigger: null,

    /**
    * APIProperty: persist
    * {Boolean} Keep the temporary measurement sketch drawn after the
    *     measurement is complete.  The geometry will persist until a new
    *     measurement is started, the control is deactivated, or <cancel> is
    *     called.
    */
    persist: true,

    html: null,
    sink: null,
    ddlLinearUnit: null,
    ddlAreaUnit: null,
    btnMeasureDone: null,
    btnMeasureClear: null,

    /**
    * Constructor: Beacon.Control.Measure
    * 
    * Parameters:
    * handler - {<OpenLayers.Handler>} 
    * options - {Object} 
    */
    initialize: function (options) {
        OpenLayers.Control.prototype.initialize.apply(this, [options]);

        this.handler = new Beacon.Handler.MeasuredPath2(this,
            {   //callbacks
                done: this.measureComplete,
                point: this.measureAddPoint,
                modify: this.measureMove
            },
            {   // handler options
                persist: this.persist,
                layerOptions: {
                    styleMap: this.setupDrawingStyle()
                }
            }
        );

        this.ddlLinearUnit = document.getElementById("linearUnit");
        this.ddlAreaUnit = document.getElementById("areaUnit");
        this.btnMeasureDone = document.getElementById("btnMeasureDone");
        this.btnMeasureClear = document.getElementById("btnMeasureClear");

        this.loadDefaultSettings();

        this.html = $('#measureOverlay').detach();

    },


    /**
    * APIMethod: cancel
    * Stop the control from measuring.  If <persist> is true, the temporary
    *     sketch will be erased.
    */
    cancel: function () {
        this.handler.cancel();
    },


    /**
    * Method: measureComplete
    * Called when the measurement sketch is done.
    *
    * Parameters:
    * geometry - {<OpenLayers.Geometry>}
    */
    measureComplete: function (geometry) {
        if (this.delayedTrigger) {
            window.clearTimeout(this.delayedTrigger);
            this.delayedTrigger = null;
        }
        this.measure(geometry, "measure");
        Beacon.GA.TrackEvent('Measure', 'Used');
        this.post_measurement(geometry, this.handler.areaLabel);
    },

    /**
    * Method: measureAddPoint
    * Called each time a new point is added to the measurement sketch.
    *
    * Parameters:
    * point - {<OpenLayers.Geometry.Point>} The last point added.
    * geometry - {<OpenLayers.Geometry>} The sketch geometry.
    */
    measureAddPoint: function (point, geometry) {

        if (this.delayedTrigger) {
            window.clearTimeout(this.delayedTrigger);
            this.delayedTrigger = null;
        }

        this.measure(geometry, true);

    },

    //This listens for the modify event - where input is still in motion
    measureMove: function (point, sketch) {

        if (this.delayedTrigger) {
            return;
        }
        this.delayedTrigger = window.setTimeout(
            OpenLayers.Function.bind(function () {
                this.measure(sketch.geometry, false);
                this.delayedTrigger = null;
            }, this),
            this.partialDelay
        );

    },

    /**
    * Method: measure
    *
    * Parameters:
    * geometry - {<OpenLayers.Geometry>}
    * pointAdded - {boolean} - does this call represent a new vertex, unused
    */
    measure: function (geometry, pointAdded) {
        if (!geometry) return;

        var length = this.handler.getLength(geometry);
        if (length == 0) {
            this.hideFinishBtn();
            return;
        }

        this.showFinishBtn();


        //close line for area
        var ls = new OpenLayers.Geometry.LinearRing(geometry.components);
        ls.addPoint(ls.components[0]);

        var area = Math.abs(this.handler.getArea(ls));

        this.updateMeasurement(length.displayRounding().addCommas(), area.displayRounding().addCommas());
    },

    removeAllMeasurements: function () {
        this.handler.removeAllMeasurements();
        try {
            var markupData = {Features:[]};
            // send
            Beacon.API.SaveMapMeasure(markupData,
                function () { }, //success
                function () { //fail
                    Beacon.MapJS.showRetryActivity();
                },
                this);

        } catch (e) {
            Beacon.MapJS.logException(e, "MeasureMarkup.save");

        }
    },


    activate: function () {
        var r = OpenLayers.Control.prototype.activate.apply(this);
        if (r) {

            this.show();
            this.updateMeasurement(0, 0);


            OpenLayers.Event.observe(this.ddlLinearUnit, "change",
                OpenLayers.Function.bindAsEventListener(function () {
                    var o = this.ddlLinearUnit.options[this.ddlLinearUnit.selectedIndex];
                    this.changeLinearUnits(o.value, o.text);
                    this.saveDefaultSettings();
                }, this));

            OpenLayers.Event.observe(this.ddlAreaUnit, "change",
                OpenLayers.Function.bindAsEventListener(function () {
                    var o = this.ddlAreaUnit.options[this.ddlAreaUnit.selectedIndex];
                    this.changeAreaUnits(o.value, o.text);
                    this.saveDefaultSettings();
                }, this));

            OpenLayers.Event.observe(this.btnMeasureDone, "click",
                OpenLayers.Function.bindAsEventListener(function () {
                    this.onFinishPushed();
                }, this));

            OpenLayers.Event.observe(this.btnMeasureClear, "click",
                OpenLayers.Function.bindAsEventListener(function () {
                    this.removeAllMeasurements();
                }, this));

            $("BODY").addClass('disableTextSelection');

        }
        return r;
    },

    deactivate: function () {

        //this.html = $("#measureOverlay").detach();

        var r = OpenLayers.Control.prototype.deactivate.apply(this);

        if (r) {
            this.hide();

            OpenLayers.Event.stopObservingElement(this.ddlLinearUnit);
            OpenLayers.Event.stopObservingElement(this.ddlAreaUnit);

            $("BODY").removeClass('disableTextSelection');

        }
        return r;

    },

    show: function () {
        //$('#measureOverlay').fadeIn();
    },

    hide: function () {
        //$('#measureOverlay').fadeOut();
    },

    draw: function () {
        OpenLayers.Control.prototype.draw.apply(this, arguments);

        if (!this.element) {
            this.element = document.createElement('div');
            this.element.id = this.id + '_frame';
            OpenLayers.Element.addClass(this.element, this.displayClass + 'Frame');

            //re-attach the div we detached in init
            $(this.element).append(this.html);
            this.html.show();
        }

        this.div.appendChild(this.element);


        //prevent clicks from hitting the map 
        this.sink = new Beacon.Control.EventSink();
        this.sink.register(this.div);


        return this.div;

    },


    detachEvents: function () {
        //        OpenLayers.Event.stopObservingElement(this.inputBuffer);
        //        OpenLayers.Event.stopObservingElement(this.inputSelect);
        //        OpenLayers.Event.stopObservingElement(this.button);
    },

    updateMeasurement: function (length, area) {
        $('#lengthDisplay').val(length);
        $('#areaDisplay').val(area);
    },

    setupDrawingStyle: function () {

        var sketchSymbolizers = {
            "Point": {
                pointRadius: 4,
                graphicName: "square",
                fillColor: "white",
                fillOpacity: 0,
                strokeWidth: 1,
                strokeOpacity: 1,
                strokeColor: "#d00"
            },
            "Line": {
                strokeWidth: 2,
                strokeOpacity: 1,
                strokeColor: "#d00" //,
                //strokeDashstyle: "dash"
            },
            "Polygon": {
                strokeWidth: 2,
                strokeOpacity: 1,
                strokeColor: "#666666",
                fillColor: "white",
                fillOpacity: 0.3
            }
        };
        var style = new OpenLayers.Style();
        style.addRules([
            new OpenLayers.Rule({ symbolizer: sketchSymbolizers })
        ]);
        var styleMap = new OpenLayers.StyleMap({ "default": style });

        return styleMap;

    },

    changeLinearUnits: function (factor, unitname) {

        this.handler.setLinearUnits(factor, unitname);
        this.measure(this.handler.getGeometry(), false);

    },

    changeAreaUnits: function (factor, unitname) {

        this.handler.setAreaUnits(factor, unitname);
        this.measure(this.handler.getGeometry(), false);

    },

    //set measurement geom/area to server for print overlay - legacy?
    post_measurement: function (geometry, areaLabel) {
        try {
            var markupData = this.handler.getExportMarkup();
            // send
            Beacon.API.SaveMapMeasure(markupData,
                function () { }, //success
                function () { //fail
                    Beacon.MapJS.showRetryActivity();
                },
                this);

        } catch (e) {
            Beacon.MapJS.logException(e, "MeasureMarkup.save");

        }

    },

    showFinishBtn: function () {
        $(this.btnMeasureDone).show();
    },

    hideFinishBtn: function () {
        $(this.btnMeasureDone).hide();
    },

    onFinishPushed: function () {
        this.hideFinishBtn();
        this.handler.forceComplete();
        this.handler.dblclick();
        this.hideFinishBtn();
    },

    loadDefaultSettings: function () {
        try {
            if (Beacon.localStorage) {
                var lu = Beacon.localStorage.getItem("MeasureLinearUnit");
                if (lu) {
                    this.ddlLinearUnit.selectedIndex = lu;
                    var o = this.ddlLinearUnit.options[this.ddlLinearUnit.selectedIndex];
                    this.changeLinearUnits(o.value, o.text);
                }
                var au = Beacon.localStorage.getItem("MeasureAreaUnit");
                if (au) {
                    this.ddlAreaUnit.selectedIndex = au;
                    var o = this.ddlAreaUnit.options[this.ddlAreaUnit.selectedIndex];
                    this.changeAreaUnits(o.value, o.text);
                }
            }
        } catch (ex) { }
    },

    saveDefaultSettings: function () {
        try {
            if (Beacon.localStorage) {

                var ol = this.ddlLinearUnit.options[this.ddlLinearUnit.selectedIndex];
                var oa = this.ddlAreaUnit.options[this.ddlAreaUnit.selectedIndex];

                Beacon.localStorage.setItem("MeasureLinearUnit", this.ddlLinearUnit.selectedIndex);
                Beacon.localStorage.setItem("MeasureAreaUnit", this.ddlAreaUnit.selectedIndex);
            }
        } catch (ex) { }
    },

    CLASS_NAME: "Beacon.Control.Measure"
});


// This was build from OL 2.11 rc1
Beacon.Handler.MeasuredPath2 = OpenLayers.Class(OpenLayers.Handler.Path, {

    tempLabel: null,

    linearUnits: 'ft',
    linearFactor: 1.0,
    areaUnits: 'acres',
    areaFactor: 43560.0,

    areaLabel: null,

    freehand: false,
    freehandToggle: null,
    doubleTouchTolerance: 50,
    persist: true,



    initialize: function () {
        OpenLayers.Handler.Path.prototype.initialize.apply(this, arguments);
    },

    activate: function () {
        // from OL.Handler.Point.js
        if (!OpenLayers.Handler.prototype.activate.apply(this, arguments)) {
            return false;
        }
        if (!this.layer) {
            // create temporary vector layer for rendering geometry sketch
            // TBD: this could be moved to initialize/destroy - setting visibility here
            var options = OpenLayers.Util.extend({
                displayInLayerSwitcher: false,
                // indicate that the temp vector layer will never be out of range
                // without this, resolution properties must be specified at the
                // map-level for this temporary layer to init its resolutions
                // correctly
                calculateInRange: OpenLayers.Function.True
            }, this.layerOptions);
            this.layer = new OpenLayers.Layer.Vector(this.CLASS_NAME, options);
            this.map.addLayer(this.layer);
        }
        this.createFeature();
        return true;
    },

    deactivate: function () {
        // from OL.Handler.Point.js
        if (!OpenLayers.Handler.prototype.deactivate.apply(this, arguments)) {
            return false;
        }
        this.cancel(true);
        // If a layer's map property is set to null, it means that that layer
        // isn't added to the map. Since we ourself added the layer to the map
        // in activate(), we can assume that if this.layer.map is null it means
        // that the layer has been destroyed (as a result of map.destroy() for
        // example.
        //if (this.layer.map != null) {
        //    this.destroyFeature();
        //    this.layer.destroy(false);
        //}
        //this.layer = null;
        this.touch = false;
        return true;
    },

    createFeature: function (pixel) {
        OpenLayers.Handler.Path.prototype.createFeature.apply(this, arguments);
        this.areaLabel = null;
    },

    addPoint: function (pixel) {
        OpenLayers.Handler.Path.prototype.addPoint.apply(this, arguments);

        if (this.tempLabel) {
            this.layer.removeFeatures([this.tempLabel]);
            this.tempLabel = null;
        }

        var pts = this.line.geometry.getVertices();
        if (pts.length > 2) {
            var lastPoint = pts[pts.length - 3];
            this.labelLineSegment(this.point.geometry, lastPoint);
        }
    },

    modifyFeature: function (pixel, drawing) {
        //OpenLayers.Handler.Path.prototype.modifyFeature.apply(this, arguments);

        // taken from the path.modifyFeature code (2.11)
        var lonlat = this.control.map.getLonLatFromPixel(pixel);

        var pt;
        if (this.line.geometry.components.length > 2) {
            pt = this.snapToSelf(lonlat); // ADDED
        } else {
            pt = lonlat;
        }

        this.point.geometry.x = pt.lon;
        this.point.geometry.y = pt.lat;
        this.callback("modify", [this.point.geometry, this.getSketch()]);
        this.point.geometry.clearBounds();
        this.line.geometry.clearBounds();

        // New code:

        if (this.tempLabel) {
            this.layer.destroyFeatures([this.tempLabel]);
            this.tempLabel = null;
        }

        var pts = this.line.geometry.getVertices();
        if (pts.length > 1) {
            var lastPoint = pts[pts.length - 2];
            this.tempLabel = this.labelLineSegment(this.point.geometry, lastPoint);
        }

        this.drawFeature();
    },

    destroyPersistedFeature: function () {
        // dont delete here, overriding base function behavior
    },

    destroyFeature: function () {
        // dont delete here, overriding base function behavior
    },

    removeAllMeasurements: function () {
        if (this.layer && this.layer.features) {
            var features = this.layer.features;
            for (var i = features.length - 1; i >= 0; i--) {
                var f = features[i];
                if (!(f == this.line || f == this.point)) {
                    this.layer.removeFeatures([f]);
                }
            }
        }
    },

    finishGeometry: function () {
        // OpenLayers.Handler.Path.prototype.finishGeometry.apply(this, arguments);

        var index = this.line.geometry.components.length - 1;
        this.line.geometry.removeComponent(this.line.geometry.components[index]);
        this.removePoint();

        this.layer.drawFeature(this.line, this.style); //redraws after we've removed the extra leg
        this.labelArea(this.line.geometry);

        this.finalize();

    },

    dblclick: function (evt) {
        //this.labelArea(this.line.geometry);

        OpenLayers.Handler.Path.prototype.dblclick.apply(this, arguments);

        return false;
    },

    forceComplete: function () {

        //this.labelArea(this.line.geometry);

        if (this.tempLabel) {
            this.layer.removeFeatures([this.tempLabel]);
            this.tempLabel = null;
        }

    },

    labelLineSegment: function (pointA, pointB) {


        var labelFeature = new OpenLayers.Feature.Vector(
            new OpenLayers.Geometry.Point((pointA.x + pointB.x) / 2,
                (pointA.y + pointB.y) / 2)
        );

        var length = this.getLength(new OpenLayers.Geometry.LineString([pointA, pointB]));

        if (length == 0) return null;

        labelFeature.style = {
            label: length.displayRounding().addCommas(),
            fontColor: "#fff",
            fontWeight: "bold",
            fontSize: "12px",
            glow: true
        };

        this.layer.addFeatures([labelFeature], { silent: true });

        return labelFeature;
    },


    labelArea: function (geometry) {

        //close line for area
        var ls = new OpenLayers.Geometry.LinearRing(geometry.components);
        ls.addPoint(ls.components[0]);

        // check closure, see if we should label area

        var pt1 = geometry.components[0];
        var ptN = geometry.components[geometry.components.length - 1];

        var dClosure = pt1.distanceTo(ptN); // this will be zero due to snapping

        // label area when closure is < 10% of the width of the geom

        if (dClosure == 0) {

            var area = Math.abs(this.getArea(ls));
            var center = ls.getCentroid();
            var extents = this.getExtents(ls);

            //50 was just a guess on width and 15 above maxY is just what worked.
            if (extents.width < 50) {
                center.y = extents.maxY + 15;
            }

            var labelFeature = new OpenLayers.Feature.Vector(
                center
            );

            this.areaLabel = area.displayRounding().addCommas() + ' ' + this.areaUnits;

            labelFeature.style = {
                label: this.areaLabel,
                fontColor: "#fff",
                fontWeight: "bold",
                fontSize: "12px",
                glow: true
            };

            this.layer.addFeatures([labelFeature], { silent: true });

        } else {
            this.areaLabel = null;
        }

    },

    getExtents: function (geometry) {
        var vertices = geometry.getVertices();

        var minX = 0, minY = 0, maxX = 0, maxY = 0;

        for (var pN = 0; pN < vertices.length; pN++) {
            var p = vertices[pN];

            if (minX == 0 || p.x < minX) {
                minX = p.x
            }

            if (maxX == 0 || p.x > maxX) {
                maxX = p.x;
            }

            if (minY == 0 || p.y < minY) {
                minY = p.y;
            }

            if (maxY == 0 || p.y > maxY) {
                maxY = p.y;
            }
        }

        return {
            minX: minX,
            minY: minY,
            maxX: maxX,
            maxY: maxY,
            width: maxX - minX,
            height: maxY - minY
        }
    },

    getArea: function (geometry) {
        var area, geomUnits;

        area = geometry.getArea();
        geomUnits = this.map.getUnits();

        var inPerDisplayUnit;
        inPerDisplayUnit = OpenLayers.INCHES_PER_UNIT['ft'];

        if (inPerDisplayUnit) {
            var inPerMapUnit = OpenLayers.INCHES_PER_UNIT[geomUnits];
            //area *= Math.pow((inPerMapUnit / inPerDisplayUnit), 2);
            area *= Math.pow((inPerMapUnit / inPerDisplayUnit), 2) / this.areaFactor;
        }
        return area * Math.pow(Beacon.MapJS.distanceFactor, 2);
    },

    getLength: function (geometry) {
        var length, geomUnits;

        length = geometry.getLength();

        geomUnits = this.map.getUnits();

        var inPerDisplayUnit = OpenLayers.INCHES_PER_UNIT['ft'] * this.linearFactor;
        if (inPerDisplayUnit) {
            var inPerMapUnit = OpenLayers.INCHES_PER_UNIT[geomUnits];
            length *= (inPerMapUnit / inPerDisplayUnit);
        }
        return length * Beacon.MapJS.distanceFactor;
    },
    snapToSelf: function (lonlat) {

        var tolerance = this.map.getResolution() * 20;
        var pt = new OpenLayers.Geometry.Point(lonlat.lon, lonlat.lat);

        if (this.line.geometry.components.length > 0) {

            var pt1 = this.line.geometry.components[0];

            if (pt1.distanceTo(pt) < tolerance) {
                return new OpenLayers.LonLat(pt1.x, pt1.y);
            }
        }

        return lonlat;

    },

    setLinearUnits: function (factor, unit) {
        this.linearUnits = unit;
        this.linearFactor = factor;
    },

    setAreaUnits: function (factor, unit) {
        this.areaUnits = unit;
        this.areaFactor = factor;
    },

    getExportMarkup: function () {

            var markupFeatures = [];
            var markupData = { Features: markupFeatures }; // for the server

            var wktWriter = new OpenLayers.Format.WKT();

            var features = this.layer.features;

            for (var i = 0; i < features.length; i++) {
                var f = features[i];
               
                var s = f.style || {};

                var markupFeature = {
                    WKT: wktWriter.write(f),
                    Label: s.label || null,
                    Color: s.fontColor || '#de0000',
                    Size: s.fontSize ? 9.0 : 2.5,
                    AnnoType: s.label ? 'TEXT' : 'GEOMETRY',
                    PointType: null
                };

                markupFeatures.push(markupFeature);

            }

            return markupData;

    },

    CLASS_NAME: 'Beacon.Handler.MeasuredPath2'

});


