Beacon.TOC = {

    // Manages the TOC / Layer List pane - v2
    // 
    //
    // Public Methods:
    //   onPageLoad - initialize the internal state, wire up to DOM elements
    //   onZoomEnd(resolution) - call after map scale changes to update the checkbox scale dep
    //   onLayerLoadStart/End/Change - call to display loading progress ajax spinners
    //   onLayerVisibilityChanged
    //   changeActiveLayer
    //   updateLegend
    //
    // Events:
    //   refreshService({serverId:int, visibile:bool}) - notification that a user has change the visibility
    //                                     of a layer and that it needs to be redrawn
    //
    // Requires:
    //   mapConfig object
    //   Beacon.API - calls setLayerVisibility to server
    //   jQuery

    activeLayer: null, // Beacon.TOC.Layer
    legendDly: null, // Delayed legend updater

    EVENT_TYPES: ["refreshServer"],

    events: null,

    layerIndex: {},
    layerViewModel: [],

    rootTreeNode: null,


    onPageLoad: function () { // for map pages

        this.events = new OpenLayers.Events(this, null, this.EVENT_TYPES);


        // render the toc
        $("#layersJsMapPane").html(Beacon.Templates.toc({
            Layers: mapConfig.LayerView,
            Links: mapConfig.Links
        }));

        this.rootTreeNode = $("ul:first", $("#layersJsMapPane"));

        $('#lnkRestoreDefaults').click(function (e) {
            e.preventDefault();

            for (var i = 0; i < mapConfig.LayerView.length; i++) {
                mapConfig.LayerView[i].restoreDefaultVisibilty();
            }

            return false;
        });

        var layersFlattened = {};

        _.forIn(mapConfig.LayerView, function (o1) {
            if (o1.GroupName) {
                // wire up all layers in this group
                _.forIn(o1.Layers, function (o2) {
                    layersFlattened[o2.LayerId] = o2;
                    o2.Group = o1; // provide back-ref
                    _.merge(o2, Beacon.TOC.Layer);// inject Layer2 functionality
                    o2.initialize();
                });
                // also wire up group itself

                _.merge(o1, Beacon.TOC.Group);// inject Layer2 functionality
                o1.initialize();

            } else {
                layersFlattened[o1.LayerId] = o1;
                _.merge(o1, Beacon.TOC.Layer); // inject Layer2 functionality
                o1.initialize();
            }
        });

        this.layerIndex = layersFlattened;
        this.layerViewModel = mapConfig.LayerView;





        // set up active layer, make sure its shown:
        this.activeLayer = this.layerIndex[mapConfig.LayerId];
        //this.activeLayer.forceGroupVisible();
        this.activeLayer.setActive();

        // setup delayed event to request legend updates
        this.legendDly = new Beacon.DelayedFunction(function () {
            this.updateLegendInternal();
        }, this, 1000);

        this.updateLegend();

        // set first item for tab focus
        this.layerViewModel[0].div.attr('tabindex', '0');
    },


    // called from a map-extent update event
    // will dim the checkboxes for layers that are out of scale range
    onZoomEnd: function (resolution) {
        _.forIn(this.layerIndex, function (layer) {
            layer.updateScale(resolution);
        });
    },

    // these usually get called from events indicating that layers are starting/ending the load process
    //  This will change the checkboxes to spinners as needed for all the layers for one serverId
    onLayerLoadStart: function (serverId) {
        _.chain(this.layerIndex)
            .where({ ServerId: serverId })
            .forIn(function (layer) {
                layer.onLayerLoadStart();
            });
    },

    onLayerLoadEnd: function (serverId) {
        _.chain(this.layerIndex)
            .where({ ServerId: serverId })
            .forIn(function (layer) {
                layer.onLayerLoadEnd();
            });
    },

    onLayerVisibilityChanged: function (serverId, visibility) {
        //if (!visibility) {
        _.chain(this.layerIndex)
            .where({ ServerId: serverId })
            .forIn(function (layer) {
                layer.onLayerVisibilityChanged(visibility);
            });
        //}
    },

    changeActiveLayer: function (layerId) {
        var layer = this.layerIndex[layerId];

        this.activeLayer.unsetActive();

        this.activeLayer = layer;
        layer.setActive();

        this.updateLegend();
    },

    getVisibleLayerIds: function () {
        var ids = _.chain(this.layerIndex)
            .where(function (o) { return o.willLayerDraw(); })
            .pluck('LayerId')
            .value();
        return ids;
    },

    getVisibleLayerIdsForServerId: function (serverId) {
        var ids = _.chain(this.layerIndex)
            .where(function (o) { return o.ServerId == serverId && o.willLayerDraw(); })
            .pluck('LayerId')
            .value();
        return ids;
    },

    updateDisclaimers: function (layer) {

        var disclaimerLayer = this.activeLayer;
        if (layer) disclaimerLayer = layer;

        var layerId = disclaimerLayer.LayerId;
        if (Beacon.localStorage['disclaimer-' + layerId] !== 'true') {

            Beacon.localStorage['disclaimer-' + layerId] = 'true'; // set flag indicating we've received the disclaimer
            Beacon.API.GetDisclaimers([layerId],
                function (html) {
                    if (html) {
                        Beacon.Dialogs.showWithHtml(null, "Disclaimer for " + disclaimerLayer.LayerName, html, false, "Close");
                    }
                },
                function () {
                    // error?
                },
                this,
                null);
        }

    },

    updateLegendInternal: function () {
        if (this.isLegendVisible()) {

            var map = Beacon.MapJS.map;

            var ext = map.getExtent();
            var layerIds = this.getVisibleLayerIds().join("|");

            var res = map.getResolution();

            var url = "/API/RenderLegend.ashx?"
                + "&AppId=" + mapConfig.AppId
                + "&Layers=" + layerIds
                + "&res=" + res;

            $("#legendJsMapPane").load(url);

        }
    },

    updateLegend: function () {
        this.legendDly.delayedInvoke();
    },

    isLegendVisible: function () {
        return (Beacon.localStorage['ShowMapLegend'] === 'true');
    },

    initUiStateOnPageload: function () {

        if (this.isLegendVisible()) {
            this.showLegendPane();
            Beacon.GA.TrackEvent('TOC', 'Legend');
        } else {
            this.showLayerListPane();
            Beacon.GA.TrackEvent('TOC', 'Layers');
        }

        $('#btnShowLegendPane').click(function (e) {
            Beacon.TOC.showLegendPane();
            //return false; 
            e.preventDefault();
            $(this).tab('show');
        });

        $('#btnShowTocPane').click(function (e) {
            Beacon.TOC.showLayerListPane();
            //return false;
            e.preventDefault();
            $(this).tab('show');
        });

        Beacon.WCAG.addSpacebarSupport($('#btnShowLegendPane'));
        Beacon.WCAG.addSpacebarSupport($('#btnShowTocPane'));

        $('#btnShowLegendPane').on('keydown', function (e) {

            switch (e.keyCode) {
                case Beacon.WCAG.keyCode.LEFT:
                    Beacon.TOC.showLayerListPane();
                    e.preventDefault();
                    e.stopPropagation();
                    break;
                case Beacon.WCAG.keyCode.RIGHT:
                    e.preventDefault();
                    e.stopPropagation();
                    break;
            }
        });

        $('#btnShowTocPane').on('keydown', function (e) {

            switch (e.keyCode) {
                case Beacon.WCAG.keyCode.LEFT:
                    e.preventDefault();
                    e.stopPropagation();
                    break;
                case Beacon.WCAG.keyCode.RIGHT:
                    Beacon.TOC.showLegendPane();
                    e.preventDefault();
                    e.stopPropagation();
                    break;
            }
        });
    },

    showLegendPane: function () {
        $('#layersJsMapPane').hide();
        $('#legendJsMapPane').show();
        Beacon.TOC.updateLegend();
        $('#btnShowLegendPane')
            .attr("aria-selected", "true")
            .attr("tabindex", "0")
            .focus()
            .parent()
            .addClass("active");
        $('#btnShowTocPane')
            .attr("aria-selected", "false")
            .attr("tabindex", "-1")
            .parent()
            .removeClass("active");
        Beacon.localStorage['ShowMapLegend'] = 'true';
    },

    showLayerListPane: function () {
        $('#legendJsMapPane').hide();
        $('#layersJsMapPane').show();
        $('#btnShowLegendPane')
            .attr("aria-selected", "false")
            .attr("tabindex", "-1")
            .parent()
            .removeClass("active");
        $('#btnShowTocPane')
            .attr("aria-selected", "true")
            .attr("tabindex", "0")
            .focus()
            .parent()
            .addClass("active");
        Beacon.localStorage.removeItem('ShowMapLegend');
    },

    moreText: "show all values...",
    lessText: "show fewer values...",

    toggleLongLegend: function (o) {
        var layerId = o.attributes["layerid"].value;  //framework layerid - links to div's id
        var divExtras = $("#legendExtra_" + layerId);
        if (o.innerText != this.moreText) {
            o.innerText = this.moreText;
            divExtras.slideUp();
        } else {
            o.innerText = this.lessText;
            divExtras.slideDown();
        }
    },



    setFocusToItem: function (layer) {

        _.forIn(this.layerViewModel, function (v) {

            if (v !== layer) {
                v.div.attr('tabindex', '-1');
            }

            if (v.GroupName) {
                _.forIn(v.Layers, function (v2) {
                    if (v2 !== layer) {
                        v2.div.attr('tabindex', '-1');
                    }
                });
            }
        });

        layer.div.attr('tabindex', '0');

    },


    findNextItem: function (currentItem, reverse) {

        // LayerViewModel: [ (Group | Layer)+ ] - Group.Layers = [ (Layer)+ ]
        //    Group.expanded - visibility flag to watch

        var foundCurrentItem = false;
        var nextItem = null;

        var foreachFcn = reverse ? _.forInRight : _.forIn;

        foreachFcn(this.layerViewModel, function (v) {

            if (v.GroupName) {
                // the group v is an item too

                if (!reverse) {
                    if (foundCurrentItem) {
                        nextItem = v;
                        return false; //exits loop
                    }

                    if (v === currentItem) {
                        foundCurrentItem = true;
                    }
                }

                if (v.expanded) { // only search group's layers if they are expanded
                    foreachFcn(v.Layers, function (v2) {

                        if (foundCurrentItem) {
                            nextItem = v2;
                            return false; //exits loop
                        }

                        // v2 is a layer in the group
                        if (v2 === currentItem) {
                            foundCurrentItem = true;
                        }

                    });
                }

                if (nextItem) return false; // exit loop - found item in inner loop

                if (reverse) {  // we search the layers after the group, since we are doing it backwards
                    if (foundCurrentItem) {
                        nextItem = v;
                        return false; //exits loop
                    }

                    if (v === currentItem) {
                        foundCurrentItem = true;
                    }
                }

            } else {
                // v is an ungrouped layer
                if (foundCurrentItem) {
                    nextItem = v;
                    return false; //exits loop
                }

                if (v === currentItem) {
                    foundCurrentItem = true;
                }
            }
        });

        return nextItem;

    },

    setFocusToNextItem: function (layer) {

        var nextItem = this.findNextItem(layer, false);

        if (nextItem) {
            this.setFocusToItem(nextItem);
            nextItem.div.focus();
        }

    },

    setFocusToPreviousItem: function (layer) {

        var prevItem = this.findNextItem(layer, true);

        if (prevItem) {
            this.setFocusToItem(prevItem);
            prevItem.div.focus();
        }

    },

    CLASS_NAME: "Beacon.TOC"

};



Beacon.TOC.Layer = {

    // public API members:  willLayerDraw  (ref: beacon.control.identify)

    div: null,
    checkboxImg: null,
    layerOn: null,

    initialize: function () {

        _.bindAll(this);

        this.div = $(".layer[data-layerid='" + this.LayerId + "']", Beacon.TOC.rootTreeNode);
        this.checkboxImg = $(".toc-checkbox", this.div);
        this.initWcag();

        this.checkboxImg.click(this.toggleLayerVisibility);

        var scale = Beacon.MapJS.map.getResolution();
        this.updateLayerCheckbox(scale);

        if (this.Visible)
            Beacon.TOC.updateDisclaimers(this);

    },

    updateScale: function (scale) {
        this.updateLayerCheckbox(scale);
    },

    updateLayerCheckbox: function (scale) {

        if (this.Locked) {
            visibilityClass = 'icon-lock';
            this.checkboxImg
                .removeClass("icon-check")
                .removeClass("icon-check-empty")
                .addClass("icon-lock");
        }
        else {
            if (this.Visible) {
                visibilityClass = 'icon-check';
                this.checkboxImg
                    .removeClass("icon-lock")
                    .removeClass("icon-check-empty")
                    .addClass("icon-check");
                this.checkboxImg.attr("aria-checked", "true");
                this.checkboxImg.parent().attr("aria-checked", "true");


            } else {
                visibilityClass = 'icon-check-empty';
                this.checkboxImg
                    .removeClass("icon-lock")
                    .removeClass("icon-check")
                    .addClass("icon-check-empty");
                this.checkboxImg.attr("aria-checked", "false");
                this.checkboxImg.parent().attr("aria-checked", "false");

            }

            if (this.inScaleRange(scale)) {
                this.checkboxImg.removeClass("inactive");
            }
            else {
                this.checkboxImg.addClass("inactive");
            }
        }


    },

    toggleLayerVisibility: function () {

        if (this.Locked) {
            return;
        }

        this.setLayerVisibility(!this.Visible, true);


    },

    setLayerVisibility: function (visible, initialUserClick) {

        if (this.Locked) {
            return;
        }

        this.Visible = visible;

        var scale = Beacon.MapJS.map.getResolution();



        this.updateLayerCheckbox(scale);

        Beacon.API.SetLayerVisibility(this.LayerId, this.Visible,
            function () {
                //success
                if (this.inScaleRange(scale)) {
                    this.spinLayerCheckbox();
                    this.triggerMapRefresh();
                }
                Beacon.TOC.updateLegend();
            },
            function () {
                Beacon.MapJS.showRetryActivity();
            }, this);

        if (initialUserClick) {

            // handle other members of a ExclusiveVisibilityGroup:
            if (this.VisibilityGroup && this.Visible) {

                var targetLayerId = this.LayerId;

                _.chain(Beacon.TOC.layerIndex)
                    .where({ VisibilityGroup: this.VisibilityGroup })
                    .forIn(function (otherLayer) {
                        if ((otherLayer.LayerId != targetLayerId) && otherLayer.Visible) {
                            otherLayer.setLayerVisibility(false, false);
                        }
                    });

            }

        }

        if (this.Visible)
            Beacon.TOC.updateDisclaimers(this);

    },

    restoreDefaultVisibilty: function () {
        if (this.Visible !== this.DefaultVisibility)
            this.setLayerVisibility(this.DefaultVisibility, true);
    },

    triggerMapRefresh: function () {

        //if (this.inScaleRange(scale)) { //we only want to redraw iamge if you could actually see them

        // new - fire off TOC event

        var event = {
            serverId: this.ServerId,
            visible: this.Visible
        };

        Beacon.TOC.events.triggerEvent("refreshServer", event);

        //} else {
        //    //this.updateLayerCheckbox(scale); //just stop the spinning
        //    this.unspinLayerCheckbox();
        //}

    },

    forceGroupVisible: function () {
        if (this.Group) {
            this.Group.showGroupLayer();
        }
    },

    inScaleRange: function (scale) {
        if ((this.ScaleMin != 0) && (scale < this.ScaleMin)) return false;
        if ((this.ScaleMax != 0) && (scale > this.ScaleMax)) return false;
        return true;
    },

    willLayerDraw: function () {
        var scale = Beacon.MapJS.map.getResolution();  // is this right?
        return (!this.Locked && this.Visible && this.inScaleRange(scale));
    },

    onLayerLoadStart: function () {
        if (this.willLayerDraw()) {
            this.spinLayerCheckbox();
        }
    },

    onLayerLoadEnd: function () {
        //undo the layer events
        var scale = Beacon.MapJS.map.getResolution();  // is this right?

        this.unspinLayerCheckbox();
        this.updateLayerCheckbox(scale);
    },

    onLayerVisibilityChanged: function (visibility) {
        // this happens if you turn off the only or last layer on a source
        if (!this.Visible) {
            this.unspinLayerCheckbox();
        }
        var scale = Beacon.MapJS.map.getResolution();  // is this right?
        this.updateLayerCheckbox(scale);
    },

    setActive: function () {

        ////tweak CSS
        this.div.addClass("active-layer");
        this.forceGroupVisible();
        if (!this.Visible) this.toggleLayerVisibility();

        //add in the I image
        this.div.prepend("<span class='icon icon-info-circled'></span>");
        $("span.sr-only", this.div).text(" (this is the active layer)");
        $("a.layer-change-btn", this.div).attr("title", "This is the active layer");
    },

    unsetActive: function () {
        this.div.removeClass("active-layer");
        $("span.icon-info-circled", this.div).remove();
        $("span.sr-only", this.div).text(" (click the / key to activate this layer)");
        $("a.layer-change-btn", this.div).attr("title", "Click to make this layer active");
    },

    spinLayerCheckbox: function () {
        this.checkboxImg.addClass("icon-spin6 animate-spin");

    },

    unspinLayerCheckbox: function () {
        this.checkboxImg.removeClass("icon-spin6 animate-spin");
        this.checkboxImg.addClass("animate-stop");  //this is hear to fix IE 11 not stopping the animation
    },

    initWcag: function () {
        this.div.attr('tabindex', '-1');
        this.div.on("keydown", this.handleKeyDown);
        this.div.on("focus", this.handleFocus);
    },

    handleKeyDown: function (e) {

        switch (e.keyCode) {
            case Beacon.WCAG.keyCode.SPACE:
            case Beacon.WCAG.keyCode.RETURN:
                e.preventDefault();
                e.stopPropagation();
                this.checkboxImg.click();
                break;

            case Beacon.WCAG.keyCode.UP:
                e.preventDefault();
                e.stopPropagation();
                Beacon.TOC.setFocusToPreviousItem(this);
                break;

            case Beacon.WCAG.keyCode.DOWN:
                e.preventDefault();
                e.stopPropagation();
                Beacon.TOC.setFocusToNextItem(this);
                break;

            case Beacon.WCAG.keyCode.LEFT:
                e.preventDefault();
                e.stopPropagation();
                if (this.Group) {
                    Beacon.TOC.setFocusToItem(this.Group);
                    this.Group.div.focus();
                }
                break;

            case Beacon.WCAG.keyCode.RIGHT:
                e.preventDefault();
                e.stopPropagation();
                // just eat these keys
                break;

            case Beacon.WCAG.keyCode.SLASH:
                // activate the layer
                e.preventDefault();
                e.stopPropagation();
                $("a", this.div).click();
                break;
        }
    },

    handleFocus: function (e) {
        Beacon.TOC.setFocusToItem(this);
    },

    CLASS_NAME: "Beacon.TOC.Layer"
};


Beacon.TOC.Group = {

    div: null,
    toggleImg: null,
    expanded: false,

    initialize: function () {

        _.bindAll(this);

        this.div = $(".grouplayer[data-groupid='" + this.GroupId + "']", Beacon.TOC.rootTreeNode);
        this.toggleImg = $(".grouptoggle", this.div);
        this.expanded = false;

        this.initWcag();

        this.expanded = (this.getCookie()) ? true : false;

        this.updateGroupLayerIcon(false);

        this.toggleImg.click(this.toggleGroupLayer);
        $(">.name", this.div).click(this.toggleGroupLayer);
    },

    toggleGroupLayer: function () {

        this.expanded = !this.expanded;
        this.updateGroupLayerIcon(true);

    },

    showGroupLayer: function () {
        this.expanded = true;
        this.updateGroupLayerIcon(false);
    },

    restoreDefaultVisibilty: function () {
        for (var i = 0; i < this.Layers.length; i++) {
            this.Layers[i].restoreDefaultVisibilty();
        }
    },

    updateGroupLayerIcon: function (animationOk) {

        //update icon
        if (this.expanded) {
            this.toggleImg.removeClass('icon-plus-squared-alt').addClass('icon-minus-squared-alt');
            this.setCookie(true);
            this.div.attr("aria-expanded", "true");
        } else {
            this.toggleImg.removeClass('icon-minus-squared-alt').addClass('icon-plus-squared-alt');
            this.setCookie(null);
            this.div.attr("aria-expanded", "false");
        }

        //show/hide layers
        if (this.expanded) {
            if (animationOk) {
                $(".group-of-layers", this.div).slideDown();
            } else {
                $(".group-of-layers", this.div).show();
            }
        } else {
            if (animationOk) {
                $(".group-of-layers", this.div).slideUp();
            } else {
                $(".group-of-layers", this.div).hide();
            }
        }

    },


    getCookieName: function () {
        return 'TOC-' + mapConfig.AppId + '-' + this.GroupId;
    },

    // set cookie -- use null to clear cookie
    setCookie: function (flag) {
        if (flag) {
            Beacon.localStorage[this.getCookieName()] = 'true';
        } else {
            Beacon.localStorage.removeItem(this.getCookieName());
        }
    },

    getCookie: function () {
        return (Beacon.localStorage[this.getCookieName()] === 'true');
    },

    initWcag: function () {
        this.div.attr('tabindex', '-1');
        this.div.on("keydown", this.handleKeyDown);
        this.div.on("focus", this.handleFocus);
    },

    handleKeyDown: function (e) {

        switch (e.keyCode) {
            case Beacon.WCAG.keyCode.SPACE:
            case Beacon.WCAG.keyCode.RETURN:
                this.toggleImg.click();
                e.preventDefault();
                e.stopPropagation();
                break;

            case Beacon.WCAG.keyCode.UP:
                Beacon.TOC.setFocusToPreviousItem(this);
                e.preventDefault();
                e.stopPropagation();
                break;

            case Beacon.WCAG.keyCode.DOWN:
                Beacon.TOC.setFocusToNextItem(this);
                e.preventDefault();
                e.stopPropagation();
                break;

            case Beacon.WCAG.keyCode.LEFT:
                this.expanded = false;
                this.updateGroupLayerIcon(true);
                e.preventDefault();
                e.stopPropagation();
                break;

            case Beacon.WCAG.keyCode.RIGHT:
                this.expanded = true;
                this.updateGroupLayerIcon(true);
                e.preventDefault();
                e.stopPropagation();
                break;
        }
    },

    handleFocus: function (e) {
        Beacon.TOC.setFocusToItem(this);
    },

    CLASS_NAME: "Beacon.TOC.Group"
};