Beacon.Layer.SelectionLayer = OpenLayers.Class(OpenLayers.Layer.Vector, {

    visibility: true,
    keyFeature: null,
    keyValue: null, //READ ONLY!
    //hover: null,
    //ctrl: null,

    rendererOptions: { zIndexing: true },

    styleMap: new OpenLayers.StyleMap({
        "default": new OpenLayers.Style({
            strokeWidth: 2,
            graphicZIndex: 1
        }),
        "selected": new OpenLayers.Style({
            strokeWidth: 2,
            graphicZIndex: 2
        }),
        "hovering": new OpenLayers.Style({
            strokeWidth: 2,
            graphicZIndex: 4
        }),
        "buffer": new OpenLayers.Style({
            strokeWidth: 2,
            graphicZIndex: 3
        })
    }, {
        extendDefault: true
    }),

    eventDelay: null,

    initialize: function (keyValue, options, zoomToFeaturesOnLoad) {
        OpenLayers.Layer.Vector.prototype.initialize.apply(this, arguments);

        this.setStyleFromConfig("default", options.symbology.selection);
        this.setStyleFromConfig("selected", options.symbology.key);
        this.setStyleFromConfig("hovering", options.symbology.hover);
        this.setStyleFromConfig("buffer", options.symbology.buffer);

        this.eventDelay = new Beacon.DelayedFunction(this.sendSelectionSetToServer, this, 423);

        // populate selection set at startup
        this.loadSelectedFeaturesFromServer(keyValue, zoomToFeaturesOnLoad);

        this.events.register("featureremoved", this, this.removePopupByFeature);

        var self = this;

        $("#btnCloseDetailsPane").click(function (e) {
            self.hideResultHtml();
            e.preventDefault();
        });
        Beacon.ResultsPane.initialize();

    },

    destroy: function () {
        this.eventDelay.destroy();
        this.eventDelay = null;
    },


    // this lets up wire up a control to handle the hover events to the map
    setMap: function (map) {

        OpenLayers.Layer.Vector.prototype.setMap.apply(this, arguments);

    },



    sendSelectionSetToServer: function () {

        Beacon.API.SetResults(mapConfig.LayerId, this.getSelectedKeyValues());

    },

    getSelectedKeyValues: function() {
        var keylist = [];

        for (i = 0; i < this.features.length; i++)
            keylist.push(this.features[i].fid);

        return keylist;
    },

    // overrides baseclass - don't call this unless you update UI too, use .selectFeatures
    addFeatures: function (features, options) {

        OpenLayers.Layer.Vector.prototype.addFeatures.apply(this, arguments);

        var feature;
        for (var i = 0, len = features.length; i < len; ++i) {
            feature = features[i];

            Beacon.ResultsPane.addToSelection(feature.fid,
                                          feature.parentId,
                                          feature.html,
                                          this.onFeatureHover,
                                          this.onFeatureClick,
                                          this);

        }

        //        Beacon.ResultsPane.updateDisplay(this.features.length, this.keyValue);
        //        Beacon.Tabs.generateTabStrip(this.features.length, this.keyValue);

        this.eventDelay.delayedInvoke();
    },

    // overrides baseclass - don't call this unless you update UI too, use .selectFeatures or .clearSelection
    removeFeatures: function (features, options) {

        var feature;
        for (var i = 0, len = features.length; i < len; ++i) {
            feature = features[i];

            // nuke key if we've just removed it from the selection
            if (feature.fid == this.keyValue) {
                this.setKeyFeature(null);
            }

            Beacon.ResultsPane.removeFromSelection(feature.fid);

        }

        OpenLayers.Layer.Vector.prototype.removeFeatures.apply(this, arguments);

        //        Beacon.ResultsPane.updateDisplay(this.features.length, this.keyValue);
        //        Beacon.Tabs.generateTabStrip(this.features.length, this.keyValue);

        this.eventDelay.delayedInvoke();
    },

    selectFeatures: function (features, appendSelection, toggleSelection) {

        if (!appendSelection && !toggleSelection) {
            this.clearSelection();
        }

        var selectedFeatures = [];
        var feature;
        var existingFeature;

        for (var i = 0, len = features.length; i < len; ++i) {
            feature = features[i];

            existingFeature = this.findFeatureById(feature.fid);

            if (existingFeature) {
                if (toggleSelection) {
                    //remove from selection
                    this.removeFeatures([existingFeature]);
                }
            } else {
                //add to selection
                this.addFeatures([feature]);
            }
        }

        // update the key value if there is only one item left:
        if (this.features.length == 1) {
            if (this.keyValue != this.features[0].fid) {
                this.setKeyFeature(this.features[0]);
            }
        }

        this.stealParentsGeometry(); //updates missing geom

        Beacon.ResultsPane.updateDisplay(this.features.length, this.keyValue);
        Beacon.Tabs.generateTabStrip(this.features.length, this.keyValue);

    },

    clearSelection: function () {
        this.destroyFeatures();
        this.setKeyFeature(null);
        Beacon.ResultsPane.updateDisplay(this.features.length, this.keyValue);
        Beacon.Tabs.generateTabStrip(this.features.length, this.keyValue);
    },

    //send null for no key
    setKeyFeature: function (feature) {

        // do nothing if still null
        if (!feature && !this.keyFeature) {
            return;
        }

        // do nothing if fid is the same
        if (feature) {
            if (feature.fid == this.keyValue) {
                return;
            }
        }

        var old = this.keyFeature;

        this.keyFeature = feature;

        //Copy FID for read-only use:
        if (feature) {
            this.keyValue = feature.fid;
        } else {
            this.keyValue = null;
        }

        //update rendering and refresh key feature
        //this.updateRendererForKeyValue();

        if (this.keyFeature) {
            this.setSelectionStyle(this.keyFeature, true);
        }

        //refresh old feature to change style
        if (old) {
            this.setSelectionStyle(old, false);
        }

        if (this.keyFeature) {
            Beacon.ResultsPane.setKeyFeatureId(this.keyValue);
        }

        Beacon.Tabs.generateTabStrip(this.features.length, this.keyValue);


        //delay this slightly to let map images get fetched
        // _.delay(_.bind(this.updateMapDetails, this), 650);

        var self = this;

        setTimeout(function () {
            self.updateMapDetails();
        }, 650);

    },

    updateMapDetails: function () {

        if (this.keyValue && this.keyValue !== 'No Data Found') {

            if (!Beacon.MapJS.mobileDevice) {
                Beacon.API.QueryMapDetail(
                    mapConfig.LayerId,
                    this.keyValue,
                    function (html) {
                        if (html == '') html = 'No data is available';
                        this.showResultHtml(html);
                    },
                    function (err) {
                        this.showResultHtml('Server request failed.');
                    },
                    this);
            } else {
                if (this.keyFeature.tipHtml) {
                    var tiphtml = this.keyFeature.tipHtml;
                    tiphtml += Beacon.Tabs.getReportLinksHtml(this.keyFeature.fid);
                    this.showResultHtml(tiphtml);
                }
            }
        } else {
            this.hideResultHtml();
        }

    },


    showResultHtml: function (html) {
        $("#mapJsDetailDivContent").html(html);
        $("#mapJsDetailDiv").show();

        // adjust controls at bottom of map:
        var h = $("#mapJsDetailDiv").height() + 13;
        $(".bEsriLogo").css("bottom", h + 12); 
        $(".bScalebar").css("bottom", h + 8);
        $(".bControlDisplayXY").css("bottom", h);
    },

    hideResultHtml: function () {
        $("#mapJsDetailDiv").hide();
        $(".bEsriLogo").css("bottom", "12px");
        $(".bScalebar").css("bottom", "8px");
        $(".bControlDisplayXY").css("bottom", "0");
    },

    setSelectionStyle: function (feature, selected) {
        //feature.renderIntent = selected ? "selected" : "default";
        this.drawFeature(feature, selected ? "selected" : "default");
    },

    stealParentsGeometry: function () {
        for (var i = 0, len = this.features.length; i < len; ++i) {
            var feature = this.features[i];

            if (!feature.geometry && feature.parentId) {
                var parentFeature = this.findFeatureById(feature.parentId);
                if (parentFeature) {
                    feature.geometry = parentFeature.geometry;
                }
            }
        }
    },


    loadSelectedFeaturesFromServer: function (keyValue, zoomToFeatures) {

        Beacon.MapJS.showActivity("Retrieving selected items");

        var bfp = new Beacon.Protocol.BeaconFeature();

        bfp.readFeatures(true, null, Beacon.API.SpatialRelation.None, null,
            function (features) {

                //add features to selection set
                this.selectFeatures(features, false, false);

                //wire up to key
                this.setKeyFeature(this.findFeatureById(keyValue));

                if (zoomToFeatures) {
                    //zoom to keyvalue
                    if (this.keyFeature) {
                        if (this.keyFeature.geometry && this.keyFeature.geometry.bounds) {
                            Beacon.MapJS.zoomToFeatureExtent(this.keyFeature.geometry.bounds, false);
                        }
                        else {
                            Beacon.Dialogs.showWithHtml(
                                null,
                                "Parcel Feature Not Found",
                                "A feature could not be found in the map data for \"" + this.keyFeature.fid + "\"",
                                false, "Close");
                        }
                    } else {
                        //zoom to selection
                        var ext = this.getDataExtent();
                        Beacon.MapJS.zoomToFeatureExtent(ext);
                    }
                }

                Beacon.MapJS.enableDynamicLayers();

                Beacon.MapJS.hideActivity();

            },

            function (e) {
                //err
                Beacon.MapJS.showRetryActivity();
            },

            this);

    },


    findFeatureById: function (featureId) {
        for (var i = 0, len = this.features.length; i < len; ++i) {
            if (this.features[i].fid == featureId) {
                return this.features[i];
            }
        }
        return null;
    },


    //support for hover - called from info pane
    onFeatureHover: function (featureId, hovering) {

        var feature = this.findFeatureById(featureId);
        if (feature) {
            if (hovering) {
                this.drawFeature(feature, "hovering");
            } else {
                this.setSelectionStyle(feature, (featureId == this.keyValue));
            }
        }
    },

    onFeatureClick: function (featureId) {
        var feature = this.findFeatureById(featureId);
        if (feature) {

            if (feature.geometry) {

                var pt = feature.geometry.getCentroid();
                var lonlat = new OpenLayers.LonLat(pt.x, pt.y);

                //zoom
                Beacon.MapJS.zoomToFeatureExtent(feature.geometry.getBounds(), false);

                //plant tip
                //Beacon.MapJS.mapTipControl.plantTip(lonlat, 1);
                this.clearPopups();
                this.showPopupForFeature(feature, null);

            }

            if (!feature.geometry) {
                if (feature.parentId) {
                    alert('no geom, I should look at ' + feature.parentId);
                }
            }

            // make key
            this.setKeyFeature(feature);

        }
    },

    // hover events from the map
    handleMapHoverOver: function (feature) {
        this.drawFeature(feature, "hovering");
        Beacon.ResultsPane.onFeatureMouseOver(feature.fid);
    },

    handleMapHoverOut: function (feature) {
        this.setSelectionStyle(feature, (feature.fid == this.keyValue));
        Beacon.ResultsPane.onFeatureMouseOut(feature.fid);
    },




    setStyleFromConfig: function (name, rgba) {
        var style = this.styleMap.styles[name].defaultStyle;

        style.fillColor = 'rgb(' + rgba.R + ',' + rgba.G + ',' + rgba.B + ')';
        style.strokeColor = style.fillColor;
        style.fillOpacity = rgba.A;
        style.strokeOpacity = 0.9;

        style.pointRadius = 3;
        style.graphicName = 'square';

    },


    showPopupForFeature: function (feature, popup) {
        //popup is optional - created if needed

        if (!feature) return;

        //this.clearPopups();
        var p;

        

        if (!popup) {

            var existingPopup = false;
            for (var i = 0; i < this.map.popups.length; i++) {
                if (this.map.popups[i].fid === feature.fid)
                    existingPopup = true;
            }
            if (!existingPopup) {
                var pt = feature.geometry.getCentroid();
                var lonlat = new OpenLayers.LonLat(pt.x, pt.y);

                p = new Beacon.Popup.MapTipFrame("maptip2",
                    lonlat,
                    null,
                    "<div class='title'>Loading Information...</div><div class='loader'></div>",
                    {
                        size: new OpenLayers.Size(0, 0),
                        offset: new OpenLayers.Pixel(0, 0)
                    },
                    true);

                var dragPopup = new Beacon.Popup.MapTipFrameDragable(p)
                dragPopup.setMap(this.map);
                //dragPopup.drawMarkup(lonlat, popup.startPosition);
                this.map.addPopup(dragPopup.popup);

                p.setFeature(feature);
            }
        } else {
            p = popup;
            p.setFeature(feature);
        }

        

    },


    clearPopups: function () {
        for (var i = this.map.popups.length - 1; i >= 0; i--) {
            var p = this.map.popups[i];
            if (!p.pinned && (!p.dragContainer || !p.dragContainer.down)) {
                if (p.dragContainer)
                    p.dragContainer.removeMarkup();
                this.map.removePopup(p);
            } 
        }
    },

    clearAllPopups: function () {
        for (var i = this.map.popups.length - 1; i >= 0; i--) {
            var p = this.map.popups[i];
            if (p.dragContainer)
                p.dragContainer.removeMarkup();

            this.map.removePopup(p);
        }
    },

    removePopupByFeature: function (e) {
        //remove any related tips, pinned or not?!?
        for (var i = this.map.popups.length - 1; i >= 0; i--) {
            var p = this.map.popups[i];
            if ((p.fid == e.feature.fid) && !p.pinned) {
                if (p.dragContainer)
                p.dragContainer.removeMarkup();
                this.map.removePopup(p);
            }
        }
    },


    zoomAndSelectByKeyValue: function (key) {

        try {
            if (!key || key == -1) return;

            Beacon.MapJS.showActivity("Performing zoom");

            //set selection
            Beacon.API.SetResults(mapConfig.LayerId, [key],
                function () {
                    var reader = new Beacon.Protocol.BeaconFeature({});

                    reader.readFeatures(
                        true,
                        null,
                        Beacon.API.SpatialRelation.None,
                        null,
                        this._gotFeatures,
                        function () {
                            Beacon.MapJS.showRetryActivity();
                        },
                        this);
                },
                function () { Beacon.MapJS.hideActivity(); },
                this);
        } catch (e) {
            Beacon.MapJS.logException(e, "SelectionLayer.zoomAndSelectByKeyValue");

        }

    },



    _gotFeatures: function (features) {
        try {
            this.selectFeatures(features, false, false);

            var geometriesPresent = false;
            var nullGeoKeys = [];

            for (var i = 0, len = features.length; i < len; ++i) {
                var f = features[i];
                if (f.geometry) {
                    geometriesPresent = true;
                }
                else {
                    nullGeoKeys.push("A feature could not be found in the map data for \"" + f.fid + "\"");
                }
            }

            if (nullGeoKeys.length > 0) {

                // Some geometries were not found in the features collection, report them to the user...AL

                var msgTitle = "Parcel Feature Not Found";
                if (nullGeoKeys.length > 1) {
                    msgTitle = "One or more Parcel Features Were Not Found";
                }
                Beacon.Dialogs.showWithHtml(
                    null,
                    msgTitle,
                    nullGeoKeys.join("<br>"),
                    false, "Close");
            
            }

            if (geometriesPresent) {
                // Only calculate the extent if there are atleast one feature with a geometry present...AL
                var ext = this.getDataExtent();
                Beacon.MapJS.zoomToFeatureExtent(ext);
            }

            Beacon.MapJS.hideActivity();
        } catch (e) {
            Beacon.MapJS.showRetryActivity(e, "SelectionLayer._gotFeatures");
        }

    },

    CLASS_NAME: "Beacon.Layer.SelectionLayer"
});