
Beacon.Layer.Beacon = OpenLayers.Class(OpenLayers.Layer.Grid, {

    /**
    * Constant: DEFAULT_PARAMS
    * {Object} Default query string parameters.
    */
    DEFAULT_PARAMS: {
    },



    beaconServerId: null, //int of fw spatialserverid
    beaconLayerIds: null, //int[] of fw layerids

    singleLayerSource: false,

    serverType: null, //int - LayerTypes enum


    /**
    * APIProperty: tileSize
    * {<OpenLayers.Size>} Size for tiles.  Default is 512x512.
    */
    tileSize: null,

    /**
    * APIProperty: async
    * {Boolean} Request images asynchronously.  Default is true.
    */
    async: true,

    /**
    * APIProperty: name
    * {String} Layer name.  Default is "Beacon".
    */
    name: "Beacon",

    /**
    * APIProperty: isBaseLayer
    * {Boolean} The layer is a base layer.  Default is true.
    */
    isBaseLayer: true,

    //Use overview map service
    overview: false,

    eventDelay: null,

    drawable: true,

    /**
    * Constant: DEFAULT_OPTIONS
    * {Object} Default layers properties.
    */
    DEFAULT_OPTIONS: {
        tileSize: new OpenLayers.Size(512, 512),
        isBaseLayer: true,
        async: true,
        name: "Beacon"
    },

    /**
    * Constructor: Beacon.Layer.Beacon
    * Create a new Beacon layer object.
    *
    */
    initialize: function (name, url, options) {

        this.tileSize = new OpenLayers.Size(512, 512);

        // parameters
        this.params = OpenLayers.Util.applyDefaults(
            {},
            this.DEFAULT_PARAMS
        );
        this.options = OpenLayers.Util.applyDefaults(
            options, this.DEFAULT_OPTIONS
        );

        OpenLayers.Layer.Grid.prototype.initialize.apply(
            this, [name, url, this.params, options]
        );

        //layer is transparent        
        if (this.transparent) {

            // unless explicitly set in options, make layer an overlay
            if (!this.isBaseLayer) {
                this.isBaseLayer = false;
            }

            // jpegs can never be transparent, so intelligently switch the 
            //  format, depending on the browser's capabilities
            if (this.format == "image/jpeg") {
                this.format = OpenLayers.Util.alphaHack() ? "image/gif" : "image/png";
            }
        }

        //hook up optimizations for self-hiding during mousewheel events
        //this.eventDelay = new Beacon.DelayedFunction(this.makeLayerVisibleAgain, this, 500);

        //this.transitionEffect = null;
        //this.ratio = 1.0;
        //this.hideLayerForABit();

        //this.singleTile = false;

    },

    //setMap: function (map) {
    //    OpenLayers.Layer.Grid.prototype.setMap.apply(this, arguments);
    //    //this.map.events.register("move", this, this.hideLayerForABit);
    //},

    //hideLayerForABit: function () {
    //    console.log("hiding for a bit");
    //    this.setVisibility(false);
    //    this.eventDelay.delayedInvoke();
    //},

    //makeLayerVisibleAgain: function () {
    //    console.log("DONE hiding!");
    //    this.setVisibility(true);
    //},

    /**
    * Method: destroy
    * Destroy this layer
    */
    destroy: function () {
        // for now, nothing special to do here. 
        OpenLayers.Layer.Grid.prototype.destroy.apply(this, arguments);
    },


    /**
    * Method: getURL
    * Return an image url this layer.
    *
    * Parameters:
    * bounds - {<OpenLayers.Bounds>} A bounds representing the bbox for the
    *     request.
    *
    * Returns:
    * {String} A string with the map image's url.
    */
    
    getURL: function (bounds) {

        //This makes sure that the TOC visibility is recalculated before the layer request is generated
        Beacon.TOC.onZoomEnd(this.map.getResolution());

        var visibleLayers = Beacon.TOC.getVisibleLayerIdsForServerId(this.beaconServerId);
       
        if (visibleLayers.length == 0 || !this.drawable) {
            //substitute a blank image when we have no layers to draw
            return "/Images/blank.gif";
        }


        bounds = this.adjustBounds(bounds);

        var newUrl = this.getFullRequestString({
            QPS: mapConfig.QPS,
            svr: this.beaconServerId, // this.serverType, //LayerType.*
            minx: bounds.left,
            miny: bounds.bottom,
            maxx: bounds.right,
            maxy: bounds.top,
            w: this.tileSize.w,
            h: this.tileSize.h,
            dpi: 96,
            OV: (this.overview) ? '1' : null
        });

        return newUrl;
    },


    /**
    * Method: addTile
    * addTile creates a tile, initializes it, and adds it to the layer div. 
    *
    * Parameters:
    * bounds - {<OpenLayers.Bounds>}
    * position - {<OpenLayers.Pixel>}
    * 
    * Returns:
    * {<OpenLayers.Tile.Image>} The added image tile.
    */
    addTile: function (bounds, position) {

        return new Beacon.Tile.Image(this, position, bounds, null, this.tileSize);

    },

    clone: function (obj) {
        if (obj == null) {
            obj = new Beacon.Layer.Beacon(this.name,
                                           this.url,
                                           this.getOptions());
        }
        //get all additions from superclasses
        obj = OpenLayers.Layer.Grid.prototype.clone.apply(this, [obj]);

        return obj;
    },


    CLASS_NAME: "Beacon.Layer.Beacon"
});


