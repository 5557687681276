Beacon.Control.BingMaps = OpenLayers.Class(Beacon.Control.HoverSupport, {

    clickHandler: null,
    featureHandler: null,

    bingGeoRssUrl: "http://www.bing.com/maps/default.aspx?style=b&lvl=18",
    bingYxUrl: "http://www.bing.com/maps/default.aspx?style=b&lvl=18&cp=",

    initialize: function (options) {

        Beacon.Control.HoverSupport.prototype.initialize.apply(this, [options]);

        this.featureHandler = new OpenLayers.Handler.Feature(
            this,
            this.selectionLayer,
            { click: this.showFeature },
            { stopClick: true, stopDown: true });

        this.clickHandler = new OpenLayers.Handler.Click(this, {
            click: this.click
        }, {
            pixelTolerance: 10
        });

    },

    setMap: function (map) {
        Beacon.Control.HoverSupport.prototype.setMap.apply(this, arguments);

        this.featureHandler.setMap(map);
        this.clickHandler.setMap(map);

    },

    activate: function () {
        if (!this.active) {

            this.clickHandler.activate();
            this.featureHandler.activate();


        }
        return Beacon.Control.HoverSupport.prototype.activate.apply(this, arguments);
    },

    deactivate: function () {
        if (this.active) {
            this.featureHandler.deactivate();
            this.clickHandler.deactivate();
        }
        return Beacon.Control.HoverSupport.prototype.deactivate.apply(this, arguments);
    },

    click: function (evt) {

        try {
            if (this.hoverGotIt) {
                this.hoverGotIt = false;
                return;
            }
            var pt = this.map.getLonLatFromPixel(evt.xy);
            this.showPoint(pt);
        } catch (e) {
            Beacon.MapJS.showRetryActivity(e, "BingMaps.Click");
        }

    },

    showFeature: function (feature) {
        Beacon.GA.TrackEvent('BingMaps', 'Feature');
        //var pfUrl = "https://" + document.location.hostname + "/BingParcelFeed.ashx?QPS=" + mapConfig.QPS
        //                + '&KV=' + escape(feature.fid);
        var pfUrl = '';
        var prjLL = new OpenLayers.Projection("EPSG:4326"); //WGS84

        var cp = feature.geometry.getCentroid();
        var tcp = cp.transform(this.map.projection, prjLL);

        var url = this.bingGeoRssUrl + '&cp=' + tcp.y + '~' + tcp.x;

        var vertices = feature.geometry.getVertices();
        var latLonPairs = '';

        for (var v = 0; v < vertices.length; v++) {
            var pt = new OpenLayers.Geometry.Point(vertices[v].x, vertices[v].y);

            var llPt = pt.transform(this.map.projection, prjLL);

            if (latLonPairs != '') {
                latLonPairs += '_';
            }
            latLonPairs += llPt.y + '_' + llPt.x;
        }

        var title = escape("Parcel Information " + feature.fid);
        var notes = '';
        var linkURL = '';
        var photoURL = '';
        var strokeColor = escape('#00ff00');
        var fillColor = '';
        var strokeWeight = '3px';
        var strokeStyle = 'Single';
        var strokeDashStyle = 'Solid';

        url += '&sp=polygon.' + latLonPairs + '_' + title + '_' + notes + '_' + linkURL + '_' + photoURL + '_' + strokeColor + '_' + fillColor + '_' + strokeWeight + '_' + strokeStyle + '_' + strokeDashStyle;

        window.open(url, '_blank');

    },

    showPoint: function (pt) {
        Beacon.GA.TrackEvent('BingMaps', 'Point');

        var prjLL = new OpenLayers.Projection("EPSG:4326"); //WGS84
        pt.transform(this.map.projection, prjLL);

        var url = this.bingYxUrl + pt.lat + '~' + pt.lon;

        var r = window.open(url, '_blank');

    },

    CLASS_NAME: "Beacon.Control.BingMaps"
});