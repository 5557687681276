Beacon.Control.GoogleEarthKml = OpenLayers.Class(OpenLayers.Control, {

    type: OpenLayers.Control.TYPE_BUTTON,

    initialize: function (options) {
        OpenLayers.Control.prototype.initialize.apply(this, [options]);
    },

    destroy: function () {
        OpenLayers.Control.prototype.destroy.apply(this, []);
    },

    trigger: function () {
        //var feature = Beacon.MapJS.selectionLayer.keyFeature;
        //if (feature) {
            this.showFeature(Beacon.MapJS.selectionLayer.keyFeature);
        //}
    },

    showFeature: function (feature) {
        Beacon.GA.TrackEvent('GoogleEarthKml', 'Feature');
        //var url = "/KmlParcelFeed.ashx?QPS=" + mapConfig.QPS + '&KV=' + escape(feature.fid) + '&layerid=' + mapConfig.LayerId;
        var url = "/KmlParcelFeed.ashx?QPS=" + mapConfig.QPS + '&layerid=' + mapConfig.LayerId;
        
        window.location = url;
    },

    CLASS_NAME: "Beacon.Control.GoogleEarthKml"
});