Beacon.Control.Pictometry = OpenLayers.Class(Beacon.Control.HoverSupport, {

    clickHandler: null,
    featureHandler: null,

    initialize: function (options) {
        Beacon.Control.HoverSupport.prototype.initialize.apply(this, [options]);

        this.featureHandler = new OpenLayers.Handler.Feature(
            this,
            this.selectionLayer,
            { click: this.showFeature },
            { stopClick: true, stopDown: true });

        this.clickHandler = new OpenLayers.Handler.Click(this, {
            click: this.click
        }, {
            pixelTolerance: 10
        });

    },

    setMap: function (map) {
        Beacon.Control.HoverSupport.prototype.setMap.apply(this, arguments);

        this.featureHandler.setMap(map);
        this.clickHandler.setMap(map);

    },

    activate: function () {
        if (!this.active) {
            this.clickHandler.activate();
            this.featureHandler.activate();
        }
        return Beacon.Control.HoverSupport.prototype.activate.apply(
            this, arguments
        );
    },

    deactivate: function () {
        if (this.active) {
            this.featureHandler.deactivate();
            this.clickHandler.deactivate();
        }
        return Beacon.Control.HoverSupport.prototype.deactivate.apply(
            this, arguments
        );
    },


    click: function (evt) {

        Beacon.GA.TrackEvent('Pictometry', 'Click');
        Beacon.polFeature = null;

        try {
            var pt = this.map.getLonLatFromPixel(evt.xy);

            var prjLL = new OpenLayers.Projection("EPSG:4326"); //WGS84
            pt.transform(this.map.projection, prjLL);


            var url = "/Pictometry/Viewer.aspx?QPS=" + mapConfig.QPS + "&x=" + pt.lon + "&y=" + pt.lat;

            Beacon.Dialogs.showFromIFrame(null, 'Oblique Imagery Viewer', url);

            //$.colorbox({
            //    title: 'Oblique Imagery Viewer',
            //    iframe: true,
            //    fixed: true,
            //    innerWidth: "804px",
            //    innerHeight: "585px",
            //    href: url
            //});

        } catch (e) {
            Beacon.MapJS.showRetryActivity(e, "Pictometry.click");

        }


    },

    showFeature: function (feature) {
        Beacon.GA.TrackEvent('Pictometry', 'Feature');

        var geom = feature.geometry.clone();

        var prjLL = new OpenLayers.Projection("EPSG:4326"); //WGS84
        geom.transform(this.map.projection, prjLL);

        var pt = geom.getCentroid();
        var bnds = geom.getBounds();
        var size = (bnds.getWidth() > bnds.getHeight()) ? bnds.getWidth() : bnds.getHeight();
        Beacon.polFeature = null;

        if (/MultiPolygon/.test(geom.CLASS_NAME)) {

            var mpGeom = $.map(geom.components, function (polygon) { // handle the multipolygon features
                return [$.map(polygon.components, function (ring) { // handle each of the rings
                    return [$.map(ring.components, function (vertex) { // handle each point
                        return { x: vertex.x, y: vertex.y };
                    })];
                })];
            });
            Beacon.polFeature = {
                type: 'polygon',
                size: size,
                points: mpGeom // geom.getVertices()
            };
        }

        if (/MultiLineString/.test(geom.CLASS_NAME)) {
            var mlGeom = $.map(geom.components, function (linestring) { // handle each of the linestrings
                return [$.map(linestring.components, function (vertex) { // handle each point
                    return { x: vertex.x, y: vertex.y };
                })];
            });
            Beacon.polFeature = {
                type: 'line',
                size: size,
                points: mlGeom //.getVertices()
            };
        }

        if (/MultiPoint/.test(geom.CLASS_NAME)) {
            var mpGeom = $.map(geom.components, function (vertex) { // handle each point
                return { x: vertex.x, y: vertex.y };
            });

            Beacon.polFeature = {
                type: 'point',
                size: null,
                points: mpGeom //.getVertices()
            };
        }

        if (!Beacon.polFeature) {
           // console.info("Unknown geometry: " + geom);
        }

        var url = "/Pictometry/Viewer.aspx?QPS=" + mapConfig.QPS + "&x=" + pt.x + "&y=" + pt.y;

        $.colorbox({
            title: 'Oblique Imagery Viewer',
            iframe: true,
            innerWidth: "804px",
            innerHeight: "585px",
            href: url
        });

    },

    CLASS_NAME: "Beacon.Control.Pictometry"
});