// Inherit from this class to add hover support to a control

Beacon.Control.HoverSupport = OpenLayers.Class(OpenLayers.Control, {

    selectionLayer: null,
    hover: null,
    enableHovering: true,
    lastFeature: null,

    initialize: function (options) {
        OpenLayers.Control.prototype.initialize.apply(this, arguments);

        if (this.enableHovering) {
            this.hover = new OpenLayers.Handler.Feature(
            this,
            this.selectionLayer,
            {
                over: function (e) {
                    this.selectionLayer.handleMapHoverOver.call(this.selectionLayer, e);
                    this.lastFeature = e;
                },
                out: function (e) {
                    this.selectionLayer.handleMapHoverOut.call(this.selectionLayer, e);
                    this.lastFeature = null;
                }
            }, {
                stopClick: false,
                stopUp: false,
                stopDown: false
            });
        }
    },

    setMap: function (map) {
        OpenLayers.Control.prototype.setMap.apply(this, arguments);

        if (this.hover) this.hover.setMap(map);

        //FIX: this breaks clicking on features in IE.  ???

        //        // need to handle mouse-out-of-layer event to do a hoverOut for the handlers.hover.lastFeature
        //        map.events.register("mouseout", this, function (e) {
        //            if (this.hover.lastFeature) {
        //                this.selectionLayer.handleMapHoverOut.call(this.selectionLayer, this.hover.lastFeature);
        //                this.hover.lastFeature = null;
        //            }
        //        });

    },

    activate: function () {
        if (!this.active && this.hover) {
            this.hover.activate();
            this.lastFeature = null;
        }
        return OpenLayers.Control.prototype.activate.apply(this, arguments);
    },

    deactivate: function () {
        if (this.active && this.hover) {
            this.hover.deactivate();
            this.lastFeature = null;
        }
        return OpenLayers.Control.prototype.deactivate.apply(this, arguments);
    },

    stopHovering: function () {
        if (this.lastFeature) {
            this.selectionLayer.handleMapHoverOut.call(this.selectionLayer, this.lastFeature);
            this.lastFeature = null;
        }
        if (this.hover) this.hover.deactivate();
    },

    startHovering: function () {
        if(this.hover) this.hover.activate();
    },

    destroy: function () {

        if (this.hover) {
            this.hover.destroy();
            this.hover = null;
        }
        
        OpenLayers.Control.prototype.destroy.apply(this, arguments);
      
    },

    CLASS_NAME: 'Beacon.Control.HoverSupport'
});