Beacon.Control.Scalebar = OpenLayers.Class(OpenLayers.Control, {

    initialize: function (options) {
        OpenLayers.Control.prototype.initialize.apply(this, [options]);
    },


    draw: function () {
        OpenLayers.Control.prototype.draw.apply(this, arguments);

        this.map.events.register('moveend', this, this.update);
        this.update();

        // setup events:
        var self = this;

        var ul = $("#scaleDropupMenu");
        ul.click(function (e) {
            var target = $(e.target);
            var newLevel = target.data('level');
            self.map.zoomTo(newLevel);
        });

        $(window).bind("resize", function () {
            self.onResize();
        });
        self.onResize();

        return this.div;
    },


    update: function () {

        var res = this.map.getResolution();
        if (!res) {
            return;
        }

        var curMapUnits = this.map.getUnits();
        var scale = OpenLayers.Util.getScaleFromResolution(res, curMapUnits) * Beacon.MapJS.distanceFactor;

        $("#scalebarScaleDisplayText").text(this.getDisplayScale(scale));

        this.populateScaleMenu();
    },

    getDisplayScale: function (scale) {
        var ftScale = scale / 12;

        if (ftScale < 25) {
            return Math.round(10 * ftScale) / 10 + " ft";
        }

        if (ftScale < 2640) {
            return Math.round(ftScale) + " ft";
        }

        return Math.round(10 * ftScale / 5280.0) / 10 + " mi";

    },

    populateScaleMenu: function () {

        var ul = $("#scaleDropupMenu");

        var levels = this.map.getNumZoomLevels();
        var units = this.map.baseLayer.units;
     
        ul.empty();

        for (var i = levels - 1; i >= 0; i--) {
            var res = this.map.getResolutionForZoom(i);
            var scale = OpenLayers.Util.getScaleFromResolution(res, units);
            var txt = this.getDisplayScale(scale);

            var li = $("<li><a href='#' title=''></a></li>");
            var href = $("a", li);

            href.text('1" = ' + txt);
            href.data("level", i);

            ul.append(li);

        }
    },

    onResize: function () {
        var mapViewHeight = $("#olMap").height();
        $("#scaleDropupMenu").css({
            "max-height": mapViewHeight - 68,
            "overflow-y": "auto"
        });
    },
   

    CLASS_NAME: "Beacon.Control.Scalebar"
});

