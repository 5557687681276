Beacon.Control.EagleViewIntegratedExplorer = OpenLayers.Class(Beacon.Control.HoverSupport, {

    CLASS_NAME: "Beacon.Control.EagleViewIntegratedExplorer",

    clickHandler: null,

    featureHandler: null,

    initialize: function (options) {
        Beacon.Control.HoverSupport.prototype.initialize.apply(this, [options]);

        this.featureHandler = new OpenLayers.Handler.Feature(
            this,
            this.selectionLayer,
            { click: this.showFeature },
            { stopClick: true, stopDown: true }
        );

        this.clickHandler = new OpenLayers.Handler.Click(
            this,
            {click: this.click},
            {pixelTolerance: 10}
        );
    },

    setMap: function (map) {
        Beacon.Control.HoverSupport.prototype.setMap.apply(this, arguments);

        this.featureHandler.setMap(map);
        this.clickHandler.setMap(map);
    },

    activate: function () {
        if (!this.active) {
            this.clickHandler.activate();
            this.featureHandler.activate();
        }
        return Beacon.Control.HoverSupport.prototype.activate.apply(
            this, arguments
        );
    },

    deactivate: function () {
        if (this.active) {
            this.featureHandler.deactivate();
            this.clickHandler.deactivate();
        }

        return Beacon.Control.HoverSupport.prototype.deactivate.apply(
            this, arguments
        );
    },

    click: function (evt) {
        Beacon.GA.TrackEvent('EagleViewIE', 'Click');

        try {
            let point = this.map.getLonLatFromPixel(evt.xy);

            const projector = new OpenLayers.Projection('EPSG:4326');
            point.transform(this.map.projection, projector);

            const url = '/Pictometry/ViewerIntegratedExplorer.aspx?QPS=' + mapConfig.QPS + '&lat=' + point.lat + '&lon=' + point.lon;

            Beacon.Dialogs.showFromIFrame(null, 'Oblique Imagery VIewer', url);
        } catch (e) {
            Beacon.MapJS.showRetryActivity(e, 'EagleViewIntegratedExplorer.click');
        }
    },

    showFeature: function (feature) {
        Beacon.GA.TrackEvent('EagleViewIE', 'Feature');

        let geom = feature.geometry.clone();

        const projector = new OpenLayers.Projection('EPSG:4326');
        geom.transform(this.map.projection, projector);

        const center = geom.getCentroid();

        const url = '/Pictometry/ViewerIntegratedExplorer.aspx?QPS=' + mapConfig.QPS + '&lat=' + center.y + '&lon=' + center.x;

        Beacon.Dialogs.showFromIFrame(null, 'Oblique Imagery Viewer', url);
    }
});