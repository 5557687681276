Beacon.MapJS = {

    map: null,
    events: null,
    EVENT_TYPES: ["changelayer"],

    selectionLayer: null,
    bufferLayer: null,
    trackGpsLayer: null,

    layerSources: null, // { beaconLayerId:<OpenLayers.Layer> },
    spatialServers: null, // spatialServerId : <OpenLayers.Layer>

    pageUnloading: null, //set on beforeunload event - to supress errors

    distanceFactor: 1.0, // only for web mercator --- factor between map coordinate distances and ground distances

    initialMapExtent: null,

    mobileDevice: false, //set for devices < 700px (Beacon.Variables.mediumLayoutWidth)

    controls: { // references to popular controls that need some external access
        mouseNavigation: null,
        kbdNavigation: null,
        identify: null,
        selectmenu: null,
        citizeninput: null,
        mainpanel: null,
        measure: null
    },

    initOlMap: function () {

        // - moved to application.aspx for all pages
        // first yell at old browser users:
        //try {
        //    Beacon.BrowserCompatibility.Detect();
        //    Beacon.BrowserCompatibility.Notify();
        //} catch (e) {
        //    // ignore i guess
        //}

        this.initRwdElements();

        

        // Grab query params (lat/lon) that will override zooming to any features.
        function URLSearchParams2(searchString) {
            var self = this;
            self.searchString = searchString;
            self.get = function (name) {
                var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(self.searchString);
                if (results == null) {
                    return null;
                }
                else {
                    return decodeURI(results[1]) || 0;
                }
            };
            return self;
        }
        var queryString = window.location.search;
        var params = (typeof URLSearchParams === 'undefined') ? new URLSearchParams2(queryString) : new URLSearchParams(queryString);
           
        var lat = params.get("lat");
        var lon = params.get("lon");
        var hasLatLon = lat && lon;


        // set up OL map

        try {
            this.configProjections();
            this.buildMap();
        } catch (e) {
            this.showRetryActivity(e, "buildMap");
        }

        this.layerSources = {};
        this.spatialServers = {};


        // --- add OSM layers
        //try {
        //    this.addOsmLayers();
        //} catch (e) {
        //    this.showRetryActivity(e, "addOsmLayers");
        //}

        // --- add in the AGS layers
        try {
            this.addTileCacheLayers();
        } catch (e) {
            this.showRetryActivity(e, "addTileCacheLayers");
        }

        // --- add in the dynamic map layers
        try {
            this.addDynamicLayers();
        } catch (e) {
            this.showRetryActivity(e, "addDynamicLayers");

        }

        // --- create selection layer overlay
        try {
            var shouldZoomToFeaturesOnLoad = !hasLatLon
            this.addSelectionLayer(shouldZoomToFeaturesOnLoad);
        } catch (e) {
            this.showRetryActivity(e, "addSelectionLayer");

        }

        // --- create buffer layer overlay
        try {
            this.addBufferLayer();
        } catch (e) {
            this.showRetryActivity(e, "addBufferLayer");

        }

        // --- create gps tracking layer
        try {
            this.addTrackGpsLayer();
        } catch (e) {
            this.showRetryActivity(e, "addTrackGpsLayer");

        }

        // --- toolbar ----
        try {
            this.addToolsAndControls();
        } catch (e) {
            this.showRetryActivity(e, "addToolsAndControls");

        }



        try {
            // setup delayed event to post current extent to server
            var mapExtDly = new Beacon.DelayedFunction(function () {
                var ext = this.getExtent();
                var res = this.getResolution();
                Beacon.API.UpdateMapExtent(ext, res);
            }, this.map, 1013);

            this.map.events.register("moveend", mapExtDly, mapExtDly.delayedInvoke);
        } catch (e) {
            this.showRetryActivity(e, "setup extent tracking");

        }


        try {
            // reset pagetype-specific tools
            this.controls.identify.updateOnLayerChange();
            this.controls.mainpanel.redraw();

            //configure toc, listens to map drawing activity
            Beacon.TOC.onPageLoad();
        } catch (e) {
            this.showRetryActivity(e, "toc page load");

        }

        try {
            //wire up scale change events 
            this.map.events.register("zoomend", this, this.onZoomEnd);

            // wire up TOC events
            Beacon.TOC.events.register("refreshServer", this, this.onLayerNeedsRefresh);
        } catch (e) {
            this.showRetryActivity(e, "wire up events");

        }


        try {

            // Initial zoom request:
            var initExtentStpl = new OpenLayers.Bounds(
            mapConfig.CurrentExtent.minx,
            mapConfig.CurrentExtent.miny,
            mapConfig.CurrentExtent.maxx,
            mapConfig.CurrentExtent.maxy);

            this.map.zoomToExtent(initExtentStpl, false);




            

            if (hasLatLon) {
                var pt = new OpenLayers.LonLat(lon, lat);
                var prj = new OpenLayers.Projection("EPSG:4326");
                pt.transform(prj, this.map.getProjectionObject());

                if (this.map.getScale() > 1200) {
                    this.map.zoomToScale(1200);
                }

                if (this.map.getMaxExtent().containsLonLat(pt)) {
                    this.map.panTo(pt);
                }
            }




            this.onZoomEnd();
        } catch (e) {
            this.showRetryActivity(e, "initial zoom");

        }




        // overview map
        try {
            this.addOverviewMap();
        } catch (e) {
            this.showRetryActivity(e, "addOverviewMap");
        }

        this.addPanZoomOverlay();

        // turn off stuff when page is about to go away
        var that = this;
        $(window).bind('beforeunload', function () {
            that.pageUnloading = true;
            that.hideActivity();
        });


        // TOC
        try {
            Beacon.TOC.initUiStateOnPageload();
        } catch (e) {
            this.showRetryActivity(e, "initUiStateOnPageload");
        }


    },

    initRwdElements: function () {

        //console.info("doc: " + $(document).width());
        //console.info("html: " + $("html").width());
        //console.info("body: " + $("body").width());
        //console.info("inner: " + window.innerWidth);

        //this.mobileDevice = window.innerWidth < Beacon.Variables.mediumLayoutWidth;
        this.mobileDevice = !Beacon.Variables.isDesktopViewport();


        if (this.mobileDevice) {
            $("body").addClass("map-mobile-mode");
        } else {
            $("body").addClass("map-desktop-mode");
        }
    },

    configProjections: function () {
        for (var i = 0; i < mapConfig.Projections.length; i++) {
            var prj = mapConfig.Projections[i];
            proj4.defs('EPSG:' + prj.SRID, prj.WKT);
        }
    },

    buildMap: function () {

        //OpenLayers.ProxyHost = "proxy.ashx?";
        OpenLayers.IMAGE_RELOAD_ATTEMPTS = 1;
        OpenLayers.DOTS_PER_INCH = 96;
        OpenLayers.ImgPath = '/Styles/OL/img/';
        this.distanceFactor = 1.0;

        this.events = new OpenLayers.Events(this,
                                            null,
                                            this.EVENT_TYPES,
                                            false,
                                            {});


        this.initialMapExtent = new OpenLayers.Bounds(
            mapConfig.MaxExtent.minx,
            mapConfig.MaxExtent.miny,
            mapConfig.MaxExtent.maxx,
            mapConfig.MaxExtent.maxy);

        var biggerExtent = this.initialMapExtent.scale(5.0);

        var stpl = new OpenLayers.Projection("EPSG:" + mapConfig.SRID);

        // set up the default controls

        this.controls.kbdNavigation = new OpenLayers.Control.KeyboardDefaults();

        this.controls.mouseNavigation = new OpenLayers.Control.Navigation({
            mouseWheelOptions: {
                interval: 333,
                cumulative: false
            },
            dragPanOptions: {
                enableKinetic: true
            }
        });

        var mapOptions = {
            projection: stpl,
            units: mapConfig.Units,
            fractionalZoom: false,
            displayProjection: stpl,
            maxExtent: biggerExtent,
            //restrictedExtent: maxExtentStpl,
            allOverlays: true,
            maxResolution: this.initialMapExtent.getWidth() / 250.0,
            minResolution: 0.2,
            fallThrough: false,
            theme: null, 
            controls: [this.controls.mouseNavigation, this.controls.kbdNavigation]
        };

        this.map = new OpenLayers.Map('olMap', mapOptions);

        if (stpl.proj.sphere) { // web mercator or other generic sphere-based proj

            var mapCentroid = this.initialMapExtent.getCenterLonLat().clone();

            var prjLL = new OpenLayers.Projection("EPSG:4326"); //WGS84
            mapCentroid.transform(this.map.projection, prjLL);

            this.distanceFactor = Math.cos(OpenLayers.Util.rad(mapCentroid.lat));

        }

        if (!this.mobileDevice) {
            // add lower overlay padding for popup placement control:
            this.map.paddingForPopups = new OpenLayers.Bounds(15,224,15,15);
        }
    },


    //addOsmLayers: function () {

    //    // from: http://developer.mapquest.com/web/products/open/map

    //    var osmUrls = ["http://otile1.mqcdn.com/tiles/1.0.0/map/${z}/${x}/${y}.png",
    //                   "http://otile2.mqcdn.com/tiles/1.0.0/map/${z}/${x}/${y}.png",
    //                   "http://otile3.mqcdn.com/tiles/1.0.0/map/${z}/${x}/${y}.png",
    //                   "http://otile4.mqcdn.com/tiles/1.0.0/map/${z}/${x}/${y}.png"];

    //    for (var i = 0; i < mapConfig.OsmTileLayers.length; i++) {
    //        var cfg = mapConfig.OsmTileLayers[i];

    //        var osmLayer = new OpenLayers.Layer.OSM("OSM" + i, osmUrls);

    //        osmLayer.beaconServerId = cfg.beaconServerId;
    //        osmLayer.beaconLayerIds = cfg.beaconLayerIds;
    //        osmLayer.singleLayerSource = true;
    //        osmLayer.transitionEffect = 'resize';
    //        osmLayer.buffer = 1;
    //        osmLayer.isBaseLayer = false;
    //        osmLayer.visibility = cfg.visibility;
    //        osmLayer.opacity = cfg.opacity;

    //        this.spatialServers[cfg.beaconServerId] = osmLayer;

    //        for (var j = 0; j < cfg.beaconLayerIds.length; j++) {
    //            this.layerSources[cfg.beaconLayerIds[j]] = osmLayer;
    //        }

    //        this.map.addLayer(osmLayer);

    //        osmLayer.events.on({
    //            "loadstart": function () { Beacon.TOC.onLayerLoadStart(this.beaconServerId); },
    //            "loadend": function () { Beacon.TOC.onLayerLoadEnd(this.beaconServerId); },
    //            "loadcancel": function () { Beacon.TOC.onLayerLoadEnd(this.beaconServerId); },
    //            "visibilitychanged": function () { Beacon.TOC.onLayerVisibilityChanged(this.beaconServerId, this.getVisibility()); },
    //            scope: osmLayer
    //        });


    //    }

    //},

    addTileCacheLayers: function () {

        for (var i = 0; i < mapConfig.AgsTileLayers.length; i++) {
            var cfg = mapConfig.AgsTileLayers[i];

            var agsLayer = new Beacon.Layer.AgsCache("AGS" + i, cfg.url, {
                beaconServerId: cfg.beaconServerId,
                beaconLayerIds: cfg.beaconLayerIds,
                singleLayerSource: true,
                transitionEffect: 'resize',
                buffer: 0,
                isBaseLayer: false,
                visibility: cfg.visibility,
                resolutions: cfg.resolutions,
                opacity: cfg.opacity,
                tileSize: new OpenLayers.Size(cfg.tileSize, cfg.tileSize),
                tileOrigin: new OpenLayers.LonLat(cfg.tileOriginX, cfg.tileOriginY),
                nativeLevels: cfg.nativeLevels,
                enhancedUrl: cfg.enhancedUrl
            });

            this.spatialServers[cfg.beaconServerId] = agsLayer;

            for (var j = 0; j < cfg.beaconLayerIds.length; j++) {
                this.layerSources[cfg.beaconLayerIds[j]] = agsLayer;
            }

            this.map.addLayer(agsLayer);

            agsLayer.events.on({
                "loadstart": function () { Beacon.TOC.onLayerLoadStart(this.beaconServerId); },
                "loadend": function () { Beacon.TOC.onLayerLoadEnd(this.beaconServerId); },
                "loadcancel": function () { Beacon.TOC.onLayerLoadEnd(this.beaconServerId); },
                "visibilitychanged": function () {
                    Beacon.TOC.onLayerVisibilityChanged(this.beaconServerId, this.getVisibility());
                    if (!this.getVisibility()) {
                        this.clearGrid(); // clear loaded tiles - fixes wacky issue in chrome
                    }
                },
                scope: agsLayer
            });


        }

    },

    addDynamicLayers: function () {

        var baseResolutions = null; // use the first AGS tile cache as standard resolution array
        if (this.map.baseLayer) {
            baseResolutions = this.map.baseLayer.resolutions;
        }

        for (var i = 0; i < mapConfig.BeaconLayers.length; i++) {
            var cfg = mapConfig.BeaconLayers[i];

            var beaconLayer = new Beacon.Layer.Beacon("Beacon" + i, '/API/RenderMap.ashx', {
                beaconServerId: cfg.beaconServerId,
                beaconLayerIds: cfg.beaconLayerIds,
                serverType: cfg.serverType,
                drawable: cfg.drawable,
                singleLayerSource: false,
                transitionEffect: 'resize',
                visibility: false,
                alpha: true,
                isBaseLayer: false,
                singleTile: true,
                resolutions: baseResolutions,
                ratio: 1.2,
                buffer: 0,
                opacity: cfg.opacity,
                async: false
            });

            this.spatialServers[cfg.beaconServerId] = beaconLayer;

            for (var j = 0; j < cfg.beaconLayerIds.length; j++) {
                this.layerSources[cfg.beaconLayerIds[j]] = beaconLayer;
            }

            this.map.addLayer(beaconLayer);

            beaconLayer.events.on({
                "loadstart": function () { Beacon.TOC.onLayerLoadStart(this.beaconServerId) },
                "loadend": function () { Beacon.TOC.onLayerLoadEnd(this.beaconServerId) },
                "loadcancel": function () { Beacon.TOC.onLayerLoadEnd(this.beaconServerId) },
                //"visibilitychanged": function () { Beacon.TOC.onLayerVisibilityChanged(cfg.beaconServerId, true) },
                scope: beaconLayer
            });

        }

    },

    addSelectionLayer: function (zoomToFeaturesOnLoad) {

        this.selectionLayer = new Beacon.Layer.SelectionLayer(mapConfig.KeyValue, {
            strategies: [
            //new Beacon.Strategy.PolygonSimplifier() - no perf benefit
            ],
            symbology: {
                selection: mapConfig.SelectionSymbology,
                key: mapConfig.KeySymbology,
                hover: mapConfig.HoverSymbology,
                buffer: mapConfig.BufferSymbology
            }
        }, zoomToFeaturesOnLoad);


        this.map.addLayer(this.selectionLayer);
    },

    addBufferLayer: function () {

        var rgba = mapConfig.BufferSymbology;
        this.bufferLayer = new OpenLayers.Layer.Vector("buffer layer", {
            visibility: false,
            style: {
                fillColor: 'rgb(' + rgba.R + ',' + rgba.G + ',' + rgba.B + ')',
                strokeColor: 'rgb(' + rgba.R + ',' + rgba.G + ',' + rgba.B + ')',
                fillOpacity: rgba.A,
                strokeOpacity: 0.9,
                strokeWidth: 2,
                graphicZIndex: 3
            }
        });

        this.map.addLayer(this.bufferLayer);
    },

    addTrackGpsLayer: function () {
        this.trackGpsLayer = new Beacon.Layer.TrackGPS({});
        this.map.addLayer(this.trackGpsLayer);
    },

    addToolsAndControls: function () {


        // onscreen controls:
        this.map.addControls([
            new Beacon.Control.Scalebar({ displayClass: 'bScalebar' }),
            new Beacon.Control.EsriLogo({ displayClass: 'bEsriLogo' })
        ]);

        if (mapConfig.Permissions.MapCoordinateDisplay) {
            this.map.addControl(new Beacon.Control.DisplayXY({ displayClass: 'bControlDisplayXY' }));
        }

        var panel = new Beacon.Control.Panel(
                {
                    displayClass: 'mapToolbar',
                    position: new OpenLayers.Pixel(60, 8)
                });

        this.controls.mainpanel = panel;


        this.controls.identify = new Beacon.Control.Identify({ title: "Identify", displayClass: 'InformationCircle', selectionLayer: this.selectionLayer });
        panel.addControls(this.controls.identify);

        //this.controls.citizeninput = new Beacon.Control.CitizenInput({ title: "Citizen Input", displayClass: 'citizeninput' });
        //panel.addControls(this.controls.citizeninput);

        if (!this.mobileDevice) {
            var selectToolPanel = new Beacon.Control.Panel({ displayClass: 'mapToolbar', saveState: true });
            selectToolPanel.addControls([
                    new Beacon.Control.Select({ mode: 'normal', title: "Select by click / rectangle", displayClass: 'SelectByRect', selectionLayer: this.selectionLayer }),
                    new Beacon.Control.Select({ mode: 'polygon', enableHovering: false, title: "Select by polygon", displayClass: 'SelectByPoly', selectionLayer: this.selectionLayer }),
                    new Beacon.Control.Select({ mode: 'line', enableHovering: false, title: "Select by line", displayClass: 'SelectByLine', selectionLayer: this.selectionLayer }),
                    new Beacon.Control.Select({ mode: 'freehand', enableHovering: false, title: "Select by freehand drawing", displayClass: 'SelectByFreehand', selectionLayer: this.selectionLayer }),
                    new Beacon.Control.Select({ mode: 'toggle', enableHovering: false, title: "Toggle selected item by clicking", displayClass: 'SelectionArrow', selectionLayer: this.selectionLayer })
            ]);
            selectToolPanel.defaultControl = selectToolPanel.controls[0];
            var selectTools = new Beacon.Control.ToolWithAuxControl({
                title: 'Selection Tools',
                displayClass: 'select',
                useActiveIcon: true,
                useExecuteCompleted: false,
                contentControl: selectToolPanel
            });
            panel.addControls(selectTools)
        }

        // -- nav history

        var nav = new OpenLayers.Control.NavigationHistory({
            previousOptions: { title: "Zoom previous", displayClass: "ZoomPrevious" },
            nextOptions: { title: "Zoom next", displayClass: "ZoomNext" }
        });

        // parent control must be added to the map
        this.map.addControl(nav);

        //        panel.addControls(nav.previous);
        //        panel.addControls(nav.next);


        if (!this.mobileDevice) {

            var zoomToolPanel = new Beacon.Control.Panel({ displayClass: 'mapToolbar', saveState: true });
            zoomToolPanel.addControls([
                new OpenLayers.Control.ZoomBox({ title: "Zoom in", out: false, alwaysZoom: true, displayClass: 'ZoomIn' }),
                new OpenLayers.Control.ZoomBox({ title: "Zoom out", out: true, alwaysZoom: true, displayClass: 'ZoomOut' }),
                new Beacon.Control.ZoomToMaxExtent({ title: "Full extent", displayClass: 'ZoomExtent', maxExtent: this.initialMapExtent }),
                new Beacon.Control.ZoomToSelection(this.selectionLayer, { title: "Zoom to selection", displayClass: "ZoomSelection" }),
                nav.previous,
                nav.next
            ]);

            zoomToolPanel.defaultControl = zoomToolPanel.controls[0];
            var zoomTools = new Beacon.Control.ToolWithAuxControl({
                title: 'Zoom Tools',
                displayClass: 'zoomPanel',
                useActiveIcon: true,
                useExecuteCompleted: false,
                contentControl: zoomToolPanel
            });
            panel.addControls(zoomTools)


            panel.addControls(new OpenLayers.Control({
                title: "Default mouse mode",
                displayClass: 'PanHand'
            }));
        }

        panel.addControls(new Beacon.Control.ClearMap(this.selectionLayer, { title: "Clear Selection", displayClass: 'Eraser' }));

        if (!this.mobileDevice) {

            var bufferTool;
            if (mapConfig.Permissions.BufferTool) {
                var bufferControl = new Beacon.Control.SpatialSelection({ displayClass: 'spatialselectionPane' })
                bufferTool = new Beacon.Control.ToolWithAuxControl({
                    title: 'Spatial selection tool',
                    displayClass: 'Buffer',
                    useActiveIcon: false,
                    useExecuteCompleted: true,
                    contentControl: bufferControl
                });
                panel.addControls(bufferTool);
            }
        }

        if (mapConfig.Permissions.Streetview) panel.addControls(new Beacon.Control.GoogleStreetview({ title: "Google Streetview - Click on a road", displayClass: 'StreetView', autoActivate: false }));

        if (mapConfig.Permissions.HasCyclomediaStreetMap) panel.addControls(new Beacon.Control.CyclomediaStreetview({ title: "Cyclomedia Streetview - Click on a road", displayClass: 'CyclomediaStreetMaps', autoActivate: false }));
        
        if (mapConfig.Permissions.GoogleEarthKml) panel.addControls(new Beacon.Control.GoogleEarthKml({ title: "Google Earth KML", displayClass: 'google-earth-icon' }));

        if (mapConfig.Permissions.Bing) panel.addControls(new Beacon.Control.BingMaps({ title: "Bing Maps", displayClass: 'Camera', selectionLayer: this.selectionLayer }));

        if (mapConfig.Permissions.Pictometry && !this.mobileDevice) panel.addControls(new Beacon.Control.Pictometry({ title: "Oblique Imagery", displayClass: 'Pictometry', selectionLayer: this.selectionLayer }));
        if (mapConfig.Permissions.PictometryIPA) panel.addControls(new Beacon.Control.PictometryIPA({ title: "Oblique Imagery IPA", displayClass: 'Pictometry', selectionLayer: this.selectionLayer }));
        if (mapConfig.Permissions.EagleViewIntegratedExplorer) {
            panel.addControls(
                new Beacon.Control.EagleViewIntegratedExplorer({
                    title: 'Oblique Imagery EagleView Integrated Explorer',
                    displayClass: 'Pictometry',
                    selectionLayer: this.selectionLayer
                })
            );
        }

        this.controls.measure = new Beacon.Control.Measure({ title: 'Measure', displayClass: 'measurePane' });
        var measureTool = new Beacon.Control.ToolWithAuxControl({
            title: 'Measure',
            displayClass: 'Measure',
            useActiveIcon: false,
            useExecuteCompleted: false,
            contentControl: this.controls.measure
        });
        panel.addControls(measureTool);

        if (!this.mobileDevice) {

            if (mapConfig.Permissions.MapZoomXYTool) {
                var zoomXyControl = new Beacon.Control.ZoomToXY({ title: 'Zoom to XY', displayClass: 'zoomtoxypPane' });
                var zoomXyTool = new Beacon.Control.ToolWithAuxControl({
                    title: 'Zoom to XY',
                    displayClass: 'ZoomXY',
                    useActiveIcon: false,
                    useExecuteCompleted: true,
                    contentControl: zoomXyControl
                });
                panel.addControls(zoomXyTool);
            }

            var mapMarkupControl = new Beacon.Control.MapMarkup({ displayClass: 'mapmarkup' });
            var mapMarkupTool = new Beacon.Control.ToolWithAuxControl({
                title: 'Map Markup Tools',
                displayClass: 'Pencil',
                useActiveIcon: false,
                useExecuteCompleted: false,
                contentControl: mapMarkupControl
            });
            panel.addControls(mapMarkupTool);

            if (mapConfig.Permissions.CogoMapTools) {
                var cogoControl = new Beacon.Control.CogoTool({ displayClass: 'mapcogo' });
                var cogoTool = new Beacon.Control.ToolWithAuxControl({
                    title: 'COGO Tools',
                    displayClass: 'cogo',
                    useActiveIcon: false,
                    useExecuteCompleted: false,
                    contentControl: cogoControl
                });
                panel.addControls(cogoTool);
            }
        }

        if (!this.mobileDevice) {

            if (mapConfig.Permissions.ExtractMapData) {
                panel.addControls(new Beacon.Control.DataExtract({ title: "Data Extract", displayClass: 'ExtractData' }));
            }
        }
        //panel.addControls(new Beacon.Control.ZoomToXY({ title: 'Zoom to XY', displayClass: 'zoomtoxy' }));
        //panel.addControls(new OpenLayers.Control.Button({ title: "Toggle map size", displayClass: 'Resize', trigger: Beacon.MapJS.maximizeMap }));


        panel.addControls(new Beacon.Control.FullScreenToggle(this.selectionLayer, { title: "Maximize Map Display", displayClass: 'Resize' }));

        // print buttons
        if (!this.mobileDevice) {
            panel.addControls(new OpenLayers.Control.Button({ title: "Print Setup", displayClass: 'PrintPreview', trigger: Beacon.MapJS.printMapSetup }));
            panel.addControls(new OpenLayers.Control.Button({ title: "Print", displayClass: 'Printer', trigger: Beacon.MapJS.printMap }));

            //panel.addControls(new OpenLayers.Control.Button({ title: "Download Map Image", displayClass: 'MapExport', trigger: Beacon.MapJS.showExportMapOptions }));

            var downloadPanel = new Beacon.Control.Panel({ displayClass: 'mapToolbar', saveState: true });
            downloadPanel.addControls([
                new OpenLayers.Control.Button({ title: "Download Map Image as PNG", displayClass: 'DownloadMapPNG', trigger: function () { downloadMapTools.deactivate(); Beacon.MapJS.exportMap('png'); } }),
                new OpenLayers.Control.Button({ title: "Download Map Image as JPG", displayClass: 'DownloadMapJPG', trigger: function () { downloadMapTools.deactivate(); Beacon.MapJS.exportMap('jpg'); } })
            ]);

            downloadPanel.defaultControl = downloadPanel.controls[0];
            var downloadMapTools = new Beacon.Control.ToolWithAuxControl({
                title: 'Download Map Image',
                displayClass: 'MapExport',
                useExecuteCompleted: false,
                contentControl: downloadPanel
            });
            panel.addControls(downloadMapTools);
        }

        //new Beacon.Control.SpatialSelection({ title: "Spatial selection & buffering", displayClass: 'spatialselection' })

        panel.defaultControl = panel.controls[0];

        this.map.addControl(panel);


        // configure dropdown tools - need to happen here after all controls have been
        // properly init'd on the toolbar

        if (!this.mobileDevice) {
            zoomTools.createPanel();
            selectTools.createPanel();
            if (bufferTool) { bufferTool.createPanel(); }
            mapMarkupTool.createPanel();
            if (mapConfig.Permissions.MapZoomXYTool) {
                zoomXyTool.createPanel();
            }
            if (cogoTool) { cogoTool.createPanel(); }
            downloadMapTools.createPanel();
        }
        measureTool.createPanel();
    },

    addOverviewMap: function () {
        var ext = new OpenLayers.Bounds(mapConfig.OverviewExtent.minx,
                                            mapConfig.OverviewExtent.miny,
                                            mapConfig.OverviewExtent.maxx,
                                            mapConfig.OverviewExtent.maxy);

        var ovLayer = new OpenLayers.Layer.Image("Overview",
                mapConfig.OverviewUrl,
                ext,
                new OpenLayers.Size(160, 160)
            );

        var ovMap = new Beacon.Control.OverviewMap({
            layers: [ovLayer],
            autoPan: false,
            size: new OpenLayers.Size(160, 160),
            displayClass: 'bControlOverviewMap',
            div: document.getElementById("overviewJsMapPane"),
            mapOptions: {
                //allOverlays: false,
                //fractionalZoom: false,
                projection: this.map.projection,
                units: this.map.units,
                numZoomLevels: 1,
                displayProjection: this.map.displayProjection,
                minExtent: ext,
                maxExtent: ext,
                restrictedExtent: ext,
                theme: null
            }
        });
        this.map.addControl(ovMap);
    },

    addPanZoomOverlay: function () {

        var map = this.map;
        var self = this;

        $("#btnZoomIn").click(function () {
            map.zoomIn();
        });

        $("#btnZoomOut").click(function () {
            map.zoomOut();
        });

        $("#btnZoomExtent").click(function () {
            //map.zoomToMaxExtent();
            map.zoomToExtent(self.initialMapExtent,false);
        });


    },

    setDefaultLayerToActive: function (cb) {
        var defaultLayer;
        var findDefaultLayer = function (layerElement) {

            if (layerElement.LayerId) {
                // console.log(layerElement.DefaultLayer);
                if (layerElement.DefaultLayer == true) {
                    defaultLayer = layerElement.LayerId;
                }
            } else if (layerElement.Layers) {
                //   console.log(layerElement.Layers);
                //it's a group layer
                for (var i = 0; i < layerElement.Layers.length; i++) {
                    findDefaultLayer(layerElement.Layers[i]);
                }
            }
            else {
                for (var i = 0; i < layerElement.length; i++) {
                    //      console.log(layerElement);
                    findDefaultLayer(layerElement[i]);
                }
            }

        }

        findDefaultLayer(mapConfig.LayerView);
        Beacon.MapJS.changeActiveLayer(defaultLayer, cb);

    },
    changeActiveLayer: function (layerId, cb) {

        try {
            this.showActivity("Changing layers");

            Beacon.TOC.changeActiveLayer(layerId);

            mapConfig.LayerId = layerId;
            this.selectionLayer.clearAllPopups();
            this.selectionLayer.clearSelection();

            Beacon.GA.TrackEvent('ChangeLayers', 'Click');

        } catch (e) {
            Beacon.MapJS.showRetryActivity(e, "changeActiveLayer");

        }

        Beacon.API.GetTabs(layerId,
            function (tabs) {

                try {

                    mapConfig.Tabs = tabs;

                    Beacon.Tabs.generateTabStrip(0, null);

                    //this.updateControlsForNewPagetype();
                    this.controls.identify.updateOnLayerChange();
                    this.controls.mainpanel.redraw();

                    this.hideActivity();

                    this.events.triggerEvent("changelayer", {
                        layerId: layerId
                    });

                } catch (e) {
                    Beacon.MapJS.showRetryActivity(e, "changeActiveLayer.callback");

                } finally {
                    if (cb) cb();
                }
            },
            function () {
                Beacon.MapJS.showRetryActivity();
                if (cb) cb();
            },
            this, null);

    },


    onZoomEnd: function () {
        Beacon.TOC.onZoomEnd(this.map.getResolution());
        Beacon.TOC.updateLegend();
    },

    onLayerNeedsRefresh: function (e) {

        var multiLayerSource = false;
        var lyrSrc = this.spatialServers[e.serverId];

        if (lyrSrc.CLASS_NAME == "Beacon.Layer.Beacon") {
            multiLayerSource = true;
        } else if (lyrSrc.CLASS_NAME == "Beacon.Layer.AgsCache") {
            multiLayerSource = false;
        }

        if (multiLayerSource) {
            lyrSrc.redraw(true);
        }

        if (!multiLayerSource) {
            lyrSrc.setVisibility(e.visible);
        }

    },

    getBeaconLayer: function (layerId) {
        return _.first(_.where(this.getAllBeaconLayers(), { LayerId: layerId }));
    },

    getAllBeaconLayers: function () {
        var layers = [];

        // flatten the grouped layer view model

        _.forIn(mapConfig.LayerView, function (o1) {
            if (o1.LayerName) {
                layers.push(o1);
            }
            else {
                _.forIn(o1.Layers, function (o2) {
                    layers.push(o2);
                });
            }
        });

        return layers;

    },

    getLayersGroupedAndFiltered: function (layerFilter) {

        // filters and returns the LayerView - all objects are cloned

        var lyrs = [];
        _.forIn(mapConfig.LayerView, function (lyr) {
            // top level layers & groups
            if (lyr.GroupName) {
                // group layer:
                var qSubLyrs = $.map(lyr.Layers, function (slyr) {
                    if (!layerFilter || layerFilter(slyr)) {
                        return _.clone(slyr);
                    } else {
                        return null;
                    }
                });
                if (qSubLyrs.length > 0) { //dont add a group if there are no sub layers visible
                    lyrs.push({ // create a new group object, because we mess with the layers list:
                        GroupName: lyr.GroupName,
                        Layers: qSubLyrs
                    });
                }
            } else {
                // top level layer
                if (!layerFilter || layerFilter(lyr)) {
                    lyrs.push(_.clone(lyr));
                }
            }
        });

        return lyrs;
    },

    enableDynamicLayers: function () {

        for (var i = 0; i < this.map.layers.length; i++) {
            var lyr = this.map.layers[i];
            if (lyr.CLASS_NAME == "Beacon.Layer.Beacon") {
                lyr.setVisibility(true);
            }
        }
    },

    printMap: function () {

        Beacon.PrintDialog.print();
    },
    
    exportMap: function (format) {

        var url = '/API/' + Beacon.Tabs.getUrl(mapConfig.LayerId,
                                    mapConfig.PageTypeId,
                                    mapConfig.PageId,
                                    Beacon.MapJS.selectionLayer.keyValue,
                                    'DownloadMap.ashx') +
                                    "&bbox=" + this.map.getExtent().toBBOX(0) +
                                    "&w=" + this.map.getSize().w +
                                    "&h=" + this.map.getSize().h +
                                    "&Q=" + (new Date()).getTime() +
                                    "&format=" + format;

        window.open(url, "_blank");

        Beacon.GA.TrackEvent('ExportMap', 'Click');

    },
    //    printMap2: function () {

    //        var printWin = window.open();
    //        printWin.document.write("<div id='loading'><img src='/Images/ajax-loader-big.gif'><span style='font-family: Verdana; font-size: 24pt; font-weight:bold;'>Printing...</span></div>");

    //        Beacon.API.UpdateMapExtent(
    //            this.map.getExtent(),
    //            this.map.getResolution(),
    //            function () {
    //                var url = Beacon.Tabs.getUrl(mapConfig.LayerId,
    //                                         mapConfig.PageTypeId,
    //                                         mapConfig.PageId,
    //                                         Beacon.MapJS.selectionLayer.keyValue,
    //                                         'RenderPdf.aspx') + "&Direct=1";

    //                printWin.document.write("Your browser has blocked the automatic display of this document.  <a href='" + url + "'>Click</a> to view it now.");
    //                printWin.document.write("<script>document.getElementById('loading').style.display='none';</script>");

    //                // this doesn't work in IE unless a user has trusted the site
    //                printWin.location.href = url;

    //            },
    //            function () {
    //                Beacon.MapJS.showRetryActivity();
    //            },
    //            this);
    //    },

    printMapSetup: function () {

        Beacon.PrintDialog.printSetup();

        //var urlCurrent = Beacon.Tabs.getUrl(mapConfig.LayerId,
        //                                    mapConfig.PageTypeId,
        //                                    mapConfig.PageId,
        //                                    Beacon.MapJS.selectionLayer.keyValue,
        //                                    '~').substring(3);

        //var urlPrintSetup = Beacon.Tabs.getUrl(mapConfig.LayerId,
        //                             17,
        //                             0,
        //                             Beacon.MapJS.selectionLayer.keyValue);

        //Beacon.API.SetPrintUrl(
        //    urlCurrent, //window.location.search.substring(1), //need to update key here too
        //    function () {
        //        window.location = urlPrintSetup;
        //    },
        //    function () {
        //        Beacon.MapJS.showRetryActivity();
        //    },
        //    this);

        //Beacon.GA.TrackEvent('PrintMapSetup', 'Click');

    },

    showActivity: function (msg, extra) {
        $("#activityIndicator").text(msg);
        if (!extra) extra = '';
        $("#activityIndicator").attr('title', extra);
        //$("#activityIndicator").animate({ 'top': '0px' }, 1000, 'swing');
        $("#activityIndicator").fadeIn();
    },

    hideActivity: function () {
        // $("#activityIndicator").stop().animate({ 'top': '-40px' }, 500, 'swing');
        $("#activityIndicator").stop().fadeOut();
    },

    showRetryActivity: function (ex, message) {

        if (this.pageUnloading) { return; }

        $("#activityIndicator").html("Communications error <a href='#' id='actIndRetry'>RETRY</a>");
        $("#activityIndicator").attr('title', 'A problem communicating with the server has prevented this operation from working.  Click retry to reload the page and try again.');
        //$("#activityIndicator").animate({ 'top': '0px' }, 1000, 'swing');
        $("#activityIndicator").fadeIn();

        $("#actIndRetry").click(function () {
            window.location.reload();
        });

        Beacon.MapJS.logException(ex, message);

    },

    logException: function (ex, message) {
        if (ex) {
            var st = printStackTrace({ e: ex });
            var stacktrace = st.join("\n");
            console.error(stacktrace);
            Beacon.API.LogJsError(message, stacktrace);
        }
    },

    zoomToFeatureExtent: function (ext) {
        if (ext) {
            var zoomExt = ext.scale(1.5);
            this.map.zoomToExtent(zoomExt, false);
        }
    },

    forceTileCacheRedraw: function () {
        for (var i = 0; i < this.map.layers.length; i++) {
            var lyr = this.map.layers[i];
            if (lyr.CLASS_NAME == "Beacon.Layer.AgsCache") {
                lyr.forceRedraw();
            }
        }
    },

    CLASS_NAME: "Beacon.MapJS"

};

