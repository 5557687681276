Beacon.Control.ZoomToXY = OpenLayers.Class(OpenLayers.Control, {

    // type: OpenLayers.Control.TYPE_TOOL,
    //panel: null,
    sink: null,
    btnOk: null,
    btnZoomToXYClear: null,
    // btnClose: null,
    ddl: null,
    xLabel: null,
    yLabel: null,
    lastSrid: null,
    btnGps: null,
    lastPosition: null,
    savedSRID: null,

    executeCompleted: null,
    executeCompletedScope: null,

    html: null,

    pinLayer: null,

    initialize: function (options) {
        OpenLayers.Control.prototype.initialize.apply(this, [options]);

        //this.panel = $("#ZoomToXYPanel");
        //this.btnClose = $("#btnHideZoomToXyPanel")[0];
        this.btnOk = $("#btnZoomToXY")[0];
        this.btnZoomToXYClear = $("#btnZoomToXYClear")[0];
        this.ddl = $("#ddlZoomToXyCoordSys")[0];
        this.xLabel = $('#xLabel')[0];
        this.yLabel = $('#yLabel')[0];
        //this.btnGps = $("#btnZoomToGps")[0];

        //OpenLayers.Event.observe(this.btnClose, "click", OpenLayers.Function.bindAsEventListener(this.hide, this));


        this.html = $('#ZoomToXYPanel').detach();


        


    },

    setMap: function (map) {
        OpenLayers.Control.prototype.setMap.apply(this, arguments);

        // add pin layer
        this.pinLayer = new OpenLayers.Layer.Vector({
            visibility: true
        });

        map.addLayer(this.pinLayer);

        this.pinLayer.setVisibility(true);
    },

    draw: function () {
        OpenLayers.Control.prototype.draw.apply(this, arguments);

        if (!this.element) {
            this.element = document.createElement('div');
            this.element.id = this.id + '_frame';
            OpenLayers.Element.addClass(this.element, this.displayClass + 'Frame');

            //re-attach the div we detached in init
            $(this.element).append(this.html);
            this.html.show();

            this.populate();
        }


        this.div.appendChild(this.element);


        //prevent clicks from hitting the map 
        this.sink = new Beacon.Control.EventSink();
        this.sink.register(this.div);

        this.savedSRID = this.loadSRID();

        return this.div;

    },

    activate: function () {
        var r = OpenLayers.Control.prototype.activate.apply(this);
        if (r) {

            //if (navigator.geolocation) {
            //    $(this.btnGps).show();
            //} else {
            //    $(this.btnGps).hide();
            //}


            //hack: disable the keyboard controls
            //var kbdCtrl = this.map.getControlsByClass("OpenLayers.Control.KeyboardDefaults");
            //if (kbdCtrl.length) kbdCtrl[0].deactivate();
            Beacon.MapJS.controls.kbdNavigation.deactivate();

            OpenLayers.Event.observe(this.btnOk, "click", OpenLayers.Function.bindAsEventListener(this.clickOk, this));
            OpenLayers.Event.observe(this.btnZoomToXYClear, "click", OpenLayers.Function.bindAsEventListener(this.clickClear, this));
            OpenLayers.Event.observe(this.ddl, "change", OpenLayers.Function.bindAsEventListener(this.sridChanged, this));
            //OpenLayers.Event.observe(this.btnGps, "click", OpenLayers.Function.bindAsEventListener(this.clickUseGps, this));

            //$(this.btnGps).hide();
            this.lastSrid = this.ddl.value;

            if (this.savedSRID) {
                //$("#ddlZoomToXyCoordSys").val(this.savedSRID);
                for (var x = 0; x < this.ddl.options.length;x++) {
                    if (this.ddl.options[x].text === this.savedSRID) {
                        this.ddl.selectedIndex = x;
                        break;
                    }
                }
                
                this.sridChanged();
            }
            else {
                this.ddl.selectedIndex = 0; // should be map units / prj

                var pt = this.map.getCenter();

                $("#txtXpos").val(pt.lon.format('0.00'));
                $("#txtYpos").val(pt.lat.format('0.00'));
            }

            $("BODY").addClass('disableTextSelection');
        }
        return r;
    },

    deactivate: function () {

        var r = OpenLayers.Control.prototype.deactivate.apply(this);

        if (r) {
            //this.hide();

            OpenLayers.Event.stopObservingElement(this.btnOk);
            OpenLayers.Event.stopObservingElement(this.btnZoomToXYClear);
            OpenLayers.Event.stopObservingElement(this.ddl);
            //OpenLayers.Event.stopObservingElement(this.btnGps);

            $("BODY").removeClass('disableTextSelection');

            //hack: re-enable the keyboard controls
            //var kbdCtrl = this.map.getControlsByClass("OpenLayers.Control.KeyboardDefaults");
            //if (kbdCtrl.length) kbdCtrl[0].activate();
            Beacon.MapJS.controls.kbdNavigation.activate();
        }
        return r;

    },




    populate: function () {
        for (var i = 0, len = mapConfig.Projections.length; i < len; i++) {
            var prj = mapConfig.Projections[i];
            var o = document.createElement("OPTION");
            this.ddl.options.add(o);
            o.text = prj.Name;
            o.value = prj.SRID;
        }
    },

    sridChanged: function () {
        $("#txtXpos").val('');
        $("#txtYpos").val('');

        if (this.ddl.value === '4326') {
            this.xLabel.innerHTML = 'Lon:';
            this.yLabel.innerHTML = 'Lat:';
        }
        else {
            this.xLabel.innerHTML = 'X:';
            this.yLabel.innerHTML = 'Y:';
        }
    },
    loadSRID: function () {
        return Beacon.localStorage["ZoomToXY_SRID"];
    },
    saveSRID: function (srid) {
        Beacon.localStorage["ZoomToXY_SRID"] = srid;
    },
    clickOk: function () {

        Beacon.GA.TrackEvent('ZoomXY','Click');

        var srid = this.ddl.value;
        var x;
        var y;

        if (srid == 4326) { //DD or DMS
            x = this.parseDMS($("#txtXpos").val().trim());
            y = this.parseDMS($("#txtYpos").val().trim());
        } else {
            x = parseFloat($("#txtXpos").val().trim());
            y = parseFloat($("#txtYpos").val().trim());
        }

        var pt = new OpenLayers.LonLat(x, y);

        var prj = new OpenLayers.Projection("EPSG:" + srid);
        pt.transform(prj, this.map.getProjectionObject());

        if (this.map.getScale() > 4800) {
            this.map.zoomToScale(4800);
        }

        if (this.map.getMaxExtent().containsLonLat(pt)) {
            this.map.panTo(pt);
        } else {
            alert("The coordinates you've entered are outside of this map's area.");
        }

        // this.hide();
        if (this.executeCompleted) {
            this.executeCompleted.apply(this.executeCompletedScope);
        }

        this.saveSRID(this.ddl.selectedOptions[0].text);

        this.addMarker(pt.lon, pt.lat, $("#txtXpos").val(), $("#txtYpos").val());

    },

    clickClear: function () {
        this.clearMarkers();
    },

    parseDMS: function (dms) {

        var a = dms.split(" ");
        var d = 0, m = 0, s = 0;

        if (a.length >= 1) {
            d = parseFloat(a[0]);
        }
        if (a.length >= 2) {
            m = parseFloat(a[1]);
        }
        if (a.length >= 3) {
            s = parseFloat(a[2]);
        }

        if (d < 0) {
            return d - (m / 60.0) - (s / 3600.0);
        } else {
            return d + (m / 60.0) + (s / 3600.0);
        }

    },

    addMarker: function (x, y, userX, userY) {

        var labelText = userX + ', ' + userY;
        var jiggle = 1;
        var xOffset = 10;
        var yOffset = 0;

        // add halo, el-cheapo
        for (var i = -1; i <= 1; i++) {
            for (var j = -1; j <= 1; j++) {
                this.pinLayer.addFeatures([new OpenLayers.Feature.Vector(
                    new OpenLayers.Geometry.Point(x, y),
                    {},
                    {
                        graphic: false,
                        label: labelText,
                        labelAlign: 'lm',
                        labelXOffset: xOffset + i*jiggle,
                        labelYOffset: yOffset + j*jiggle,
                        fontColor: 'white',
                        fontWeight: 'bold'
                    }
                )]);

            }
        }

        // add marker and coordinate text
        this.pinLayer.addFeatures([
            new OpenLayers.Feature.Vector(
                new OpenLayers.Geometry.Point(x,y),
                {},
                {
                    graphicName: 'cross',
                    strokeColor: '#ffffff',
                    strokeWidth: 1,
                    fillOpacity: 1.0,
                    fillColor: '#000000',
                    pointRadius: 6
                }
            )
            ,
            new OpenLayers.Feature.Vector(
                new OpenLayers.Geometry.Point(x, y),
                {},
                {
                    graphic: false,
                    label: labelText,
                    labelAlign: 'lm',
                    labelXOffset: xOffset,
                    labelYOffset: yOffset,
                    fontColor:'black',
                    fontWeight: 'bold'
                }
            )
            
        ]);
    },

    clearMarkers: function () {

        this.pinLayer.removeAllFeatures();
        
    },

//    warmUpGps: function () {

//        this.lastPosition = null;

//        if (navigator.geolocation) {
//            var that = this;

//            $(that.btnGps).text('wait');

//            navigator.geolocation.getCurrentPosition(
//                function (position) {

//                    that.lastPosition = position;
//                    $(that.btnGps)
//                        .show()
//                        .text('[GPS]')
//                    //                        .text("Use GPS (accuracy="
//                    //                            + (position.coords.accuracy * 3.2808399).displayRounding().addCommas()
//                    //                            + " ft)")
//                        .attr('title', 'Current position: ' + position.coords.latitude + ' ' + position.coords.longitude
//                              + " (accuracy="
//                                + (position.coords.accuracy * 3.2808399).displayRounding().addCommas()
//                                + " ft)");


//                    var pt = new OpenLayers.LonLat(position.coords.longitude,
//                                                    position.coords.latitude);

//                    var srid = that.ddl.value;

//                    var prjLL = new OpenLayers.Projection("EPSG:4326");
////                    pt.transform(prj, that.map.getProjectionObject());

//                    var prjUser = new OpenLayers.Projection("EPSG:" + srid);
//                    pt.transform(prjLL, prjUser);


//                    $("#txtXpos").val(pt.lon); //.format('0.00'));
//                    $("#txtYpos").val(pt.lat); //.format('0.00'));
//                },
//                function (error) {
//                    that.lastPosition = null;
//                    $(that.btnGps).text('failed');

//                    //                    switch (error.code) {
//                    //                        case error.TIMEOUT:
//                    //                            alert('Timeout');
//                    //                            break;
//                    //                        case error.POSITION_UNAVAILABLE:
//                    //                            alert('Position unavailable');
//                    //                            break;
//                    //                        case error.PERMISSION_DENIED:
//                    //                            alert('Permission denied');
//                    //                            break;
//                    //                        case error.UNKNOWN_ERROR:
//                    //                            alert('Unknown error');
//                    //                            break;
//                    //                    }
//                },
//                {
//                    maximumAge: 15000,        //15-sec position cache?
//                    responseTime: 25,
//                    timeout: 30000,
//                    enableHighAccuracy: true  // use best position
//                });
//        } else {
//            //no gps
//        }

//    },

//    clickUseGps: function () {

//        this.warmUpGps();
//        Beacon.GA.TrackEvent('ZoomXY', 'GPS');

//    },


    CLASS_NAME: "Beacon.Control.ZoomToXY"

});