/// <reference path="beacon_cogo.ts" />

Beacon.Control.CogoTool = OpenLayers.Class(OpenLayers.Control, {

    cogoLayer: null,
    html: null,
    div: null,
    tools: {},
    snap: null,
    panels: null,
    kbdHandler: null,


    initialize: function (options) {

        OpenLayers.Control.prototype.initialize.apply(this, [options]);


        var that = this;


        this.cogoLayer = new OpenLayers.Layer.Vector("COGO Layer", {
            styleMap: new OpenLayers.StyleMap({
                temporary: OpenLayers.Util.applyDefaults({
                    pointRadius: 16
                }, OpenLayers.Feature.Vector.style.temporary)
            })
        });

        this.cogoLayer.events.on({
            "sketchstarted": this.updateClosure,
            "sketchmodified": this.updateClosure,
            "sketchcomplete": this.updateClosure,
            "beforefeatureadded": this.onBeforeFeatureAdded,
            "featureadded": this.onFeatureAdded,
            scope: this
        });


        var vertexStyle = OpenLayers.Util.applyDefaults({
            fill: true,
            fillColor: "#fff",
            fillOpacity: 0.1,
            stroke: true,
            strokeColor: "#000",
            strokeWidth: 1.5,
            strokeOpacity: 0.75,
            graphic: true,
            graphicName: "square",
            pointRadius: 5
        }, OpenLayers.Feature.Vector.style["default"]);

        var vertexStyle2 = OpenLayers.Util.applyDefaults({
            fill: true,
            fillColor: "#fff",
            fillOpacity: 0.1,
            stroke: true,
            strokeColor: "#444",
            strokeWidth: 1.0,
            strokeOpacity: 0.75,
            graphic: true,
            graphicName: "square",
            pointRadius: 4
        }, OpenLayers.Feature.Vector.style["default"]);

        var dragStyle = OpenLayers.Util.applyDefaults({
            fill: false,
            fillColor: "#000",
            fillOpacity: 0.75,
            stroke: false,
            graphic: true,
            externalGraphic: "/Images/Hover-Move-Arrows.png",
            graphicWidth: 19,
            graphicHeight: 19,
            pointRadius: 5
        }, OpenLayers.Feature.Vector.style["default"]);

        var handlerStyle = {
            pointRadius: 4,
            fillColor: "#fff",
            fillOpacity: 0.1
        };

        this.tools = {

            modify: new Beacon.Control.ModifyFeature(this.cogoLayer, {
                toggle: true,
                mode: Beacon.Control.ModifyFeature.RESHAPE | Beacon.Control.ModifyFeature.DRAG,
                virtualStyle: vertexStyle2,
                vertexStyle: vertexStyle,
                dragStyle: dragStyle,
                onModificationStart: function (f) { that.featureSelected(f); },
                onModificationEnd: function (f) { that.featureUnselected(f); }
            }),

            line: new OpenLayers.Control.DrawFeature(this.cogoLayer, OpenLayers.Handler.Path,
                {
                    handlerOptions: {
                        style: handlerStyle,
                        doubleTouchTolerance: 30
                    }
                }),


            point: new OpenLayers.Control.DrawFeature(this.cogoLayer, OpenLayers.Handler.Point,
                {
                    handlerOptions: {
                        style: handlerStyle
                    }
                }),

            polygon: new OpenLayers.Control.DrawFeature(this.cogoLayer, OpenLayers.Handler.Polygon,
                {
                    handlerOptions: {
                        style: handlerStyle,
                        doubleTouchTolerance: 30
                    }
                })

        };


        this.divs = {
            modify: $("#cogoModify")[0],
            line: $("#cogoLine")[0],
            setDistance: $("#cogoSetDistance")[0],
            chordToCurve: $("#cogoChordToCurve")[0],
            buffer: $("#cogoBuffer")[0],
            distAz: $("#cogoDistAz")[0],
            point: $("#cogoPoint")[0],
            polygon: $("#cogoPolygon")[0],
            closureFeedback: $("#cogoClosureFeedback")[0],
            deleteFeature: $("#cogoDeleteFeature")[0],
            clearAll: $("#cogoClearAll")[0]
        };

        this.panels = {
            bufferUI: $("#cogoBufferUI")[0],
            distAzUI: $("#cogoDistAzUI")[0],
            cutDistUI: $("#cogoCutDistUI")[0],
            curveChordUI: $("#cogoCurveChordUI")[0]
        };

        //snapping support
        this.snap = new OpenLayers.Control.Snapping({
            layer: this.cogoLayer,
            targets: [Beacon.MapJS.selectionLayer, this.cogoLayer],
            greedy: false
        });

        this.html = $('#CogoPanel').detach();

        this.kbdHandler = new OpenLayers.Handler.Keyboard(this, {
            keydown: this.onKeyPress
        });
    },

    onKeyPress: function (e) {

        if (e.code === "KeyQ" && e.ctrlKey) {

            if (this.tools.line.handler.active) {
                this.tools.line.handler.finishGeometry();
                this.hideLineEditTools();
                this.activateUI(null);
            }

            if (this.tools.polygon.handler.active) {
                this.tools.polygon.handler.finishGeometry();
                this.hideLineEditTools();
                this.activateUI(null);
            }

        }

        // OL 2.11 didn't actually have the undo/redo code. bummer.

    },

    draw: function () {
        OpenLayers.Control.prototype.draw.apply(this, arguments);

        if (!this.element) {
            this.element = document.createElement('div');
            this.element.id = this.id + '_frame';
            OpenLayers.Element.addClass(this.element, this.displayClass + 'Frame');

            //re-attach the div we detached in init
            $(this.element).append(this.html);
            this.html.show();

        }

        this.div.appendChild(this.element);

        //prevent clicks from hitting the map 
        this.sink = new Beacon.Control.EventSink();
        this.sink.register(this.div);

        return this.div;
    },

    setMap: function (map) {
        OpenLayers.Control.prototype.setMap.apply(this, [map]);

        this.map.addLayers([this.cogoLayer]);

        for (key in this.tools) {
            this.tools[key].setMap(map);
        }
    },

    activate: function () {
        try {
            var r = OpenLayers.Control.prototype.activate.apply(this);
            if (r) {

                this.snap.activate();
                this.kbdHandler.activate();

                Beacon.MapJS.controls.kbdNavigation.deactivate();

                OpenLayers.Event.observe(this.divs.modify, "click",
                    OpenLayers.Function.bindAsEventListener(function () { this.activateTool(this.tools.modify); $("#cogoClosureUI").hide(); this.hideLineEditTools(); }, this));

                OpenLayers.Event.observe(this.divs.point, "click",
                    OpenLayers.Function.bindAsEventListener(function () { this.activateTool(this.tools.point); this.activateUI(null); $("#cogoClosureUI").hide(); this.hideLineEditTools(); }, this));

                OpenLayers.Event.observe(this.divs.line, "click",
                    OpenLayers.Function.bindAsEventListener(function () { this.activateTool(this.tools.line); this.activateUI(null); $("#cogoClosureUI").show(); this.showLineEditTools(); }, this));

                OpenLayers.Event.observe(this.divs.polygon, "click",
                    OpenLayers.Function.bindAsEventListener(function () { this.activateTool(this.tools.polygon); this.activateUI(null); $("#cogoClosureUI").hide(); this.hideLineEditTools(); }, this));

                OpenLayers.Event.observe(this.divs.distAz, "click",
                    OpenLayers.Function.bindAsEventListener(function () { this.activateUI(this.panels.distAzUI); }, this));/*this.createDistAz*/

                OpenLayers.Event.observe(this.divs.setDistance, "click",
                    OpenLayers.Function.bindAsEventListener(function () { this.activateUI(this.panels.cutDistUI); }, this));

                OpenLayers.Event.observe(this.divs.chordToCurve, "click",
                    OpenLayers.Function.bindAsEventListener(function () { this.activateUI(this.panels.curveChordUI); }, this));

                OpenLayers.Event.observe(this.divs.buffer, "click",
                    OpenLayers.Function.bindAsEventListener(function () { this.activateUI(this.panels.bufferUI); this.hideLineEditTools(); }, this));

                OpenLayers.Event.observe(this.divs.deleteFeature, "click",
                    OpenLayers.Function.bindAsEventListener(this.deleteFeature, this));

                OpenLayers.Event.observe(this.divs.clearAll, "click",
                    OpenLayers.Function.bindAsEventListener(this.clearAll, this));


                OpenLayers.Event.observe($("#cogoDistAzUI_btn")[0], "click",
                    OpenLayers.Function.bindAsEventListener(this.createDistAz, this));

                OpenLayers.Event.observe($("#cogoCutDistUI_btn")[0], "click",
                    OpenLayers.Function.bindAsEventListener(this.setDistanceOnLastLeg, this));

                OpenLayers.Event.observe($("#cogoCurveChordUI_btn")[0], "click",
                    OpenLayers.Function.bindAsEventListener(this.createCurveFromChord, this));

                OpenLayers.Event.observe($("#cogoBufferUI_btn")[0], "click",
                    OpenLayers.Function.bindAsEventListener(this.createBufferFeature, this));


                this.hideLineEditTools();

                if (this.currentTool) {
                    this.activateTool(this.currentTool);
                } else {
                    this.activateTool(this.tools.modify);
                }

                $("BODY").addClass('disableTextSelection');

                Beacon.GA.TrackEvent('CogoTool', 'Opened');
            }
            return r;

        } catch (e) {
            Beacon.MapJS.logException(e, "CogoTool.activate");
        }
    },

    deactivate: function () {
        try {
            var r = OpenLayers.Control.prototype.deactivate.apply(this);

            if (r) {

                this.snap.deactivate();
                this.kbdHandler.deactivate();

                for (key in this.divs) {
                    var toolDiv = this.divs[key];
                    var toolControl = this.tools[key];
                    if (toolControl) toolControl.deactivate();

                    OpenLayers.Event.stopObservingElement(toolDiv);
                }

                $("BODY").removeClass('disableTextSelection');

                Beacon.MapJS.controls.kbdNavigation.activate();

            }
            return r;
        } catch (e) {
            Beacon.MapJS.logException(e, "CogoTool.deactivate");

        }
    },


    activateTool: function (newTool) {
        try {
            for (key in this.tools) {
                var control = this.tools[key];
                var toolDiv = this.divs[key];

                if (newTool == control) {
                    control.activate();
                    $(toolDiv).addClass("mmActive");
                } else {
                    control.deactivate();
                    $(toolDiv).removeClass("mmActive");
                }
            }
            this.currentTool = newTool;


        } catch (e) {
            Beacon.MapJS.logException(e, "CogoTool.activateTool");
        }
    },

    activateUI: function (showPanel) {
        _.each(this.panels, function (v) {
            $(v).hide();
        });

        if (showPanel) {
            $(showPanel).show();
            $("INPUT[type=text]:first", showPanel).focus();
        }
    },

    disableTool: function (toolDiv) {
        $(toolDiv).addClass("disabled-tool");
    },

    enableTool: function (toolDiv) {
        $(toolDiv).removeClass("disabled-tool");
    },

    showLineEditTools: function () {
        this.enableTool(this.divs.distAz);
        this.enableTool(this.divs.setDistance);
        this.enableTool(this.divs.chordToCurve);
    },

    hideLineEditTools: function () {
        this.disableTool(this.divs.distAz);
        this.disableTool(this.divs.setDistance);
        this.disableTool(this.divs.chordToCurve);
    },

    deleteFeature: function () {
        try {
            var f = this.tools.modify.feature;
            if (f) {
                this.tools.modify.unselectFeature(f);
                this.cogoLayer.removeFeatures([f]);
                this.featureUnselected();
            }
        } catch (e) {
            Beacon.MapJS.logException(e, "CogoTool.delete");
        }
    },

    clearAll: function () {
        try {
            this.cogoLayer.removeAllFeatures();
        } catch (e) {
            Beacon.MapJS.logException(e, "CogoTool.clear");
        }
    },

    featureSelected: function (feature) {
        this.enableTool(this.divs.deleteFeature);
        this.enableTool(this.divs.buffer);
    },

    featureUnselected: function () {
        this.disableTool(this.divs.deleteFeature);
        this.disableTool(this.divs.buffer);
    },


    /* edit feature functions */

    getLinearDistanceInMapUnits: function (distFt) {
        if (this.map.units == "m") {
            return distFt * 0.3048;
        }
        else {
            return distFt;
        }
    },

    getMapDistanceInFeet: function (distMap) {
        if (this.map.units == "m") {
            return distMap / 0.3048;
        }
        else {
            return distMap;
        }
    },

    getLastPointXY: function (howFarBack) {
        var farIndex = howFarBack || 0; // 0 is the last point planted, 1 would be the one before it
        var pathHandler = this.tools.line.handler;
        var previousIndex = pathHandler.line.geometry.components.length - 1 - farIndex;
        var p0 = pathHandler.line.geometry.components[previousIndex - 1];
        return p0;
    },

    getFirstPointXY: function () {
        var pathHandler = this.tools.line.handler;
        var p0 = pathHandler.line.geometry.components[0];
        return p0;
    },

    insertDeltaXY: function (dx, dy) {
        var pt1 = this.getLastPointXY(0);
        this.insertXY(pt1.x + dx, pt1.y + dy);
    },

    insertXY: function (x, y) {
        var pathHandler = this.tools.line.handler;
        var previousIndex = pathHandler.line.geometry.components.length - 1;
        pathHandler.line.geometry.addComponent(
            new OpenLayers.Geometry.Point(x, y),
            previousIndex
        );
        this.forceSketchChanged();
    },

    insertXYs: function (points) {
        var pathHandler = this.tools.line.handler;
        var previousIndex = pathHandler.line.geometry.components.length - 1;
        for (var i = 0; i < points.length; i++) {
            pathHandler.line.geometry.addComponent(
                new OpenLayers.Geometry.Point(points[i].x, points[i].y),
                pathHandler.line.geometry.components.length - 1
            );
        }
        this.forceSketchChanged();
    },

    removePoint: function (point) {
        var pathHandler = this.tools.line.handler;
        pathHandler.line.geometry.removeComponent(point);
    },

    forceSketchChanged: function () {
        var pathHandler = this.tools.line.handler;
        pathHandler.drawFeature();
        delete pathHandler.redoStack;
        this.updateClosure({
            type: 'sketchmodified',
            feature: pathHandler.line
        });
    },

    setDistanceOnLastLeg: function () {

        var distFt = Beacon.Cogo.UnitConverter.measurementParser($("#cogoCutdistUI_dist").val()); 
        var distMap = this.getLinearDistanceInMapUnits(distFt);

        var pt0 = this.getLastPointXY(1); // from
        var pt1 = this.getLastPointXY(0); // to - temp, this one will be updated

        // grab the angle of the line
        var angle = Beacon.Cogo.CogoMath.angleBetweenPoints(pt0, pt1);

        // find the new endpoint of the line
        var pt2 = Beacon.Cogo.CogoMath.applyDistanceAzimuth(pt0, distMap, angle);

        // change the coords on the original point
        pt1.x = pt2.x;
        pt1.y = pt2.y;

        this.forceSketchChanged();

        $("#cogoCutdistUI_dist").val("").focus();

    },

    createDistAz: function () {

        var distFt = Beacon.Cogo.UnitConverter.measurementParser($("#cogoDistAzUI_dist").val());
        var distMap = this.getLinearDistanceInMapUnits(distFt);
        var angle = Beacon.Cogo.UnitConverter.angleParser($("#cogoDistAzUI_angle").val());

        // find the new endpoint of the line
        var pt1 = this.getLastPointXY(0);
        var pt2 = Beacon.Cogo.CogoMath.applyDistanceAzimuth(pt1, distMap, angle);

        this.insertXY(pt2.x, pt2.y);

        $("#cogoDistAzUI_dist").val("").focus();
        $("#cogoDistAzUI_angle").val("");
    },


    createCurveFromChord: function () {

        var cwOrientation = ($("#cogoCurveChordUI_cw").val() === "cw");
        var isMajor = ($("#cogoCurveChordUI_major").val() === "major");

        var isRadius = ($("#cogoCurveChordUI_radiusOrDelta").val() === "radius");

        var radiusMap = null;
        var delta = null;

        if (isRadius) {
            var radiusFt = Beacon.Cogo.UnitConverter.measurementParser($("#cogoCurveChordUI_measure").val());
            radiusMap = this.getLinearDistanceInMapUnits(radiusFt);
        }
        else {
            delta = Beacon.Cogo.UnitConverter.angleParser($("#cogoCurveChordUI_measure").val());
        }


        var pt0 = this.getLastPointXY(1); // from
        var pt1 = this.getLastPointXY(0); // to 

        var newLeg = Beacon.Cogo.CogoMath.chordRadiusCurve(pt0, pt1, radiusMap, delta, isMajor, cwOrientation);

        // remove chord
        this.removePoint(pt0);
        this.removePoint(pt1);

        // add new curve points
        this.insertXYs(newLeg);

        $("#cogoCurveChordUI_measure").val("").focus();

    },



    updateClosure: function (o) {
        if (!this.tools.line.active) return;

        var geom = o.feature.geometry;
        var i;
        switch (o.type) {
            case 'sketchmodified':
                i = 2;
                break;
            case 'sketchcomplete':
                i = 1;
                break;
            default:
                return; // don't update on other messages
        }

        if (o.type === 'sketchmodified' && geom.components.length === 2) {
            this.updateClosureUI("n/a"); // first point clicked, reset the ui
            return;
        }
        if (geom.components.length <= i) {
            return; // not enough points to show anything
        }

        var pt1 = geom.components[0];
        var pt2 = geom.components[geom.components.length - i];

        var closureError = Math.abs(pt1.distanceTo(pt2));
        var closureErrorFt = this.getMapDistanceInFeet(closureError);

        this.updateClosureUI(closureErrorFt);

    },

    updateClosureUI: function (distance) {
        if (_.isNumber(distance)) {
            $(this.divs.closureFeedback).text(distance.format("0,000.00") + " ft");
        } else {
            $(this.divs.closureFeedback).text(distance);
        }
    },




    /* buffer tool support */


    createBufferFeature: function () {
        var editingFeature = this.tools.modify.feature;
        if (!editingFeature) return;
        var dist = parseFloat($("#cogoBufferUI_dist").val());
        var unitFactor = parseFloat($("#cogoBufferUI_units").val());

        this.buffer(dist * unitFactor, [editingFeature]); // this api actually takes Ft - not map units

        $("#cogoBufferUI_dist").val("").focus();
    },

    buffer: function (distance, features) {

        var wktParser = new OpenLayers.Format.WKT();

        var wkts = [];

        for (var i = 0; i < features.length; i++) {
            var feature = features[i];
            if (feature.geometry) {
                wkts.push(wktParser.write(feature));
            }
        }

        Beacon.API.BufferGeometry(
            wkts,
            distance,
            this.bufferComplete,
            this.bufferFailure,
            this,
            {
            });
    },

    bufferComplete: function (polyWkt, params) {

        var self = this;
        var wktParser = new OpenLayers.Format.WKT();
        var polyFeature = wktParser.read(polyWkt);
        polyFeature.fid = 'buffer';

        this.cogoLayer.addFeatures([polyFeature]);


    },



    bufferFailure: function (msg) {
        console.error(msg);
    },



    onBeforeFeatureAdded: function (e) {
        var f = e.feature;

        if (f.style) return; //skip items that are pre-styled (loaded from svr)

        var newStyle;

        var markerStyle = {
            fillColor: "#000",
            fillOpacity: 0.25,
            strokeColor: "#000",
            strokeWidth: 2,
            pointRadius: 5
        };

        var geomStyle = {
            fillColor: "#000",
            fillOpacity: 0.25,
            strokeColor: "#000",
            strokeWidth: 3,
            graphic: false
        };

        if (this.currentTool == this.tools.point) {
            newStyle = markerStyle;
        } else {
            newStyle = geomStyle;
        }

        // assign and fill in missing settings from default style
        f.style = OpenLayers.Util.applyDefaults(newStyle, OpenLayers.Feature.Vector.style["default"]);

        return true;
    },

    onFeatureAdded: function (e) {
        var f = e.feature;
        this.activateTool(this.tools.modify);
        this.tools.modify.selectControl.select(f);

        this.hideLineEditTools();
    },

    CLASS_NAME: "Beacon.Control.CogoTool"
});

