Beacon.Control.ZoomToMaxExtent = OpenLayers.Class(OpenLayers.Control, {

    /**
     * Property: type
     * {String} The type of <OpenLayers.Control> -- When added to a 
     *     <Control.Panel>, 'type' is used by the panel to determine how to 
     *     handle our events.
     */
    type: OpenLayers.Control.TYPE_BUTTON,
    
    maxExtent: null,

    /*
     * Method: trigger
     * Do the zoom.
     */
    trigger: function() {
        if (this.map) {
            this.map.zoomToExtent(this.maxExtent,false);
        }    
    },

    CLASS_NAME: "Beacon.Control.ZoomToMaxExtent"
});
