
/**
* @requires OpenLayers/Layer/Grid.js
* @requires OpenLayers/Tile/Image.js
*/

/**
* Class: Beacon.Layer.AgsCache
* 
* Inherits from:
*  - <OpenLayers.Layer.Grid>
*/
Beacon.Layer.AgsCache = OpenLayers.Class(OpenLayers.Layer.Grid, {

    /**
    * APIProperty: serviceVersion
    * {String}
    */
    serviceVersion: "1.0.0",

    /**
    * APIProperty: isBaseLayer
    * {Boolean}
    */
    isBaseLayer: true,

    /**
    * APIProperty: tileOrigin
    * {<OpenLayers.Pixel>}
    */
    tileOrigin: null,


    // use this url for the synthetic levels (past native zoom)
    enhancedUrl: null,

    // native zoom levels
    nativeLevels: null,

    /**
    * Constructor: Beacon.Layer.AgsCache
    * 
    * Parameters:
    * name - {String}
    * url - {String}
    * options - {Object} Hashtable of extra options to tag onto the layer
    */
    initialize: function (name, url, options) {

        var base = mapConfig.TileHost;
        var url2;

        //if ((url.charAt(0) == '/') && base) {
        //    url2 = ['https://i0.' + base + url,
        //            'https://i1.' + base + url,
        //            'https://i2.' + base + url,
        //            'https://i3.' + base + url];
        //} else {
        //    url2 = url;
        //}

        // Modified for forced-https use:  old domains threw cert exceptions being from beacongis.com domain
        //  so just hit a relative url
        //  TODO:  -- this should be fixed when migrating tiles to S3???
        url2 = url;



        var newArguments = [];
        newArguments.push(name, url2, {}, options);
        OpenLayers.Layer.Grid.prototype.initialize.apply(this, newArguments);
    },

    /**
    * APIMethod:destroy
    */
    destroy: function () {
        // for now, nothing special to do here. 
        OpenLayers.Layer.Grid.prototype.destroy.apply(this, arguments);
    },


    /**
    * APIMethod: clone
    * 
    * Parameters:
    * obj - {Object}
    * 
    * Returns:
    * {<Beacon.Layer.AgsCache>} An exact clone of this <Beacon.Layer.AgsCache>
    */
    clone: function (obj) {

        if (obj == null) {
            obj = new Beacon.Layer.AgsCache(this.name,
                                           this.url,
                                           this.getOptions());
        }

        //get all additions from superclasses
        obj = OpenLayers.Layer.Grid.prototype.clone.apply(this, [obj]);

        // copy/set any non-init, non-simple values here

        return obj;
    },

    // suppt for fractional zoom: (unfinished)
    // find the resolution that is best used at the (res) resolution
    bestLevel: function (res) {
        for (var level = 0; level < this.resolutions.length; level++) {
            if (res >= this.resolutions[level]) {
                return level;
            }
        }
        return this.resolutions.length - 1;
    },

    /*** overrides Grid.calculateGridLayout ***/
    calculateGridLayout: function (bounds, extent, resolution) {

        // suppt for fractional zoom: (unfinished)
        //tweak resolution ???
        //var level = this.bestLevel(resolution);
        //resolution = this.resolutions[level];


        //bounds: area displayed on map (mu)

        var tilelon = resolution * this.tileSize.w;                     // width of the tile (mu)
        var tilelat = resolution * this.tileSize.h;                     // height of the tile (mu)

        //note: tile origin typically in the NW corner

        var offsetlon = bounds.left - this.tileOrigin.lon;              // dist from tile origin to left edge (mu)
        var tilecol = Math.floor(offsetlon / tilelon) - this.buffer;    // column number for left tile
        var tilecolremain = offsetlon / tilelon - tilecol;              // fraction of first tile off the left edge
        var tileoffsetx = -tilecolremain * this.tileSize.w;             // pixels of first tile off the left edge
        var tileoffsetlon = this.tileOrigin.lon + tilecol * tilelon;    // left edge of first tile in mu

        var offsetlat = this.tileOrigin.lat - bounds.top;               // dist from tile origin to top edge (mu)
        var tilerow = Math.floor(offsetlat / tilelat) - this.buffer;    // row number for top tile
        var tilerowremain = tilerow - (offsetlat / tilelat);            // fraction of first tile off the top edge
        var tileoffsety = tilerowremain * this.tileSize.h;              // pixels of first tile off the top edge
        var tileoffsetlat = (this.tileOrigin.lat - (tilerow * tilelat)) - tilelat;    // bottom(!) edge of first tile in mu

        return {
            tilelon: tilelon,               // width of the tile in map units
            tilelat: tilelat,               // height of the tile in map units
            tileoffsetlon: tileoffsetlon,   // left edge of first tile in mu 
            tileoffsetlat: tileoffsetlat,   // bottom(!) edge of first tile in mu
            tileoffsetx: tileoffsetx,       // pixels of first tile off the left edge
            tileoffsety: tileoffsety        // pixels of first tile off the top edge
        };
    },


    /**
    * Method: getURL
    * 
    * Parameters:
    * bounds - {<OpenLayers.Bounds>}
    * 
    * Returns:
    * {String} A string with the layer's url and parameters and also the 
    *          passed-in bounds and appropriate tile size specified as 
    *          parameters
    */
    getURL: function (bounds) {
        bounds = this.adjustBounds(bounds);

        var res = this.map.getResolution();
        var z = this.resolutions != null ?
            OpenLayers.Util.indexOf(this.resolutions, res) :
            this.map.getZoom();

        // suppt for fractional zoom: (unfinished)
        // var z = this.bestLevel(this.map.getResolution());
        // var res = this.resolutions[z];

        var x = Math.round((bounds.left - this.tileOrigin.lon) / (res * this.tileSize.w));
        var y = Math.round((this.tileOrigin.lat - bounds.top) / (res * this.tileSize.h));


        var path;

        //        if (url[0] == "/") {
        //            // use custom tile server
        //            path = "?x=" + x + "&y="
        //        } else {
        // normal AGS rest api tile server
        path = "/" + z + "/" + y + "/" + x;
       
        var url;
        if (this.url instanceof Array) {
            url = this.selectUrl(path, this.url);
        } else {
            url = this.url;
        }

        // override url 
        if (this.enhancedUrl && z >= this.nativeLevels) {
            url = this.enhancedUrl;
        }

        return url + path;
    },

    /**
    * Method: addTile
    * addTile creates a tile, initializes it, and adds it to the layer div. 
    * 
    * Parameters:
    * bounds - {<OpenLayers.Bounds>}
    * position - {<OpenLayers.Pixel>}
    * 
    * Returns:
    * {<OpenLayers.Tile.Image>} The added OpenLayers.Tile.Image
    */
    addTile: function (bounds, position) {
        return new OpenLayers.Tile.Image(this, position, bounds,
                                         null, this.tileSize);
    },

    /** 
    * APIMethod: setMap
    * When the layer is added to a map, then we can fetch our origin 
    *    (if we don't have one.) 
    * 
    * Parameters:
    * map - {<OpenLayers.Map>}
    */
    setMap: function (map) {
        OpenLayers.Layer.Grid.prototype.setMap.apply(this, arguments);
        if (!this.tileOrigin) {
            this.tileOrigin = new OpenLayers.LonLat(this.map.maxExtent.left,
                                                this.map.maxExtent.bottom);
        }
    },


    //    getSettingsFromServer: function (callback) {

    //        OpenLayers.Request.GET({
    //            url: this.url,
    //            params: { f: "json" },

    //            success: function (response) {
    //                var data = response.responseText;
    //                var jsonObj = new OpenLayers.Format.JSON();

    //                var o = jsonObj.read(data);

    //                var lodCt = o.tileInfo.lods.length;

    //                var reslist = [];
    //                for (var i = 0; i < lodCt; i++) {
    //                    reslist[i] = o.tileInfo.lods[i]["resolution"];
    //                }

    //                /* set parameters  
    //                this.resolutions = reslist;
    //                this.tileSize = new OpenLayers.Size(o.tileInfo.cols, o.tileInfo.rows);
    //                this.tileOrigin = new OpenLayers.LonLat(o.tileInfo.origin.x, o.tileInfo.origin.y);
    //                this.maxExtent = new OpenLayers.Bounds(o.fullExtent.xmin,
    //                o.fullExtent.ymin,
    //                o.fullExtent.xmax,
    //                o.fullExtent.ymax);

    //                myMap.addLayer(this);
    //                */

    //                var retObj = {
    //                    resolutions: reslist,
    //                    tileSize: new OpenLayers.Size(o.tileInfo.cols, o.tileInfo.rows),
    //                    tileOrigin: new OpenLayers.LonLat(o.tileInfo.origin.x, o.tileInfo.origin.y),
    //                    maxExtent: new OpenLayers.Bounds(o.fullExtent.xmin,
    //                                                        o.fullExtent.ymin,
    //                                                        o.fullExtent.xmax,
    //                                                        o.fullExtent.ymax)
    //                };

    //                callback(retObj);




    //            }

    //        });


    //    },

    forceRedraw: function () {
        var isVisible = this.getVisibility();
        if (isVisible) {
            // trigger a redraw
            this.moveTo(this.map.getExtent(), true, false);
        }
    },

    CLASS_NAME: "Beacon.Layer.AgsCache"
});
