
//  This overrides the base tile class and to enhance the background tile
//  effect while a new image is loading - by making the old tile 50% opacity

Beacon.Tile.Image = OpenLayers.Class(OpenLayers.Tile.Image, {

    opacityOnTransition: 0.5,

    initialize: function (layer, position, bounds, url, size) {
        OpenLayers.Tile.Image.prototype.initialize.apply(this, arguments);

    },

    startTransition: function () {

        if (this.backBufferTile && this.backBufferTile.imgDiv) {
            // set opacity to 50% for backtile
            OpenLayers.Util.modifyDOMElement(this.backBufferTile.imgDiv,
                null, null, null, null, null, null, this.opacityOnTransition);
        }

        OpenLayers.Tile.Image.prototype.startTransition.apply(this, arguments);

    },

    CLASS_NAME: 'Beacon.Tile.Image'
});
