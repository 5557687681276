
Beacon.Control.DataExtract = OpenLayers.Class(OpenLayers.Control, {

    type: OpenLayers.Control.TYPE_BUTTON,

    trigger: function (evt) {

        try {

            var url = Beacon.Tabs.getUrl(mapConfig.LayerId,
                                     mapConfig.PageTypeId,
                                     mapConfig.PageId,
                                     null,
                                     "ExtractData.aspx") + "&BBOX=" + this.map.getExtent().toBBOX();

            Beacon.Dialogs.showFromIFrame(null, "Download map data", url);


        } catch (e) {
            Beacon.MapJS.showRetryActivity(e, "DataExtract.trigger");

        }


    },


    CLASS_NAME: "Beacon.Control.DataExtract"
});