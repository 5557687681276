
//Beacon.Control.Select = OpenLayers.Class(Beacon.Control.HoverSupport, {
Beacon.Control.Select = OpenLayers.Class(OpenLayers.Control, {

    //handlers: null,
    modifiers: null,
    multipleKey: "shiftKey",
    toggleKey: "ctrlKey",
    clickTolerance: 15,
    mode: 'normal',   // select by: 'normal'|'polygon'|'line'
    type: OpenLayers.Control.TYPE_TOOL,

    initialize: function (options) {

        //Beacon.Control.HoverSupport.prototype.initialize.apply(this, arguments);
        OpenLayers.Control.prototype.initialize.apply(this, arguments);

        this.modifiers = { multiple: false, toggle: false };

        //this.handlers = {};

        var handlerStyle = {
            pointRadius: 4,
            fillColor: "#fff",
            fillOpacity: 0.1
        };

        switch (this.mode) {
            case 'normal':
                this.handler = new OpenLayers.Handler.Box(
                    this,
                    { done: this.onBox },
                    { boxDivClassName: "olHandlerBoxSelectFeature" });

                break;

            case 'polygon':
                this.handler = new OpenLayers.Handler.Polygon(
                    this,
                    { done: this.onPolygon },
                    {
                        freehand: false,
                        freehandToggle: null,
                        doubleTouchTolerance: 30,
                        style: { strokeColor: "#00f", strokeWidth: 2, pointRadius: 4, fillColor: "#fff", fillOpacity: 0.3 }
                    });
                break;

            case 'line':
                this.handler = new OpenLayers.Handler.Path(
                    this,
                    { done: this.onLine },
                    {
                        freehand: false,
                        freehandToggle: null,
                        doubleTouchTolerance: 30,
                        style: { strokeColor: "#00f", strokeWidth: 2, pointRadius: 4, fillColor: "#fff", fillOpacity: 0.3 }
                    });
                break;

            case 'freehand':
                this.handler = new OpenLayers.Handler.Path(
                    this,
                    {
                        done: this.onLine
                    },
                    {
                        freehand: true,
                        freehandToggle: null,
                        style: { strokeColor: "#00f", strokeWidth: 2, pointRadius: 4, fillColor: "#fff", fillOpacity: 0.3 }
                    });

                var h = this.handler;
                h.addPoint = function (pixel) {
                    this.layer.removeFeatures([this.point]);
                    var lonlat = this.control.map.getLonLatFromPixel(pixel);

                    //Hack to correct 1st point of touch
                    if (!this.point.geometry.x) {
                        this.point.geometry.x = lonlat.lon;
                        this.point.geometry.y = lonlat.lat;
                        this.point.geometry.clearBounds();
                        this.line.geometry.clearBounds();
                    }
                    //End of hack

                    this.point = new OpenLayers.Feature.Vector(
                        new OpenLayers.Geometry.Point(lonlat.lon, lonlat.lat)
                    );
                    this.line.geometry.addComponent(
                        this.point.geometry, this.line.geometry.components.length
                    );
                    this.layer.addFeatures([this.point]);
                    this.callback("point", [this.point.geometry, this.getGeometry()]);
                    this.callback("modify", [this.point.geometry, this.getSketch()]);
                    this.drawFeature();
                };

                break;


            case 'toggle':
                
                this.handler = new OpenLayers.Handler.Click(
                    this,
                    {
                        click: this.onToggle
                    },
                    {
                        single: true,
                        double: false
                    });
                break;

        }


    },
    

    onBox: function (position) {

        Beacon.GA.TrackEvent('Select', 'BoxOrClick');

        var bounds;
        if (position instanceof OpenLayers.Bounds) {
            var minXY = this.map.getLonLatFromPixel(
                new OpenLayers.Pixel(position.left, position.bottom)
            );
            var maxXY = this.map.getLonLatFromPixel(
                new OpenLayers.Pixel(position.right, position.top)
            );
            bounds = new OpenLayers.Bounds(
                minXY.lon, minXY.lat, maxXY.lon, maxXY.lat
            );

        } else {
            //its a point
            bounds = this.pixelToBounds(position);
        }

        this.setModifiers(this.handler.dragHandler.evt);
        this.request(bounds);
        //this.startHovering();

    },

    onPolygon: function (polygon) {

        this.setModifiers(this.handler.evt);

        var pts = polygon.components[0].components;

        // need to check if user digitized a 2 or 3 point polygon
        if (pts.length == 4) {
            if (pts[1].distanceTo(pts[2]) == 0) {
                // middle 2 pts are the same - thus its just a line.
                var line = new OpenLayers.Geometry.LineString([pts[0], pts[1]]);
                this.request(line);
                Beacon.GA.TrackEvent('Select', 'Polygon (2pt)');
                //this.startHovering();
                return;
            }
        }

        // see if user just dbl clicked
        if (pts.length == 2) {
            var res = this.map.getResolution();
            bounds = new OpenLayers.Bounds(pts[0].x, pts[0].y, pts[0].x + res, pts[0].y + res);
            this.request(bounds);
            Beacon.GA.TrackEvent('Select', 'Polygon (1pt)');
            //this.startHovering();
            return;
        }

        // normal polygons with 4 or more points
        this.request(polygon);
        Beacon.GA.TrackEvent('Select', 'Polygon');
        //this.startHovering();
        return;

    },

    onLine: function (line) {

        this.setModifiers(this.handler.evt);

        var pts = line.components;

        // see if user just dbl clicked
        if (pts.length == 1 || (pts.length == 2 && (pts[0].distanceTo(pts[1]) == 0))) {
            var res = this.map.getResolution();
            bounds = new OpenLayers.Bounds(pts[0].x, pts[0].y, pts[0].x + res, pts[0].y + res);
            this.request(bounds);
            Beacon.GA.TrackEvent('Select', 'Line (1pt)');
            //this.startHovering();
            return;
        }

        this.request(line);
        Beacon.GA.TrackEvent('Select', 'Line');
        //this.startHovering();
    },

    onToggle: function (position) {

        Beacon.GA.TrackEvent('Select', 'Toggle');

        var bounds = this.pixelToBounds(position.xy);

        this.modifiers = {
            multiple: false,
            toggle: true
        };

        this.request(bounds);
    },

    request: function (geom) {

        Beacon.MapJS.showActivity("Selecting items");

        var reader = new Beacon.Protocol.BeaconFeature({});

        reader.readFeatures(
            false,
            geom,
            Beacon.API.SpatialRelation.Intersects,
            null,
            this.gotFeatures,
            function () {
                Beacon.MapJS.showRetryActivity();
            },
            this);
    },



    gotFeatures: function (features) {
        try {

            this.selectionLayer.selectFeatures(features, this.modifiers.multiple, this.modifiers.toggle);

            //OpenLayers.Element.removeClass(this.map.viewPortDiv, "olCursorWait");
            Beacon.MapJS.hideActivity();
        } catch (e) {
            Beacon.MapJS.showRetryActivity(e, "Select.gotFeatures");

        }

    },

    setModifiers: function (evt) {
        this.modifiers = {
            multiple: evt[this.multipleKey],
            toggle: evt[this.toggleKey]
        };
    },

    pixelToBounds: function (pixel) {
        var llPx = pixel.add(-this.clickTolerance / 2, this.clickTolerance / 2);
        var urPx = pixel.add(this.clickTolerance / 2, -this.clickTolerance / 2);
        var ll = this.map.getLonLatFromPixel(llPx);
        var ur = this.map.getLonLatFromPixel(urPx);
        return new OpenLayers.Bounds(ll.lon, ll.lat, ur.lon, ur.lat);
    },


    CLASS_NAME: 'Beacon.Control.Select'

});