
Beacon.Control.Identify = OpenLayers.Class(Beacon.Control.HoverSupport, {

    wizardName: null, //name of citizen input wizard name - set on layer change if we're on a layer with forms

    identifyMode: 'normal', // normal | hover | disable

    popups: [],

    initialize: function (options) {
        Beacon.Control.HoverSupport.prototype.initialize.apply(this, arguments);
        this.initHoverMode();
    },

    setMap: function (map) {
        Beacon.Control.HoverSupport.prototype.setMap.apply(this, arguments);

        // orig behavior:
        this.clickHandler = new OpenLayers.Handler.Click(this,
            {
                click: this.onClick
            }, {
            pixelTolerance: 10
        });
        //this.clickHandler.setMap(map);


        this.hoverHander = new OpenLayers.Handler.Hover(this, {
            move: this.onHoverMove,
            pause: this.onHoverPause
        }, {
            delay: 750,
            pixelTolerance: 4
        });
        //this.hoverHandler.setMap(map);
    },

    activate: function () {
        if (!this.active) {
            this.clickHandler.activate();
            this.hoverHander.activate();
        }

        if (this.active && !Beacon.MapJS.mobileDevice) {
            if ($("#identifyOptionPanel:visible").length > 0) {
                $("#identifyOptionPanel").slideUp(200, function () { $("#identifyOptionPanelSpacer").hide(); });
            } else {
                $("#identifyOptionPanelSpacer").show();
                $("#identifyOptionPanel").slideDown(200);
            }
        }

        return Beacon.Control.HoverSupport.prototype.activate.apply(
            this, arguments
        );
    },

    deactivate: function () {
        if (this.active) {
            this.clickHandler.deactivate();
            this.hoverHander.deactivate();
            $("#identifyOptionPanel").slideUp(200, function () { $("#identifyOptionPanelSpacer").hide(); });
        }
        return Beacon.Control.HoverSupport.prototype.deactivate.apply(
            this, arguments
        );
    },

    onClick: function (evt) {

        // What should a click to in hover mode???

        try {


            if (this.isWizardLayer()) {
                var pt = this.map.getLonLatFromPixel(evt.xy);
                this.showCitizenInput(pt);
                Beacon.GA.TrackEvent('CitizenInput', 'Click');
            } else {
                switch (this.identifyMode) {
                    case 'normal':
                        this.plantTip(evt, 5, false);
                        Beacon.GA.TrackEvent('Identify', 'Click');
                        break;

                    case 'hover':
                        // We have a click event while in hoverMode -- just populate the result on the selection layer...AL

                        var pix = evt.xy;
                        // expand search by a few pixels:
                        var px1 = pix.add(-5, -5);
                        var px2 = pix.add(5, 5);

                        var ext = new OpenLayers.Bounds();
                        ext.extend(this.map.getLonLatFromPixel(px1));
                        ext.extend(this.map.getLonLatFromPixel(px2));

                        var reader = new Beacon.Protocol.BeaconFeature({});

                        reader.readFeatures(
                            false,
                            ext,
                            Beacon.API.SpatialRelation.Intersects,
                            25,
                            function (results, popup, clickPoint) {

                                var result;

                                if (results.length > 0) {
                                    this.selectionLayer.selectFeatures(results, false, false);
                                }

                                Beacon.MapJS.hideActivity();

                            },
                            this.displayError,
                            this,
                            null);

                        Beacon.MapJS.showActivity("Identifying item");
                        break;

                    case 'disable':
                        this.plantTip(evt, 5, false);
                        Beacon.GA.TrackEvent('Identify', 'Click');
                        break;

                }

            }
        } catch (e) {
            Beacon.MapJS.showRetryActivity(e, "Identify.onClick");
        }


    },

    onHoverMove: function (evt) {
        if (this.identifyMode === 'hover' && !this.isWizardLayer()) {
            var tgt = evt.target || evt.srcElement;
            // console.info("move: " + tgt.id);

            if (this.isDescendentOf(this.map.layerContainerDiv, tgt)) {
                //for (var i = 0; i < this.popups.length; i++)
                //    if (this.popups[i].popup && !this.popups[i].popup.pinned)
                //        this.popups[i].removeMarkup();

                this.selectionLayer.clearPopups();
            }
        }
    },


    onHoverPause: function (evt) {


        if (this.identifyMode === 'hover' && !this.isWizardLayer()) {

            for (var i = 0; i < this.map.popups.length; i++)
                if (this.map.popups[i].dragContainer.down)
                    return; // Do not render tip if we are dragging a popup...AL

            if (Beacon.TOC.activeLayer.willLayerDraw()) {

                var tgt = evt.target || evt.srcElement;

                // console.info("pause: " + tgt.id);

                if (this.isDescendentOf(this.map.layerContainerDiv, tgt)) {
                    //console.info('i am related!');

                    Beacon.GA.TrackEvent('Identify', 'Hover');
                    this.plantTip(evt, 5, false);

                }
            }
        }


    },

    isWizardLayer: function () {
        return Beacon.Tabs.hasPageType(5);
    },

    isDescendentOf: function (parent, child) {

        if (!child) {
            return false;
        }

        if (child.parentNode === parent) {
            return true;
        } else {
            return this.isDescendentOf(parent, child.parentNode);
        }

    },

    plantTip: function (evt, tolerance, bePinned) {

        var lonlat = this.map.getLonLatFromPixel(evt.xy);
        var pix = evt.xy; //this.map.getPixelFromLonLat(lonlat);


        if (this.identifyMode === 'normal' || this.identifyMode === 'disable' || this.isWizardLayer()) {
            // remove old tips that aren't pinned, unless we clicked on a selected item
            //for (var i = 0; i < this.popups.length; i++)
            //    if (this.popups[i].popup && !this.popups[i].popup.pinned)
            //        this.popups[i].removeMarkup();

            this.selectionLayer.clearPopups();
            this.selectionLayer.clearSelection();
        }


        var popup = null;
        var dragPopup = null;

        if (!Beacon.MapJS.mobileDevice && (this.identifyMode === 'normal' || this.identifyMode === 'hover')) {

            // non small device -- normal map tips

            popup = new Beacon.Popup.MapTipFrame("maptip2",
                lonlat,
                null,
                "<div class='title'>Loading Information...</div><div class='loader'></div>",
                {
                    size: new OpenLayers.Size(0, 0),
                    offset: new OpenLayers.Pixel(0, 0)
                },
                null,
                null,
                (this.identifyMode === 'hover')  //showundermouse - for hovermode
            );

            dragPopup = new Beacon.Popup.MapTipFrameDragable(popup)
            dragPopup.setMap(this.map);
            //dragPopup.drawMarkup(lonlat, popup.startPosition);
            this.map.addPopup(dragPopup.popup);

            if (bePinned) {
                dragPopup.popup.togglePin();
            }

            // this.popups.push(dragPopup);

            // capture events on popup
            //if (this.hoverMode && !this.isWizardLayer()) {
            //    var hoverHandler = this.hoverHander;
            //    dragPopup.popup.events.on({
            //        mousemove: function (e) {
            //            hoverHandler.clearTimer(); // UNDOCUMENTED API CALL - resets hoverhandler so that we don't get a strat popup once we've moused over a tip
            //            OpenLayers.Event.stop(e, true);
            //        },
            //        scope: this
            //    });
            //}
        } else {

            //small device - no map tip popup created
            popup = null;

        }

        var cached = this.selectionLayer.getFeatureFromEvent(evt);
        if (cached && this.identifyMode === 'hover' && !this.isWizardLayer()) {
            this.displayResult([cached], popup, lonlat);
        } else {

            //do format request

            // expand search by a few pixels:
            var px1 = pix.add(-tolerance, -tolerance);
            var px2 = pix.add(tolerance, tolerance);

            var ext = new OpenLayers.Bounds();
            ext.extend(this.map.getLonLatFromPixel(px1));
            ext.extend(this.map.getLonLatFromPixel(px2));

            var reader = new Beacon.Protocol.BeaconFeature({});

            reader.readFeatures(
                false,
                ext,
                Beacon.API.SpatialRelation.Intersects,
                25,
                this.displayResult,
                this.displayError,
                this,
                [popup, lonlat]);

            Beacon.MapJS.showActivity("Identifying item");

        }


    },

    displayError: function (errorMessage, popup) {
        Beacon.MapJS.showRetryActivity();
        if (!popup) return;
        popup.setContent("No information available", "An error has occurred while requesting map data.");
    },

    displayResult: function (results, popup, clickPoint) {

        var result;

        if (results.length > 0) {
            result = this.findBestFeature(results, clickPoint);
            if (this.identifyMode === 'normal' || this.identifyMode === 'disable' || this.isWizardLayer()) {
                this.selectionLayer.selectFeatures([result], false, false);  //this should set key in the process
            }
        } else {
            result = null;
        }

        if (popup) {

            // normal size:

            if (result) {
                var existingPopup = false;
                for (var i = 0; i < this.map.popups.length; i++) {
                    if (this.map.popups[i].fid === result.fid)
                        existingPopup = true;
                }
                if (!existingPopup)
                    popup.setFeature(result);
                else {
                    this.map.removePopup(popup);
                }
            }
            else {
                popup.setContent('No information is available', "&nbsp;");
            }

        }

        Beacon.MapJS.hideActivity();

    },



    // code adapted from openlayers.control.getfeature.js
    findBestFeature: function (features, clickPosition) {
        if (features.length) {
            var point = new OpenLayers.Geometry.Point(clickPosition.lon, clickPosition.lat);
            var feature, resultFeature, dist;
            var minDist = Number.MAX_VALUE;
            for (var i = 0; i < features.length; ++i) {
                feature = features[i];
                if (feature.geometry) {
                    dist = point.distanceTo(feature.geometry, { edge: false });
                    if (dist < minDist) {
                        minDist = dist;
                        resultFeature = feature;
                        if (minDist == 0) {
                            break;
                        }
                    }
                }
            }

            return (resultFeature || features);
        }
    },


    showCitizenInput: function (pt) {

        var tiphtml = "<div class='title'>" + this.wizardName + "</div>";

        tiphtml += Beacon.Tabs.getReportLinksHtml(
            null,
            5, // show tabs that are forms
            "Which form would you like to complete?<br>",
            "<br>",
            "&X=" + pt.lon + "&Y=" + pt.lat);

        var popup = new Beacon.Popup.MapTipFrame("maptip2",
            pt,
            null,
            tiphtml,
            {
                size: new OpenLayers.Size(0, 0),
                offset: new OpenLayers.Pixel(0, 0)
            },
            null);

        var dragPopup = new Beacon.Popup.MapTipFrameDragable(popup)
        dragPopup.setMap(this.map);
        //dragPopup.drawMarkup(lonlat, popup.startPosition);
        this.map.addPopup(dragPopup.popup);

    },

    updateOnLayerChange: function () {

        if (this.isWizardLayer()) {
            // get active layer name
            var lyr = Beacon.MapJS.getBeaconLayer(mapConfig.LayerId);
            this.wizardName = lyr.LayerName;
            this.panel_div.title = this.wizardName;
            this.displayClass = "citizeninput";
        } else {
            this.panel_div.title = "Identify (click again to adjust map tip mode)";
            this.displayClass = "identify";
        }

    },

    getIdentifyModeUI: function () {
        return $("input[type=radio][name=identify-mode]:checked").val();
    },

    setIdentifyModeUI: function (mode) {
        $("input[type=radio][name=identify-mode]").removeProp("checked");
        $("input[type=radio][name=identify-mode][value=" + mode + "]").prop("checked", "checked");
    },

    saveIdentifyMode: function (mode) {
        try {
            Beacon.localStorage["MapTipHoverMode"] = mode;
        } catch (e) { }
    },

    loadIdentifyMode: function () {
        var mode;

        try {
            mode = Beacon.localStorage["MapTipHoverMode"];
        } catch (e) { }
        switch (mode) {
            case 'true', 'hover':
                return 'hover';
            case 'disable':
                return 'disable';
            default:
                return 'normal';
        }
    },

    initHoverMode: function () {

        if (Beacon.MapJS.mobileDevice) {
            // no hovering on small devices
            this.identifyMode = 'normal';
            return;
        }

        this.identifyMode = this.loadIdentifyMode();
        this.setIdentifyModeUI(this.identifyMode);
        var that = this;
        $('input[type=radio][name=identify-mode]').change(function () {
            var mode = $(this).val();
            that.onIdentifyModeChanged.apply(that, [mode]);
        });

        Beacon.GA.TrackEvent('IdentifyMode', this.identifyMode);

    },

    onIdentifyModeChanged: function (mode) {

        this.identifyMode = mode;
        this.saveIdentifyMode(mode);

        Beacon.GA.TrackEvent('IdentifyMode', this.identifyMode);

    },

    CLASS_NAME: 'Beacon.Control.Identify'
});